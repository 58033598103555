import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import queryString from 'query-string'

import ECProject from './ECProject';

const DefaultProps = {
  e1Completed: false,
  loading: true
}

const mapStateToProps = (state, ownProps) => {
  return {...DefaultProps, ...{
    loading: state.fetching
  }}
};

class ConnectedHome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      e1Completed: props.e1Completed,
      loading: props.loading,
      category: null,
      survey: null
    };
  }

  componentDidMount() {
    const uri = queryString.parse(this.props.location.search)
    this.setState({
      category: uri.category,
      survey: uri.survey
    })
  }

  componentDidUpdate(prevProps) {
    Object.keys(DefaultProps).forEach( key => {
      if (this.props[key] !== prevProps[key])
        this.setState({ [key]: this.props[key] })
    })
  }

  render() {
    const { loading, category, survey } = this.state
    const defaultPage = ECProject === 'fidura' ?
      '/fidura/enkat/1/intro':
      '/vista/enkat/0/intro'

    if (loading) {
      return <div>Laddning...</div>
    } else if (category && survey) {
      return <Redirect to={`/${ECProject}/enkat/${survey}/${category}`} />
    } else if (survey) {
      return <Redirect to={`/${ECProject}/enkat/${survey}/`} />
    } else {
      return <Redirect to={defaultPage} />
    }
  }
}

const Home = connect(mapStateToProps)(ConnectedHome);

export default Home;
