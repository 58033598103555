import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Validate, Headline, Radio, Title, Previous } from '../../fields';

//export const requiredAnswers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]
export const requiredAnswers = [1, '2_1', '2_2', '3_1', '4_1', '5_1', 6, 7, 8, 9, 10, 11, 12, 13]

export class Kvinnohalsa extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <div id="top">
        <Title>FRÅGOR OM KVINNOHÄLSA OCH SEXUALITET</Title>
        <Headline
            nr="1"
            question="Har du eller har du haft någon gynekologisk sjukdom eller diagnos det senaste året?"
        />
        <Radio _id="0.1_1" value="nej" desc="Nej" />
        <Radio _id="0.1_1" value="lichenSclerosus" desc="Ja, lichen sclerosus (hudsjukdom i underlivet)" />
        <Radio _id="0.1_1" value="vaginism" desc="Ja, vaginism (slidkramp)" />
        <Radio _id="0.1_1" value="vulvodyni" desc="Ja, lichen sclerosus (hudsjukdom i underlivet)" />
        <Radio _id="0.1_1" value="prolaps" desc="Ja, prolaps (framfall)" />
        <Radio _id="0.1_1" value="annat" desc="Annat, nämligen:" custom />

        <Headline
            nr="2"
            question="Har du fött barn?"
        />
        <Radio _id="0.2_1" value="nej" desc="Nej" />
        <Radio _id="0.2_1" value="ja" desc="Ja, jag har fött" custom short after=" (antal) barn" />
        <Radio _id="0.2_2" omJa="0.2_1" offset="2em" value="vaginalt" desc="vaginalt" />
        <Radio _id="0.2_2" omJa="0.2_1" offset="2em" value="kejsarsnitt" desc="med kejsarsnitt" />

        <Headline
            nr="3"
            question="Blev Du blivit klippt i området mellan slidan, mellangården och ändtarmsöppningen i samband med förlossningen?"
        />
        <Radio _id="0.3_1" omJa="0.2_1" show nejToggle="0.2_1" value="inteAktuellt" desc="Inte aktuellt, jag har inte fött barn" />
        <Radio _id="0.3_1" omJa="0.2_1" show value="nej" desc="Nej" />
        <Radio _id="0.3_1" omJa="0.2_1" show value="vidEnForlossning" desc="Ja, vid en förlossning" />
        <Radio _id="0.3_1" omJa="0.2_1" show value="vidFleraForlossningar" desc="Ja, vid en förlossning" />

        <Headline
            nr="4"
            question="Fick Du bristningar i mellangården i samband med förlossningen?"
        />
        <Radio _id="0.4_1" omJa="0.2_1" show nejToggle="0.2_1" value="inteAktuellt" desc="Inte aktuellt, jag har inte fött barn" />
        <Radio _id="0.4_1" omJa="0.2_1" show value="nej" desc="Nej" />
        <Radio _id="0.4_1" omJa="0.2_1" show value="vidEnForlossning" desc="Ja, vid en förlossning" />
        <Radio _id="0.4_1" omJa="0.2_1" show value="vidFleraForlossningar" desc="Ja, vid en förlossning" />

        <Headline
            nr="5"
            question="Fick Du bristningar i slutmuskeln för ändtarmen (sfinkterskada) i samband med förlossningen?"
        />
        <Radio _id="0.5_1" omJa="0.2_1" show nejToggle="0.2_1" value="inteAktuellt" desc="Inte aktuellt, jag har inte fött barn" />
        <Radio _id="0.5_1" omJa="0.2_1" show value="nej" desc="Nej" />
        <Radio _id="0.5_1" omJa="0.2_1" show value="vidEnForlossning" desc="Ja, vid en förlossning" />
        <Radio _id="0.5_1" omJa="0.2_1" show value="vidFleraForlossningar" desc="Ja, vid en förlossning" />

        <Headline
            nr="6"
            question="Har du kommit in i menopaus (klimakteriet, slutat menstruera) sedan minst 6 månader före din cancerdiagnos?"
        />
        <Radio _id="0.6_1" value="nej" desc="Nej" />
        <Radio _id="0.6_1" value="ja" desc="Ja" />

        <Headline
            nr="7"
            question="Har du använt hormontabletter sedan minst 6 månader tillbaka?"
        />
        <Radio _id="0.7_1" value="nej" desc="Nej" />
        <Radio _id="0.7_1" value="ja" desc="Ja" />

        <Headline
            nr="8"
            question="Har du använt hormonplåster sedan minst 6 månader tillbaka?"
        />
        <Radio _id="0.8_1" value="nej" desc="Nej" />
        <Radio _id="0.8_1" value="ja" desc="Ja" />

        <Headline
            nr="9"
            question="Har du använt lokalt östrogen exempelvis vagitorium, kräm eller gel i underlivet regelbundet sedan minst 6 månader tillbaka?"
        />
        <Radio _id="0.9_1" value="nej" desc="Nej" />
        <Radio _id="0.9_1" value="ja" desc="Ja" />

        <Headline
            nr="10"
            question="När du har haft menstruation, har du då oftast använt tampong eller menskopp?"
        />
        <Radio _id="0.10_1" value="nej" desc="Nej, aldrig" />
        <Radio _id="0.10_1" value="farreAnHalften" desc="Ja, vid färre än hälften av menstruationerna" />
        <Radio _id="0.10_1" value="halften" desc="Ja, vid ungefär hälften av menstruationerna" />
        <Radio _id="0.10_1" value="flerAnHalften" desc="Ja, vid fler än hälften av menstruationerna" />
        <Radio _id="0.10_1" value="varjeMenstruation" desc="Ja, vid varje menstruation" />

        <Headline
            nr="11"
            question="Har du upplevt smärta eller fysiskt obehag i samband med att du använt tampong eller menskopp?"
        />
        <Radio _id="0.11_1" value="inteAktuellt" desc="Inte aktuellt" />
        <Radio _id="0.11_1" value="nej" desc="Nej, inte alls" />
        <Radio _id="0.11_1" value="lite" desc="Ja, lite" />
        <Radio _id="0.11_1" value="mattligt" desc="Ja, måttligt" />
        <Radio _id="0.11_1" value="mycket" desc="Ja, mycket" />

        <Headline
            nr="12"
            question="Om du någon gång använt slidpiller (vagitorium, exempelvis lokalt östrogen eller medel mot svampinfektion), har du då upplevt smärta eller fysiskt obehag i samband med det?"
        />
        <Radio _id="0.12_1" value="inteAktuellt" desc="Inte aktuellt" />
        <Radio _id="0.12_1" value="nej" desc="Nej, inte alls" />
        <Radio _id="0.12_1" value="lite" desc="Ja, lite" />
        <Radio _id="0.12_1" value="mattligt" desc="Ja, måttligt" />
        <Radio _id="0.12_1" value="mycket" desc="Ja, mycket" />

        <Headline
            nr="13"
            question="När du har haft sex med partner de senaste 10 åren, vilket kön har partnern haft?"
        />
        <Radio _id="0.13_1" value="inteAktuellt" desc="Inte aktuellt, jag har inte haft sex med partner de senaste 10 åren." />
        <Radio _id="0.13_1" value="kvinna" desc="Alltid en kvinna" />
        <Radio _id="0.13_1" value="bada" desc="Ibland en kvinna och ibland en man" />
        <Radio _id="0.13_1" value="man" desc="Alltid en man" />

        <Validate className="next" answers={requiredAnswers} enkat="0">
          <Link to="forecancern#top">
            <button>Fortsätt &#8250;</button>
          </Link>
        </Validate><Previous to="intro" />
      </div>
    );
  }
}

export default requiredAnswers;
