import React from 'react'
import styled from 'styled-components';

const Table = styled.table`
margin: 2em;
width: 500px;
border-spacing: 0;
border-collapse: collapse;
border: 1px solid black;

th, td {
  border: 1px solid #F4B083;
  text-align: left;
  padding-left: 8px;
  padding-right: 8px;
  font-weight: normal;
}

tr:nth-child(odd) {
  background-color: #FBE4D5;
}

th {
  color: white;
  background-color: #ED7D31;
}

td:first-child, th:first-child {
  font-weight: bold;
}

td:last-child, th:last-child {
  text-align: right;
}
`

const Portionstabell = (props) => {

  return (
    <Table>
      <thead>
        <tr>
          <th>PORTIONSTABELL</th>
          <th>1 portion</th>
          <th>Mängd per portion</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Mjölk, fil, yoghurt</td>
          <td>1 glas/tallrik</td>
          <td>= 2 dl</td>
        </tr>
        <tr>
          <td>Juice, saft, läsk</td>
          <td>1 glas</td>
          <td>= 2 dl</td>
        </tr>
        <tr>
          <td>Öl (alla alkoholhalter)</td>
          <td>1 glas</td>
          <td>= 33 cl &nbsp;&nbsp; = 3,3 dl</td>
        </tr>
        <tr>
          <td>Vin</td>
          <td>1 glas</td>
          <td>= 15 cl &nbsp;&nbsp; = 1,5 dl</td>
        </tr>
        <tr>
          <td>Starkvin/dessertvin, starksprit</td>
          <td>1 glas</td>
          <td>= 4 cl &nbsp;&nbsp; = 0,4 dl</td>
        </tr>
        <tr>
          <td>Kaffe, te</td>
          <td>1 kopp</td>
          <td>= 1,5 dl</td>
        </tr>
      </tbody>
    </Table>
  )
}

export default Portionstabell;
