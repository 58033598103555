import React, { Component } from "react";

import { Smorgasar, GrotFlingorMusli, NotterFron, Drycker,
GronsakerFrukt, Kott, Fisk, Vegetarisk, Potatis, Snabbmat, Matfett,
Sas, Snacks, Tarmsymptom, Matvanor, Fysisk, Mediciner, Tobak, Halsa,
Bakgrund, Misc, Slutet, requiredAnswers } from '../enkat1';

import Panel from '../../Panel';
import Logout from '../Logout';

class Enkat1 extends Component {
  render() {
    return (
      <>
        <Panel admin answers={requiredAnswers} />
        <Logout />

        <Smorgasar />
        <GrotFlingorMusli />
        <NotterFron />
        <Drycker />
        <GronsakerFrukt />
        <Kott />
        <Fisk />
        <Vegetarisk />
        <Potatis />
        <Snabbmat />
        <Matfett />
        <Sas />
        <Snacks />
        <Tarmsymptom />
        <Matvanor />
        <Fysisk />
        <Mediciner />
        <Tobak />
        <Halsa />
        <Bakgrund />
        <Misc />
        <Slutet />
      </>
    );
  }
}

export default Enkat1;
