import React, { Component } from 'react';
import styled from 'styled-components';
import { Title, Previous } from '../../fields';

const Text = styled.p`
margin: 1em 0 1em 2em;
`

const Spacer = styled.div`
height: 2em;
`

const BigAssTack = styled.p`
margin: 1em 0 1em 2em;
font-size: 3em;
`

export class Slutet extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <div>
        <Title>Slutligen…</Title>
        <Text>Om du har några ytterligare frågor, kontakta oss gärna på 031-786 51 30 / 076-618 51 30 (kl. 8–15) eller 
          &nbsp;<a href="mailto:fidura@vgregion.se">fidura@vgregion.se</a>.</Text>

        <Spacer />
        <Text><strong>Stort tack för din medverkan! Du kan stänga denna enkät nu.</strong></Text>
        <BigAssTack>Tack</BigAssTack>

        <Previous to="misc" />

      </div>
    );
  }
}

export default Slutet;
