import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';

class Previous extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    let url = `${this.props.to}#top`
    return (
        <div className="previous">
          <Link to={url} >
            <button>&#8249; Föregående</button>
          </Link>
        </div>
    );
  }
}

export default Previous;
