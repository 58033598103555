import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Headline, Validate, Radio, Title, Progress, Previous } from '../../fields';
import { numberOfCategories } from './index';

export const requiredAnswers = [44, '45_1', '45_2', 46]

export class Stralbehandling extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <div id="top">
        <Progress max={numberOfCategories} value={7} />
        <Title>STRÅLBEHANDLING</Title>

        <Headline
          nr="44"
          question="Har du fått brachybehandling under din strålbehandling (inre strålbehandling)?"
        />
        <Radio _id="2.44_1" value="nej" desc="Nej" />
        <Radio _id="2.44_1" value="ja" desc="Ja" />
        <Radio _id="2.44_1" value="vetEj" desc="Vet ej" />

        <Headline
          nr="45"
          question="Har du fått behandling med cellgifter?"
        />
        <Radio _id="2.45_1" value="nej" desc="Nej" />
        <Radio _id="2.45_1" value="ja" desc="Ja" />
        <Radio _id="2.45_2" multiple offset="2em" omJa="2.45_1" value="fore" desc="Ja, före strålbehandling" />
        <Radio _id="2.45_2" multiple offset="2em" omJa="2.45_1" value="samtidigt" desc="Ja, samtidigt som strålbehandling" />
        <Radio _id="2.45_2" multiple offset="2em" omJa="2.45_1" value="efter" desc="Ja, efter avslutad strålbehandling" />
        <Radio _id="2.45_1" value="vetEj" desc="Vet ej" />

        <Headline
          nr="46"
          question="Har du operatas för cancer i bäckenområdet?"
        />
        <Radio _id="2.46_1" value="nej" desc="Nej" />
        <Radio _id="2.46_1" value="ja" desc="Ja" />

        <Validate className="next" answers={requiredAnswers} enkat="2">
          <Link to="tobak#top">
            <button>Fortsätt &#8250;</button>
          </Link>
        </Validate><Previous to="halsa" />

      </div>
    );
  }
}

export default Stralbehandling;
