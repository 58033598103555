import React from 'react'
import styled from 'styled-components';
import { connect } from "react-redux";
import { commitAnswer } from "../Actions";

const DefaultProps = {
  freq: '',
  times: '',
  warning: false,
  freqWarning: false,
  freqConfirm: false,
}

const Container = styled.div`
margin: 0;
`

const Row = styled.div`
margin-bottom: 0.5em;
`

const Title = styled.div`
margin: 0 1rem;
font-weight: bold;
display: inline-block;
`

const Input = styled.input`
display: inline;
width: 3em;
text-align: right;
margin-right: 1em;
border-style: solid;
border-color: black;
border-width: 1px;
padding: 0.2em;
`

const Times = styled.span`
margin-right: 1rem;
`

const RadioForm = styled.form`
display: inline;
`

const RadioLabel = styled.label`
display: inline;
margin-right: 1rem;
`

const RadioInput = styled.input`
`

const Confirm = styled.div`
margin: 0.7em 1em 1.1em;
font-size: 0.9em;
font-style: italic;
color: #FF2400;
`

const mapStateToProps = (state, ownProps) => {
  let stateObj = state.answers.find( answer => answer._id === ownProps._id );
  return {...DefaultProps, ...stateObj}
};

const mapDispatchToProps = dispatch => {
  return {
    commitAnswer: answer => dispatch(commitAnswer(answer))
  };
};

class ConnectedFreqInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: props._id,
      freq: props.freq,
      times: props.times,
      warning: props.warning,
      freqWarning: props.freqWarning,
      freqConfirm: props.freqConfirm
    };

    this.changeFreq = this.changeFreq.bind(this);
    this.changeTimes = this.changeTimes.bind(this);
  }

  componentDidUpdate(prevProps) {
    Object.keys(DefaultProps).forEach( key => {
      if (this.props[key] !== prevProps[key]) {
        this.setState({
          [key]: this.props[key],
          freqWarning: false,
          freqConfirm: false,
          warning: false
        }, () => {
          this.checkTimes()
          this.checkFreq()
        })
      }
    })
  }

  checkTimes() {
    let { times, freq } = this.state

    this.setState({
      warning: (!/^(-|\+)?(\d+)$/.test(times) && times !== '')
        || (freq !== '' && freq !== 'aldrig' && times === ''),
      freqConfirm: freq === 'dag' && parseInt(times) >= 7
    });
  }

  checkFreq() {
    const { freq, times } = this.state

    this.setState({
      freqWarning: freq === '',
      freqConfirm: freq === 'dag' && parseInt(times) >= 7
    });
  }

  changeFreq(event) {
    let freq = event.target.value

    this.setState({
      freq: freq,
      times: freq === 'aldrig' ? '': this.state.times
    }, () => {
      this.checkTimes()
      this.checkFreq()
      this.saveChanges()
    });
  }

  changeTimes(event) {
    this.setState({
      times: event.target.value
    }, () => {
      this.checkTimes()
      this.checkFreq()
      this.saveChanges()
    });
  }

  saveChanges() {
    const _id = this.props._id;
    let { freq, times } = this.state;
    this.props.commitAnswer({ _id, freq, times })
  }

  render() {
    const { warning, freqWarning, freqConfirm } = this.state

    const { title, times, freq, exampleTimes, exampleFreq, skivor, titleWidth, grey } = this.props

    const WarningStyle = {
      color: warning ? 'red': 'inherit',
      backgroundColor: grey ? 'rgb(238, 238, 238)': 'transparent',
      borderBottom: grey ? '1px solid black': 'none',
      borderTop: grey ? '1px solid black': 'none'
    }

    const FreqWarningStyle = {
      color: freqWarning ? 'red': 'inherit'
    }

    const TitleStyle = {
      width: titleWidth ? titleWidth : 'inherit'
    }

    return(
      <Container>
        <Row style={WarningStyle}>
          <Title style={TitleStyle}>{title}</Title>
          <Input
            type="text"
            value={exampleTimes ? exampleTimes : times}
            onChange={this.changeTimes}
            onBlur={this.changeTimes}
            disabled={exampleTimes || freq === "aldrig"}
          />
          <Times>{ skivor ? 'skivor' : 'gånger' }</Times>
          <RadioForm style={FreqWarningStyle}>
            <RadioLabel>
              <RadioInput
                type="radio"
                value="dag"
                checked={exampleFreq === "dag" || freq === "dag"}
                onChange={this.changeFreq}
              />
              per dag
            </RadioLabel>
            <RadioLabel>
              <RadioInput
                type="radio"
                value="vecka"
                checked={exampleFreq === "vecka" || freq === "vecka"}
                onChange={this.changeFreq}
              />
              per vecka
            </RadioLabel>
            <RadioLabel>
              <RadioInput
                type="radio"
                value="manad"
                checked={exampleFreq === "manad" || freq === "manad"}
                onChange={this.changeFreq}
              />
              per månad
            </RadioLabel>
            <RadioLabel>
              <RadioInput
                type="radio"
                value="aldrig"
                checked={freq === "aldrig"}
                onChange={this.changeFreq}
              />
              aldrig
            </RadioLabel>
          </RadioForm>
        </Row>
        { freqConfirm && (
          <Confirm>Du har skrivit att du äter detta sju eller fler gånger per dag, är du säker?</Confirm>
        )}
      </Container>
    )
  }
}

const FreqInput = connect(mapStateToProps, mapDispatchToProps)(ConnectedFreqInput);

export default FreqInput;
