import React from 'react'
import styled from 'styled-components';
import { connect } from "react-redux";
import { swapDB } from "../Actions";
import ReactExport from "react-data-export";
import { userList, loginList, initialUser } from '../DB';

import Grunddatabas from "../fidura/Grunddatabas.json";
import Grunddatabas3 from "../fidura/Grunddatabas3.json";
import LivsmedelsDB from "../fidura/LivsmedelsDB.json";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

//console.log(LivsmedelsDB[0])
//console.log(Object.keys(LivsmedelsDB[0]))
const Nutrients =  ["Energi (kcal)", "Energi (kJ)", "Kolhydrater (g)", "Fett (g)", "Protein (g)", "Fibrer (g)", "Olöslig fibrer (g)", "Löslig fibrer (g)", "Vatten (g)", "Alkohol (g)", "Aska (g)", "Monosackarider (g)", "Disackarider (g)", "Sackaros (g)", "Fullkorn totalt (g)", "Sockerarter (g)", "Summa mättade fettsyror (g)", "Fettsyra 4:0-10:0 (g)", "Laurinsyra C12:0 (g)", "Myristinsyra C14:0 (g)", "Palmitinsyra C16:0 (g)", "Stearinsyra C18:0 (g)", "Arakidinsyra C20:0 (g)", "Summa enkelomättade fettsyror (g)", "Palmitoljesyra C16:1 (g)", "Oljesyra C18:1 (g)", "Summa fleromättade fettsyror (g)", "Linolsyra C18:2 (g)", "Linolensyra C18:3 (g)", "Arakidonsyra C20:4 (g)", "EPA (C20:5) (g)", "DPA (C22:5) (g)", "DHA (C22:6) (g)", "Kolesterol (mg)", "Retinol (µg)", "Vitamin A (RE)", "β-Karoten (µg)", "Vitamin D (µg)", "Vitamin E (mg)", "Vitamin K (µg)", "Tiamin (mg)", "Riboflavin (mg)", "Vitamin C (mg)", "Niacin (mg)", "Niacinekvivalenter (NE)", "Vitamin B6 (mg)", "Vitamin B12 (µg)", "Folat (µg)", "Fosfor (mg)", "Jod (µg)", "Järn (mg)", "Kalcium (mg)", "Kalium (mg)", "Koppar (mg)", "Magnesium (mg)", "Natrium (mg)", "Salt (g)", "Selen (µg)", "Zink (mg)", "Stärkelse (g)", "Avfall (skal etc.) (%)"]

const FreqSASNAMES = [
  ["soft_bread", "bread_white"],
  ["soft_bread", "bread_whitesoft"],
  ["soft_bread", "bread_grainwheat"],
  ["soft_bread", "bread_grainray"],
  ["soft_bread", "bread_grain"],
  ["crisp_bread", "bread_crispw"],
  ["crisp_bread", "bread_crispo"],
  ["crisp_bread", "bread_crispr"],
  ["porridge", "porridge_havre"],
  ["porridge", "porridge_mana"],
  ["porridge", "porridge_ray"],
  ["cereal", "cereal_corn"],
  ["cereal", "cereal_bran"],
  ["cereal", "cereal_ray"],
  ["cereal", "cereal_musli_sweet"],
  ["cereal", "cereal_musli"],
  ["nut", "nut_hazel"],
  ["nut", "nut_peanut"],
  ["nut", "nut_other"],
  ["seed", "seed_flax"],
  ["seed", "seed_other"],
  ["seed", "seed_chia"],
  ["seed", "seed_psyllium"],
  ["vegetables_cooked", "beans_boild"],
  ["vegetables_cooked", "beans_green_boild"],
  ["vegetables_cooked", "cabbage_boild"],
  ["vegetables_cooked", "carrot_boild"],
  ["vegetables_cooked", "peas_boild"],
  ["vegetables_cooked", "broccoli_boild"],
  ["vegetables_cooked", "spinache_boild"],
  ["vegetables_cooked", "mushrooms_boild"],
  ["vegetables_stew", "beans_stew"],
  ["vegetables_stew", "beans_green_stew"],
  ["vegetables_stew", "cabbage_stew"],
  ["vegetables_stew", "root_vegetable_stew"],
  ["vegetables_stew", "carrots_stew"],
  ["vegetables_stew", "peas_green_stew"],
  ["vegetables_stew", "broccoli_stew"],
  ["vegetables_stew", "spinache_stew"],
  ["vegetables_stew", "onion_stew"],
  ["vegetables_stew", "mushrooms_stew"],
  ["vegetables_salad", "tomato"],
  ["vegetables_salad", "pepper"],
  ["vegetables_salad", "lettuce"],
  ["vegetables_salad", "green_leafy"],
  ["vegetables_salad", "beans"],
  ["vegetables_salad", "peas"],
  ["vegetables_salad", "beans_green"],
  ["vegetables_salad", "corn"],
  ["vegetables_salad", "carrot"],
  ["vegetables_salad", "cabbage"],
  ["vegetables_salad", "avocado"],
  ["vegetables_salad", "olives"],
  ["fruit", "apple"],
  ["fruit", "banana"],
  ["fruit", "citrus"],
  ["fruit", "pear"],
  ["fruit", "plum"],
  ["fruit", "melon"],
  ["fruit", "grape"],
  ["fruit", "peach"],
  ["fruit", "pommegranate"],
  ["berries", "strawberry"],
  ["berries", "blueberry"],
  ["berries", "raspberry"],
  ["berries", "cloudberry"],
  ["berries", "blackcurrant"],
  ["berries", "blackberry"],
  ["berries", "lingonberry"],
  ["berries", "cherrie"],
  ["fruit_soup", "apple_soup"],
  ["fruit_soup", "orange_soup"],
  ["fruit_soup", "plum_soup"],
  ["fruit_soup", "driedfruit_soup"],
  ["fruit_soup", "strawberry_soup"],
  ["fruit_soup", "blueberry_soup"],
  ["fruit_soup", "raspberry_soup"],
  ["fruit_soup", "cloudberry_soup"],
  ["fruit_soup", "blackcurrant_soup"],
  ["fruit_soup", "blackberry_soup"],
  ["fruit_soup", "cherrie_soup"],
  ["fruit_soup", "nypon_soup"],
  ["jam", "stawberry_jam"],
  ["jam", "blueberry_jam"],
  ["jam", "raspberry_jam"],
  ["jam", "cloudberry_jam"],
  ["jam", "blackcurrant_jelly"],
  ["jam", "blackberry_jam"],
  ["jam", "lingonberry_jam"],
  ["jam", "cherrie_jam"],
  ["jam", "applesauce"],
  ["meat", "meat_mince"],
  ["meat", "meat_pork"],
  ["meat", "meat_beef"],
  ["meat", "meat_lamb_game"],
  ["meat", "meat_chicken"],
  ["meat", "meat_sausage"],
  ["meat", "meat_bacon"],
  ["meat", "meat_blackpudding"],
  ["meat", "meat_entrails"],
  ["fish", "fish_fatty"],
  ["fish", "fish_salmon"],
  ["fish", "fish_lean"],
  ["fish", "fish_breaded"],
  ["fish", "seafood"],
  ["egg", "egg_boiled"],
  ["egg", "egg_fried"],
  ["egg", "panncake"],
  ["vegetarian", "quorn"],
  ["vegetarian", "soy"],
  ["vegetarian", "greens"],
  ["carbohydrates", "potato"],
  ["carbohydrates", "spagetti"],
  ["carbohydrates", "spagetti_wholegrain"],
  ["carbohydrates", "rice"],
  ["carbohydrates", "rice_wholegrain"],
  ["carbohydrates", "grain"],
  ["fastfood", "pizza"],
  ["fastfood", "burger"],
  ["fastfood", "pie"],
  ["fastfood", "sushi"],
  ["fastfood", "fries"],
  ["fastfood", "sandwich"],
  ["dressing", "dressing_oliveoil"],
  ["dressing", "dressing_vinaigrette"],
  ["dressing", "dressing_oil_other"],
  ["dressing", "dressing_sourcream"],
  ["dressing", "dressing_cream_fullfat"],
  ["dressing", "dressing_mayonnaise_highfat"],
  ["dressing", "dressing_mayonnaise_lowfat"],
  ["sauce", "gravy"],
  ["sauce", "sauce_milk"],
  ["sauce", "sauce_lowfat"],
  ["sauce", "sauce_medfat"],
  ["sauce", "sauce_medcream"],
  ["sauce", "sauce_wholefat"],
  ["sauce", "sauce_butter"],
]

const BreadSASNAMES = [
  "seed",
  "cheese",
  "soft_cheese",
  "meat_topping",
  "sausage_topping",
  "liver_pate",
  "fish_topping",
  "marmelade",
]

const FatSASNAMES = [
  "butter",
  "bregott_highfat",
  "bregott_lowfat",
  "margarin_highfat",
  "margarin_lowfat",
  "margarin_sterol",
]

const SASNAMES = [
  "milk_lowfat",
  "milk_medfat",
  "milk_highfat",
  "yoghurt_lowfat",
  "yoghurt_medfat",
  "yoghurt_highfat",
  "oat_milk",
  "juice",
  "softdrink",
  "softdrink_light",
  "beer_lowalco",
  "beer_alco3,5",
  "beer_alco4,0",
  "wine_red",
  "wine_white",
  "wine_strong",
  "licor",
  "coffee",
  "tea",
  "tea_green",

  "bun",
  "cookies",
  "cake",
  "chokolate",
  "candy",
  "icecream",
  "crisp",
  "cheese_snack",
]

const DefaultProps = {
  answers: [],
  user: initialUser,
  loading: true,
  calculating: false,
  allUsersDataset: [],
  allUsersDataset3: [],
  allUsersRawData: [],
  allUsersRawData2: [],
  allUsersRawData3: [],
}

const Container = styled.div`
margin: 0;
`

const CalculatingScreen = styled.div`
position: fixed;
top:0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(255, 255, 255, 0.8);
color: black;
text-align: center;
padding-top: 200px;
`

const mapDispatchToProps = dispatch => {
  return {
    swapDB: db => dispatch(swapDB(db))
  };
};

const mapStateToProps = (state, ownProps) => {
  return {...DefaultProps,
    answers: state.answers,
    user: state.user,
    loading: state.fetching
  }
};

class ConnectedControl extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      answers: props.answers,
      user: props.user,
      loading: props.loading,
      calculating: props.calculating,
      allUsersDataset: props.allUsersDataset,
      allUsersDataset3: props.allUsersDataset3,
      allUsersRawData: props.allUsersRawData,
      allUsersRawData2: props.allUsersRawData2,
      allUsersRawData3: props.allUsersRawData3,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    Object.keys(DefaultProps).forEach( key => {
      if (this.props[key] !== prevProps[key]) {
        if (key === 'user') {
          this.setState({
            [key]: this.props[key],
            dataset: null,
            dataset3: null,
          })
        } else if (key === 'loading' && this.props[key] === false && this.state.calculating === true) {
          this.setState({
            [key]: this.props[key],
          }, () => this.addUserToExport(this.props.user)
          )
        } else {
          //if (key === 'answers') {
          //  console.log(this.props.answers.length)
          //  console.log(this.props.answers.map(a => a._id))
          //}
          this.setState({
            [key]: this.props[key],
          })
        }
      }
    })
  }

  calculateAllUsers = () => {
    const fallbackUser = userList[this.state.user] ? userList[this.state.user] : '50012'

    this.setState({
      calculating: true,
      fallbackUser,
      totalUsers: Object.keys(loginList).length,
      userCalculating: 1,
    }, () => {
      this.props.swapDB( {
        id: Object.entries(loginList)[0][0],
        user: Object.entries(loginList)[0][1]
      })
    });
  }

  addUserToExport = (user) => {
    this.calculateAll( () => {
      const totalNutrition = this.state.dataset.reduce( (acc, row) => {
        return {...acc, User: user, ...Nutrients.reduce((ac,n) => (
          {...ac, [n]: acc[n] + row[n] }
        ),{}) }

      }, Nutrients.reduce((ac,n) => ({...ac,[n]:0}), {'User': ''}))

      const totalNutrition3 = this.state.dataset3.reduce( (acc, row) => {
        return {...acc, User: user, ...Nutrients.reduce((ac,n) => (
          {...ac, [n]: acc[n] + row[n] }
        ),{}) }

      }, Nutrients.reduce((ac,n) => ({...ac,[n]:0}), {'User': ''}))

      const usersLeft = this.state.allUsersDataset.length < Object.keys(loginList).length - 1
      const userCalculating = this.state.allUsersDataset.length + 1

      //const sortedAnswers = this.state.answers.map(a => ({...a, user})).sort((a, b) => {
      //  const regex = /^(\d).(\d\d?|extra)_(\d\d?)$/

      //  if (!a._id || !b._id) { return 0 } else {
      //    const [ , , questionNrA, fieldNrA ] = regex.exec(a._id)
      //    const [ , , questionNrB, fieldNrB ] = regex.exec(b._id)

      //    const qA = questionNrA === 'extra' ? 1000 : questionNrA
      //    const qB = questionNrB === 'extra' ? 1000 : questionNrB

      //    return qA === qB ? fieldNrA - fieldNrB : qA - qB
      //  }
      //}).map(a => {
      //  return Object.keys(a).reduce((acc, key) => {
      //    return {...acc, [key]: typeof a[key] === 'string' || typeof a[key] === 'number' ? a[key] : JSON.stringify(a[key])}
      //  }, {})
      //})

      const IGNORED_KEYS = [
        '_id',
        '_rev',
        'min',
        'max',
        'sum',
        'day',
        'month',
        'customValue',
        'times',
        'freq',
        'omJa',
      ]

      const SIMPLIFY_KEYS = [
        'value',
        'text',
        'checked',
      ]

      const DATE_KEYS = [
        'year',
        'month',
        'day',
      ]
      
      const stringifiedAnswers = this.state.answers
        .filter(a => a._id.startsWith('1.'))
        .reduce((row, answer) => (
          {...row,
          ...(answer.customValue ? {[`${answer._id}_custom`]: answer.customValue}: {}),
          ...(answer.times ? {[`${answer._id}_times`]: answer.times}: {}),
          ...(answer.freq ? {[`${answer._id}_freq`]: answer.freq}: {}),
          ...(answer.freq ? {} : {[answer._id]: Object.keys(answer)
            .filter(key => !IGNORED_KEYS.includes(key))
            .reduce((acc, key, i) => {
              if (SIMPLIFY_KEYS.includes(key)) {
                return answer[key]
              } else if (key === 'values') {
                return answer[key].join(', ')
              } else if (DATE_KEYS.includes(key)) {
                const year = answer.year ? answer.year : 1930
                const month = answer.month ? new Date(Date.parse(answer.month +" 1, 2012")).getMonth()+1 : 1
                const day = answer.day ? answer.day : 1
                return `${year}/${month}/${day}`
              } else {
                const totalAnswers = Object.keys(answer).filter(key => !IGNORED_KEYS.includes(key)).length
                return `${acc === '' ? '{ ' : acc}"${key}": ${JSON.stringify(answer[key])}${i === totalAnswers-1 ? ' }' : ', '}`
              }
            }, '')
          })}
        ), {user})

      const stringifiedAnswers2 = this.state.answers
        .filter(a => a._id.startsWith('2.'))
        .reduce((row, answer) => (
          {...row,
          ...(answer.customValue ? {[`${answer._id}_custom`]: answer.customValue}: {}),
          ...(answer.times ? {[`${answer._id}_times`]: answer.times}: {}),
          ...(answer.freq ? {[`${answer._id}_freq`]: answer.freq}: {}),
          ...(answer.freq ? {} : {[answer._id]: Object.keys(answer)
            .filter(key => !IGNORED_KEYS.includes(key))
            .reduce((acc, key, i) => {
              if (SIMPLIFY_KEYS.includes(key)) {
                return answer[key]
              } else if (key === 'values') {
                return answer[key].join(', ')
              } else if (DATE_KEYS.includes(key)) {
                const year = answer.year ? answer.year : 1930
                const month = answer.month ? new Date(Date.parse(answer.month +" 1, 2012")).getMonth()+1 : 1
                const day = answer.day ? answer.day : 1
                return `${year}/${month}/${day}`
              } else {
                const totalAnswers = Object.keys(answer).filter(key => !IGNORED_KEYS.includes(key)).length
                return `${acc === '' ? '{ ' : acc}"${key}": ${JSON.stringify(answer[key])}${i === totalAnswers-1 ? ' }' : ', '}`
              }
            }, '')
          })}
        ), {user})

      const stringifiedAnswers3 = this.state.answers
        .filter(a => a._id.startsWith('3.'))
        .reduce((row, answer) => (
          {...row,
          ...(answer.customValue ? {[`${answer._id}_custom`]: answer.customValue}: {}),
          ...(answer.times ? {[`${answer._id}_times`]: answer.times}: {}),
          ...(answer.freq ? {[`${answer._id}_freq`]: answer.freq}: {}),
          ...(answer.freq ? {} : {[answer._id]: Object.keys(answer)
            .filter(key => !IGNORED_KEYS.includes(key))
            .reduce((acc, key, i) => {
              if (SIMPLIFY_KEYS.includes(key)) {
                return answer[key]
              } else if (key === 'values') {
                return answer[key].join(', ')
              } else if (DATE_KEYS.includes(key)) {
                const year = answer.year ? answer.year : 1930
                const month = answer.month ? new Date(Date.parse(answer.month +" 1, 2012")).getMonth()+1 : 1
                const day = answer.day ? answer.day : 1
                return `${year}/${month}/${day}`
              } else {
                const totalAnswers = Object.keys(answer).filter(key => !IGNORED_KEYS.includes(key)).length
                return `${acc === '' ? '{ ' : acc}"${key}": ${JSON.stringify(answer[key])}${i === totalAnswers-1 ? ' }' : ', '}`
              }
            }, '')
          })}
        ), {user})

      this.setState({
        allUsersDataset: [...this.state.allUsersDataset, totalNutrition],
        allUsersDataset3: [...this.state.allUsersDataset3, totalNutrition3],
        allUsersRawData: [...this.state.allUsersRawData, stringifiedAnswers],
        allUsersRawData2: [...this.state.allUsersRawData2, stringifiedAnswers2],
        allUsersRawData3: [...this.state.allUsersRawData3, stringifiedAnswers3],
        //allUsersRawData: [...this.state.allUsersRawData, ...sortedAnswers],
        calculating: usersLeft,
        loading: usersLeft,
        userCalculating
      }, () => {
        if (usersLeft) {
          const [remainingDB, remainingID] = Object.entries(loginList).find(o =>
            !this.state.allUsersDataset.map(r => r.User).includes(o[1]))

          this.props.swapDB( {
            id: remainingDB,
            user: remainingID,
          })
          
        } else {
          const fallbackDB = Object.keys(loginList).find(db => loginList[db] === this.state.fallbackUser)
          const fallbackUser = Object.keys(userList).find(u => userList[u] === this.state.fallbackUser)

          this.props.swapDB( {
            id: fallbackDB,
            user: fallbackUser,
          })

        }
      })
    } )
  }

  calculate = (SASNAME, QtySASNAME, survey=1) => {
    const { answers } = this.state

    const freqSASNAME = QtySASNAME ? `${SASNAME}_d` : SASNAME

    const freqID = survey === 1 ?
      Grunddatabas.find(food => food.SASNAME === freqSASNAME).Question :
      Grunddatabas3.find(food => food.SASNAME === freqSASNAME).Question

    const freqObj = answers.find( answer => answer._id === freqID )

    const timesN = freqObj && freqObj.times ? parseInt(freqObj.times) : 0
    let portionPerDay =
      freqObj && freqObj.freq === 'dag' ? timesN :
      freqObj && freqObj.freq === 'vecka' ? timesN / 7 :
      freqObj && freqObj.freq === 'manad' ? timesN / 30 : 0

    const foodQtyID = QtySASNAME ?
      survey === 1 ?
        Grunddatabas.find(food => food.SASNAME === QtySASNAME).Question :
        Grunddatabas3.find(food => food.SASNAME === QtySASNAME).Question
      : undefined

    if (foodQtyID) {
      const foodObj = answers.find( answer => answer._id === foodQtyID )
      const foodQty = foodObj ? /^(-|\+)?(\d+)$/.test(foodObj.text) ? parseInt(foodObj.text) : 0 : 0
      portionPerDay = portionPerDay * foodQty / 10
    }

    const foodSASNAME = QtySASNAME ? QtySASNAME : SASNAME

    return this.getNutrition(foodSASNAME, portionPerDay, QtySASNAME ? foodQtyID : freqID, survey)
  }

  calculateBread = (SASNAME, fat = false, survey = 1) => {
    // Uses additioned soft and crisp bread frequency + conditional fat amount
    const { answers } = this.state

    const freqID1 = survey === 1 ?
      Grunddatabas.find(food => food.SASNAME === 'soft_bread_d').Question :
      Grunddatabas3.find(food => food.SASNAME === 'soft_bread_d').Question
    const freqObj1 = answers.find( answer => answer._id === freqID1 )

    const freqID2 = survey === 1 ?
      Grunddatabas.find(food => food.SASNAME === 'crisp_bread_d').Question :
      Grunddatabas3.find(food => food.SASNAME === 'crisp_bread_d').Question
    const freqObj2 = answers.find( answer => answer._id === freqID2 )

    const timesN1 = freqObj1 && freqObj1.times ? parseInt(freqObj1.times) : 0
    let portionPerDay =
      freqObj1 && freqObj1.freq === 'dag' ? timesN1 :
      freqObj1 && freqObj1.freq === 'vecka' ? timesN1 / 7 :
      freqObj1 && freqObj1.freq === 'manad' ? timesN1 / 30 : 0

    const timesN2 = freqObj2 && freqObj2.times ? parseInt(freqObj2.times) : 0
    portionPerDay = portionPerDay + (
      freqObj2 && freqObj2.freq === 'dag' ? timesN2 :
      freqObj2 && freqObj2.freq === 'vecka' ? timesN2 / 7 :
      freqObj2 && freqObj2.freq === 'manad' ? timesN2 / 30 : 0
    )

    const fatQtyObj = answers.find( answer => answer._id === '1.5_1' )
    const fatGramsPerSlice = !fat ? 1 : fatQtyObj && fatQtyObj.value ?
      fatQtyObj.value === '0.5' ? 5 :
      fatQtyObj.value === '1' ? 10 :
      fatQtyObj.value === '1.5' ? 15 :
      fatQtyObj.value === '2' ? 20 :
      fatQtyObj.value === 'mer' ? 25 : 0 : 0

    const foodQtyID = survey === 1 ?
      Grunddatabas.find(food => food.SASNAME === SASNAME).Question :
      Grunddatabas3.find(food => food.SASNAME === SASNAME).Question

    const foodObj = answers.find( answer => answer._id === foodQtyID )
    const foodQty = foodObj ? /^(-|\+)?(\d+)$/.test(foodObj.text) ? parseInt(foodObj.text) : 0 : 0
    portionPerDay = portionPerDay * fatGramsPerSlice * foodQty / 10

    return this.getNutrition(SASNAME, portionPerDay, foodQtyID, survey)
  }

  getNutrition = (SASNAME, portionPerDay, ID, survey) => {
    const foodUnits = survey === 1 ?
      Grunddatabas.filter(food => 
        food.SASNAME === SASNAME && (!food.OPTIONS || food.OPTIONS === `${food.SASNAME}_d` || (
          food.SASNAME === "cheese_snack" && food.OPTIONS === "cheese_d"
        ))
      ) :
      Grunddatabas3.filter(food => 
        food.SASNAME === SASNAME && (!food.OPTIONS || food.OPTIONS === `${food.SASNAME}_d` || (
          food.SASNAME === "cheese_snack" && food.OPTIONS === "cheese_d"
        ))
      )

    const nutrition = foodUnits.reduce((acc, unit) => {
      const amount = typeof unit.AMOUNT === 'string' ? 1 : unit.AMOUNT
      const weight = unit.WEIGHT === '' ? 1 : unit.WEIGHT
      const gPerDay = portionPerDay * weight * amount
      const nutritionalValues = LivsmedelsDB.find(f => f.Livsmedelsnummer === unit.FOODIDNEW)

      return {
        ...Nutrients.reduce((totalNutrition, nutrient) => {
          const currentNutrientValue = Object.keys(totalNutrition).includes(nutrient) ?
            totalNutrition[nutrient] : 0

          if (nutrient === "Olöslig fibrer (g)" || nutrient === "Löslig fibrer (g)") {
            const totalFiberValue = nutritionalValues["Fibrer (g)"]
            const proportion = nutrient === "Olöslig fibrer (g)" ? "Proportion insoluble fiber" : "Proportion soluble fiber"
            const unitProp = isNaN(unit[proportion]) ? 0 : unit[proportion]

            return {
              ...totalNutrition,
              [nutrient]: currentNutrientValue +
                gPerDay * (totalFiberValue * unitProp) / 100,
            }

          } else {

            return {
              ...totalNutrition,
              [nutrient]: currentNutrientValue + gPerDay * nutritionalValues[nutrient] / 100
            }
          }
        }, acc),
        'Grams per day': acc['Grams per day'] + gPerDay
      }
    }, {'Grams per day': 0.0})


    return {
      SASNAME: SASNAME,
      Question: ID,
      ...nutrition
    }

  }

  calculateAll = (callback) => {
    this.setState({dataset: [
      ...FreqSASNAMES.map(names => this.calculate(names[0], names[1])),
      ...BreadSASNAMES.map(name => this.calculateBread(name)),
      ...FatSASNAMES.map(name => this.calculateBread(name, true)),
      ...SASNAMES.map(name => this.calculate(name)),
    ].sort((a, b) => {
      const regex = /^(\d).(\d\d?|extra)_(\d\d?)/

      if (!a.Question || !b.Question) { return 0 } else {
        const [ , , questionNrA, fieldNrA ] = regex.exec(a.Question)
        const [ , , questionNrB, fieldNrB ] = regex.exec(b.Question)

        const qA = questionNrA === 'extra' ? 1000 : questionNrA
        const qB = questionNrB === 'extra' ? 1000 : questionNrB

        return qA === qB ? fieldNrA - fieldNrB : qA - qB
      }
    }), dataset3: [
      ...FreqSASNAMES.map(names => this.calculate(names[0], names[1], 3)),
      ...BreadSASNAMES.map(name => this.calculateBread(name, false, 3)),
      ...FatSASNAMES.map(name => this.calculateBread(name, true, 3)),
      ...SASNAMES.map(name => this.calculate(name, undefined, 3)),
    ].sort((a, b) => {
      const regex = /^(\d).(\d\d?|extra)_(\d\d?)/

      if (!a.Question || !b.Question) { return 0 } else {
        const [ , , questionNrA, fieldNrA ] = regex.exec(a.Question)
        const [ , , questionNrB, fieldNrB ] = regex.exec(b.Question)

        const qA = questionNrA === 'extra' ? 1000 : questionNrA
        const qB = questionNrB === 'extra' ? 1000 : questionNrB

        return qA === qB ? fieldNrA - fieldNrB : qA - qB
      }
    })}, () => callback && callback())
  }

  render() {
    const { calculating, loading, answers, user, dataset, dataset3, allUsersDataset, allUsersDataset3, allUsersRawData, allUsersRawData2, allUsersRawData3, userCalculating, totalUsers } = this.state
    const login = userList[user]

    const cols = answers.reduce((acc, answer) => {
      return new Set([...acc, ...Object.keys(answer).filter(k => k !== '_rev')])
    }, new Set(['_id']))

    //const allUsersCols = allUsersRawData.reduce((acc, answer) => {
    //  return new Set([...acc, ...Object.keys(answer).filter(k => k !== '_rev')])
    //}, new Set(['user', '_id']))

    const allUsersCols = [...new Set(
      allUsersRawData.flatMap(r => Object.keys(r).filter(k => k !== 'user'))
    )].sort((a, b) => {

      const regex = /^(\d).(\d\d?|extra)_(\d\d?)(_custom)?/

      const [ , , questionNrA, fieldNrA, customA ] = regex.exec(a)
      const [ , , questionNrB, fieldNrB, customB ] = regex.exec(b)

      const qA = questionNrA === 'extra' ? 1000 : questionNrA
      const qB = questionNrB === 'extra' ? 1000 : questionNrB

      const fA = customA ? fieldNrA + 0.5 : fieldNrA
      const fB = customB ? fieldNrB + 0.5 : fieldNrB

      return qA === qB ? fA - fB : qA - qB
    })

    const allUsersCols2 = [...new Set(
      allUsersRawData2.flatMap(r => Object.keys(r).filter(k => k !== 'user'))
    )].sort((a, b) => {

      const regex = /^(\d).(\d\d?|extra)_(\d\d?)(_custom)?/

      const [ , , questionNrA, fieldNrA, customA ] = regex.exec(a)
      const [ , , questionNrB, fieldNrB, customB ] = regex.exec(b)

      const qA = questionNrA === 'extra' ? 1000 : questionNrA
      const qB = questionNrB === 'extra' ? 1000 : questionNrB

      const fA = customA ? fieldNrA + 0.5 : fieldNrA
      const fB = customB ? fieldNrB + 0.5 : fieldNrB

      return qA === qB ? fA - fB : qA - qB
    })

    const allUsersCols3 = [...new Set(
      allUsersRawData3.flatMap(r => Object.keys(r).filter(k => k !== 'user'))
    )].sort((a, b) => {

      const regex = /^(\d).(\d\d?|extra)_(\d\d?)(_custom)?/

      const [ , , questionNrA, fieldNrA, customA ] = regex.exec(a)
      const [ , , questionNrB, fieldNrB, customB ] = regex.exec(b)

      const qA = questionNrA === 'extra' ? 1000 : questionNrA
      const qB = questionNrB === 'extra' ? 1000 : questionNrB

      const fA = customA ? fieldNrA + 0.5 : fieldNrA
      const fB = customB ? fieldNrB + 0.5 : fieldNrB

      return qA === qB ? fA - fB : qA - qB
    })

    const sortedAnswers = answers.sort((a, b) => {
      const regex = /^(\d).(\d\d?|extra|intro)_(\d\d?)/

      if (!a._id || !b._id) { return 0 } else {
        const [ , , questionNrA, fieldNrA ] = regex.exec(a._id)
        const [ , , questionNrB, fieldNrB ] = regex.exec(b._id)

        const qA = questionNrA === 'extra' ||
          questionNrA === 'intro' ? 1000 : questionNrA
        const qB = questionNrB === 'extra' ||
          questionNrB === 'intro' ? 1000 : questionNrB

        return qA === qB ? fieldNrA - fieldNrB : qA - qB
      }
    }).map(a => {
      return Object.keys(a).reduce((acc, key) => {
        return {...acc, [key]: typeof a[key] === 'string' || typeof a[key] === 'number' ? a[key] : JSON.stringify(a[key])}
      }, {})
    })

    if (calculating) {
      return <CalculatingScreen>
        Calculating ({userCalculating} / {totalUsers}), please wait...
      </CalculatingScreen>

    } else {
      return(
        <Container>
          <div>
            <button disabled={loading} onClick={() => this.calculateAll()}>Calculate nutrition for user {login}</button>
            <ExcelFile filename={`fidura-survey-nutrition-${login}`} element={<button disabled={!dataset}>Export</button>}>
              <ExcelSheet data={dataset} name="Nutrition Survey 1">
                <ExcelColumn label="SASNAME" value="SASNAME"/>
                <ExcelColumn label="Question" value="Question"/>
                <ExcelColumn label="Grams per day" value="Grams per day"/>
                { Nutrients.map(key => <ExcelColumn label={key} key={key} value={key} /> )}
              </ExcelSheet>
              <ExcelSheet data={dataset3} name="Nutrition Survey 3">
                <ExcelColumn label="SASNAME" value="SASNAME"/>
                <ExcelColumn label="Question" value="Question"/>
                <ExcelColumn label="Grams per day" value="Grams per day"/>
                { Nutrients.map(key => <ExcelColumn label={key} key={key} value={key} /> )}
              </ExcelSheet>
              <ExcelSheet data={sortedAnswers} name="Raw data">
                { [...cols].map(key => <ExcelColumn label={key} key={key} value={key} />) }
              </ExcelSheet>
            </ExcelFile>
          </div>
          <div>
            <button disabled={loading} onClick={() => this.calculateAllUsers()}>Calculate nutrition for all users</button>
            <ExcelFile filename={`fidura-survey-data-all-users`} element={<button disabled={!allUsersDataset.length}>Export all Users</button>}>
              <ExcelSheet data={allUsersDataset} name="Nutrition Survey 1">
                <ExcelColumn label="User" value="User"/>
                { Nutrients.map(key => <ExcelColumn label={key} key={key} value={key} /> )}
              </ExcelSheet>
              <ExcelSheet data={allUsersDataset3} name="Nutrition Survey 3">
                <ExcelColumn label="User" value="User"/>
                { Nutrients.map(key => <ExcelColumn label={key} key={key} value={key} /> )}
              </ExcelSheet>
              <ExcelSheet data={allUsersRawData} name="Raw data (Enkät 1)">
                <ExcelColumn label="user" value="user" />
                { [...allUsersCols].map(key => <ExcelColumn label={key} key={key} value={key} /> )}
              </ExcelSheet>
              <ExcelSheet data={allUsersRawData2} name="Raw data (Enkät 2)">
                <ExcelColumn label="user" value="user" />
                { [...allUsersCols2].map(key => <ExcelColumn label={key} key={key} value={key} /> )}
              </ExcelSheet>
              <ExcelSheet data={allUsersRawData3} name="Raw data (Enkät 3)">
                <ExcelColumn label="user" value="user" />
                { [...allUsersCols3].map(key => <ExcelColumn label={key} key={key} value={key} /> )}
              </ExcelSheet>
            </ExcelFile>
          </div>
        </Container>
      )
    }
  }
}

const Control = connect(mapStateToProps, mapDispatchToProps)(ConnectedControl);

export default Control;
