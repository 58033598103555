import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import styled from 'styled-components';
import { Validate, Headline, Radio, Title, Previous } from '../../fields';

export const requiredAnswers = [14, 15, 16, 17, 18, 19, 20, 21, 22, 23]

const Text = styled.p`
font-weight: bold;
font-size: 1.1em;
line-height: 1.3em;
text-align: justify;
`

const Spacer = styled.div`
height: 1.2em;
`

export class ForeCancern extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <div id="top">
        <Title>FÖRE CANCERN</Title>
        <Text>Den här studien handlar om hur vi på bästa sätt kan ge råd för att förebygga sammanväxning av vagina under och efter strålbehandling. Därför följer här frågor om tidigare erfarenheter och vanor som gäller införande av något i vagina.  När vi skriver vaginalt sex menar vi i den här studien, sexuell aktivitet själv eller med partner där något förs in i slidan, exempelvis fingrar, föremål, dildo eller penis (vaginalt samlag). Frågorna gäller sexuell hälsa innan du fick din cancerdiagnos.</Text>
        <Headline
            nr="1"
            anchor="14"
            question="När du har haft sex med partner de senaste 10 åren, har det varit med någon form av vaginalt sex (exempelvis samlag)?"
        />
        <Radio _id="0.14_1" value="inteAktuellt" desc="Inte aktuellt, jag har inte haft sex med partner de senaste 10 åren" />
        <Radio _id="0.14_1" value="nej" desc="Nej, aldrig" />
        <Radio _id="0.14_1" value="farreAnHalften" desc="Ja, vid färre än hälften av tillfällena som jag har haft sex" />
        <Radio _id="0.14_1" value="halften" desc="Ja, ungefär hälften av tillfällena som jag har haft sex" />
        <Radio _id="0.14_1" value="flerAnHalften" desc="Ja, vid fler än hälften av tillfällena som jag har haft sex" />
        <Radio _id="0.14_1" value="varje" desc="Ja, vid varje tillfälle som jag har haft sex" />

        <Spacer />
        <Text>Nu kommer vi ställa frågor som gäller sexuell hälsa <i><u>året innan</u></i> du fick din cancerdiagnos</Text>

        <Headline
            nr="2"
            anchor="15"
            question="Ungefär hur ofta hade du vaginalt sex eller motsvarande, det senaste året innan din cancerdiagnos?"
        />
        <Radio _id="0.15_1" value="inteAktuellt" desc="Inte aktuellt" />
        <Radio _id="0.15_1" value="aldrig" desc="Aldrig" />
        <Radio _id="0.15_1" value="nagraGanger" desc="Några gånger" />
        <Radio _id="0.15_1" value="enTvaGangerPerManad" desc="1-2 gånger per månad" />
        <Radio _id="0.15_1" value="treFyraGangerPerManad" desc="3-4 gånger per månad" />
        <Radio _id="0.15_1" value="enTvaGangerPerVecka" desc="1-2 gånger per vecka" />
        <Radio _id="0.15_1" value="fler" desc="Fler än 2 gånger per vecka" />

        <Headline
            nr="3"
            anchor="16"
            question="Ungefär hur ofta har Du haft orgasm i genomsnitt, det senaste året innan din cancerdiagnos?"
        />
        <Radio _id="0.16_1" value="inteAktuellt" desc="Inte aktuellt" />
        <Radio _id="0.16_1" value="aldrig" desc="Aldrig" />
        <Radio _id="0.16_1" value="nagraGanger" desc="Några gånger" />
        <Radio _id="0.16_1" value="enTvaGangerPerManad" desc="1-2 gånger per månad" />
        <Radio _id="0.16_1" value="treFyraGangerPerManad" desc="3-4 gånger per månad" />
        <Radio _id="0.16_1" value="enTvaGangerPerVecka" desc="1-2 gånger per vecka" />
        <Radio _id="0.16_1" value="fler" desc="Fler än 2 gånger per vecka" />

        <Headline
            nr="4"
            anchor="17"
            question="Har du haft ytlig smärta, i vulva eller i underlivet (dvs. smärtor från slemhinnorna och slidöppningen) vid vaginalt sex, det senaste året före din cancerdiagnos?"
        />
        <Radio _id="0.17_1" value="inteAktuellt" desc="Inte aktuellt, jag har inte haft vaginalt samlag" />
        <Radio _id="0.17_1" value="nej" desc="Nej, aldrig" />
        <Radio _id="0.17_1" value="nagonEnstaka" desc="Någon enstaka gång" />
        <Radio _id="0.17_1" value="nagraGanger" desc="Några gånger" />
        <Radio _id="0.17_1" value="nastanVarjeGang" desc="Nästan varje gång" />
        <Radio _id="0.17_1" value="varjeGang" desc="Varje gång" />

        <Headline
            nr="5"
            anchor="18"
            question="Har du haft sveda, brännande eller skärande känsla från slemhinnorna och i slidöppningen i samband med vaginalt sex, det senaste året före din cancerdiagnos?"
        />
        <Radio _id="0.18_1" value="inteAktuellt" desc="Inte aktuellt, jag har inte haft vaginalt samlag" />
        <Radio _id="0.18_1" value="nej" desc="Nej, aldrig" />
        <Radio _id="0.18_1" value="nagonEnstaka" desc="Någon enstaka gång" />
        <Radio _id="0.18_1" value="nagraGanger" desc="Några gånger" />
        <Radio _id="0.18_1" value="nastanVarjeGang" desc="Nästan varje gång" />
        <Radio _id="0.18_1" value="varjeGang" desc="Varje gång" />

        <Headline
            nr="6"
            anchor="19"
            question="Har du haft besvär med <u>djupt liggande</u> smärta vid samlag, dvs smärtor från djupet av slidan och inne i själva bäckenet, det senaste året före din cancerdiagnos?"
        />
        <Radio _id="0.19_1" value="inteAktuellt" desc="Inte aktuellt, jag har inte haft vaginalt samlag" />
        <Radio _id="0.19_1" value="nej" desc="Nej, aldrig" />
        <Radio _id="0.19_1" value="nagonEnstaka" desc="Någon enstaka gång" />
        <Radio _id="0.19_1" value="nagraGanger" desc="Några gånger" />
        <Radio _id="0.19_1" value="nastanVarjeGang" desc="Nästan varje gång" />
        <Radio _id="0.19_1" value="varjeGang" desc="Varje gång" />

        <Headline
            nr="7"
            anchor="20"
            question="Hur nöjd totalt sett har du varit med ditt sexliv, det senaste året före din cancerdiagnos?"
        />
        <Radio _id="0.20_1" value="inteAllsNojd" desc="Inte alls nöjd" />
        <Radio _id="0.20_1" value="liteNojd" desc="Lite nöjd" />
        <Radio _id="0.20_1" value="mattligtNojd" desc="Måttligt nöjd" />
        <Radio _id="0.20_1" value="mycketNojd" desc="Mycket nöjd" />

        <Headline
            nr="8"
            anchor="21"
            question="Har du använt glidsalva/glidmedel vid sexuell aktivitet, det senaste året före din cancerdiagnos?"
        />
        <Radio _id="0.21_1" value="inteAktuellt" desc="Inte aktuellt" />
        <Radio _id="0.21_1" value="nej" desc="Nej, inte alls" />
        <Radio _id="0.21_1" value="nagonGang" desc="Ja, någon gång" />
        <Radio _id="0.21_1" value="ibland" desc="Ja, ibland" />
        <Radio _id="0.21_1" value="alltid" desc="Ja, alltid" />

        <Headline
            nr="9"
            anchor="22"
            question="Hur viktigt har det varit för <u>dig</u> med vaginalt samlag eller motsvarande vid sexuell aktivitet det senaste året före din cancerdiagnos?"
        />
        <Radio _id="0.22_1" value="inteAktuellt" desc="Inte aktuellt, jag har inte haft sex med partner" />
        <Radio _id="0.22_1" value="inteViktigt" desc="Inte alls viktigt" />
        <Radio _id="0.22_1" value="liteViktigt" desc="Lite viktigt" />
        <Radio _id="0.22_1" value="mattligtViktigt" desc="Måttligt viktigt" />
        <Radio _id="0.22_1" value="mycketViktigt" desc="Mycket viktigt" />

        <Headline
            nr="10"
            anchor="23"
            question="Hur viktigt har det varit för din/dina <u>partners</u> med vaginalt samlag eller motsvarande vid sexuell aktivitet det senaste året före cancerdiagnos?"
        />
        <Radio _id="0.23_1" value="inteAktuellt" desc="Inte aktuellt, jag har inte haft sex med partner" />
        <Radio _id="0.23_1" value="inteViktigt" desc="Inte alls viktigt" />
        <Radio _id="0.23_1" value="liteViktigt" desc="Lite viktigt" />
        <Radio _id="0.23_1" value="mattligtViktigt" desc="Måttligt viktigt" />
        <Radio _id="0.23_1" value="mycketViktigt" desc="Mycket viktigt" />

        <Validate className="next" answers={requiredAnswers} enkat="0" offset={13}>
          <Link to="sexuellKrankning#top">
            <button>Fortsätt &#8250;</button>
          </Link>
        </Validate><Previous to="kvinnohalsa" />
      </div>
    );
  }
}

export default requiredAnswers;
