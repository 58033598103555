import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import styled from 'styled-components';
import { Textarea, Validate, Headline, Radio, Title, Previous } from '../../fields';
import { validateSurvey } from '../../DB';

export const requiredAnswers = [40, 41, 42, 43]

const Text = styled.p`
font-weight: bold;
font-size: 1.1em;
line-height: 1.3em;
text-align: justify;
`

const Instructions = styled.div`
font-size: 1.1em;
line-height: 1.3em;
`

export class Avslutande extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <div id="top">
        <Title>AVSLUTANDE FRÅGOR</Title>
        <Text>Nu kommer vi be dig att göra en mätning av slidan med hjälp av en vaginalstav som du fått av studieansvarig sjuksköterska. Du har fått två vaginalstavar, välj den vaginalstav som storleksmässigt är möjlig för dig att använda.</Text>

        <Instructions>
          <p>1) Du kan börja med den något större staven, lägg på olja eller glidmedel</p>
          <p>2) För in vaginalstaven långsamt i slidan utan att forcera. Det ska inte göra ont. Gör det ont behöver du avbryta och prova igen efter en stund.</p>
          <p>3) Håll kvar staven i slidan med ett lätt tryck mot slidtoppen</p>
          <p>4) Placera ett finger på staven vid slidöppningen och håll kvar fingret på staven</p>
          <p>5) Läs av på staven vilken markering (1,2,3 eller 4) som ligger närmast ditt grepp.</p>
          <p>6) Var vänlig svara på frågorna nedan</p>
        </Instructions>

        <Headline
            nr="1"
            anchor="40"
            question="När du använde vaginalstav idag, vilken storlek var då möjlig att använda?"
        />
        <Radio _id="0.40_1" value="inteAktuellt" desc="Inte aktuellt, jag har inte använt vaginalstav" />
        <Radio _id="0.40_1" value="inteMojligt" desc="Det är inte möjligt att föra in någon vaginalstav" />
        <Radio _id="0.40_1" value="mindre" desc="Den mindre staven" />
        <Radio _id="0.40_1" value="storre" desc="Den större staven" />

        <Headline
            nr="2"
            anchor="41"
            question="När du använde vaginalstav idag, vilken nummer-markering kom du då närmast, när du höll staven införd och håller fingret invid slidmynningen?"
        />
        <Radio _id="0.41_1" value="inteAktuellt" desc="Inte aktuellt, jag har inte använt vaginalstav" />
        <Radio _id="0.41_1" value="inteMojligt" desc="Det är inte möjligt att föra in någon vaginalstav" />
        <Radio _id="0.41_1" value="1" desc="1" />
        <Radio _id="0.41_1" value="2" desc="2" />
        <Radio _id="0.41_1" value="3" desc="3" />
        <Radio _id="0.41_1" value="4" desc="4" />

        <Headline
            nr="3"
            anchor="42"
            question="När du använt vaginalstaven, gav det dig känslor av obehag?"
        />
        <Radio _id="0.42_1" value="inteAktuellt" desc="Inte aktuellt, jag har inte använt vaginalstav" />
        <Radio _id="0.42_1" value="inteAlls" desc="Nej, inte alls" />
        <Radio _id="0.42_1" value="lite" desc="Ja, lite" />
        <Radio _id="0.42_1" value="mattligt" desc="Ja, måttligt" />
        <Radio _id="0.42_1" value="mycket" desc="Ja, mycket" />

        <Headline
            nr="4"
            anchor="43"
            question="När du läst igenom och besvarat det här formuläret, har det gett dig känslor av obehag?"
        />

        <Radio _id="0.43_1" value="inteAlls" desc="Nej, inte alls" />
        <Radio _id="0.43_1" value="lite" desc="Ja, lite" />
        <Radio _id="0.43_1" value="mattligt" desc="Ja, måttligt" />
        <Radio _id="0.43_1" value="mycket" desc="Ja, mycket" />

        <Headline
            anchor="extra_2"
            question="Nedan följer några rader där Du själv kan berätta eller komplettera frågan angående hur det varit för dig att besvara det här formuläret."
        />
        <Textarea _id="0.extra_2" />

        <Validate className="next" answers={requiredAnswers} enkat="0" offset={39}>
          <Link to="tack#top">
            <button onClick={ () => validateSurvey('baseline') }>Skicka &#8250;</button>
          </Link>
        </Validate><Previous to="allmanna" />
      </div>
    );
  }
}

export default requiredAnswers;
