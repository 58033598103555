import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Validate, Headline, Radio, Title, Previous } from '../../fields';

export const requiredAnswers = [1, 2, 3, 4, 5, 6]

export class Allmanna extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <div id="top">
        <Title>ALLMÄNNA FRÅGOR</Title>

        <Headline
            nr="1"
            question="Har du avslutat din strålbehandling mot bäckenområdet?"
        />
        <Radio _id="1.1_1" value="nej" desc="Nej" />
        <Radio _id="1.1_1" value="ja" desc="Ja" />

        <Headline
            nr="2"
            question="Vilken av följande cancerdiagnoser har du fått behandling för?"
        />
        <Radio _id="1.2_1" value="livmodercancer" desc="Livmodercancer (Corpuscancer)" />
        <Radio _id="1.2_1" value="livmoderhalscancer" desc="Livmoderhalscancer (Cervixcancer)" />
        <Radio _id="1.2_1" value="analcancer" desc="Analcancer" />
        <Radio _id="1.2_1" value="vaginacancer" desc="Vaginacancer" />
        <Radio _id="1.2_1" value="Vulvacancer" desc="Vulvacancer" />

        <Headline
            nr="3"
            question="Har du fått brachybehandling (inre strålbehandling)?"
        />
        <Radio _id="1.3_1" value="nej" desc="Nej" />
        <Radio _id="1.3_1" value="ja" desc="Ja" />

        <Headline
            nr="4"
            question="Har du fått cellgiftsbehandling?"
        />
        <Radio _id="1.4_1" value="nej" desc="Nej" />
        <Radio _id="1.4_1" value="ja" desc="Ja" />

        <Headline
            nr="5"
            question="Har du opererats för cancer i bäckenområdet?"
        />
        <Radio _id="1.5_1" value="nej" desc="Nej" />
        <Radio _id="1.5_1" value="ja" desc="Ja" />

        <Headline
            nr="6"
            question="Har du rökt under din strålbehandling?"
        />
        <Radio _id="1.6_1" value="nej" desc="Nej" />
        <Radio _id="1.6_1" value="nagraGanger" desc="Ja, några gånger i veckan" />
        <Radio _id="1.6_1" value="farreAn10cig" desc="Ja, färre än 10 cigaretter per dag" />
        <Radio _id="1.6_1" value="flerAn10cig" desc="Ja, 11 cigaretter per dag eller fler" />

        <Validate className="next" answers={requiredAnswers} enkat="1">
          <Link to="sexualitet#top">
            <button>Fortsätt &#8250;</button>
          </Link>
        </Validate><Previous to="intro" />
      </div>
    );
  }
}

export default requiredAnswers;
