import React from 'react'
import styled from 'styled-components';

const Container = styled.div`

  margin: 3em 0 1em;

  progress[value] {
    width: 100%;
    margin: 0 auto;
    appearance: none;

    ::-webkit-progress-bar {
      height: 1em;
      border-radius: 20px;
      background-color: #eee;
    }

    ::-webkit-progress-value {
      height: 1em;
      border-radius: 20px;
      background-color: #1d62d1;
    }
  }
`

const Progress = (props) => {

  return (
    <Container>
      <progress max={props.max} value={props.value} />
    </Container>
  )
}

export default Progress;
