import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { FreqInput, Input, Validate, Checkbox, Headline, Title, OBS, Previous, Progress } from '../../fields';
import { numberOfCategories } from './index';

export const requiredAnswers = [8, 9, 10, 11]

export class GrotFlingorMusli extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <div id="top">
        <Progress max={numberOfCategories} value={2} />
        <Title>GRÖT, FLINGOR OCH MÜSLI</Title>
        <Headline
            nr="8"
            question="Hur ofta äter du gröt?"
            desc="Fyll i antal gånger och klicka endast i ett alternativ (dag, vecka eller månad). Om du aldrig äter
            gröt, klicka i rutan för ”aldrig”."
        />
        <FreqInput title="Någon typ av gröt" _id="1.8_1" />

        <Headline
            nr="9"
            question="Tänk dig att du äter gröt <u>tio gånger</u> i följd."
            desc="Hur många gånger brukar gröten vara:"
        />
        <Checkbox hidden _id="1.9_0" aldrigToggle="1.8_1" />
        <Input ten desc="Havregrynsgröt" _id="1.9_1" />
        <Input ten desc="Mannagryns-/risgrynsgröt" _id="1.9_2" />
        <Input ten desc="Rågmjöls-/rågflingegröt" _id="1.9_3" />
        <OBS sumTen="1.9" />

        <Headline
            nr="10"
            question="Hur ofta äter du flingor eller müsli?"
            desc="Fyll i antal gånger och klicka endast i ett alternativ (dag, vecka eller månad). Om du aldrig äter flingor och müsli, klicka i rutan för ”aldrig”."
        />
        <FreqInput title="Någon typ av flingor eller müsli" _id="1.10_1" />

        <Headline
            nr="11"
            question="Tänk dig att du äter flingor eller müsli <u>tio portioner</u> i följd."
            desc="Hur många gånger brukar flingorna eller müslin vara:"
        />
        <Checkbox hidden _id="1.11_0" aldrigToggle="1.10_1" />
        <Input ten desc="Cornflakes" _id="1.11_1" />
        <Input ten desc="Branflakes" _id="1.11_2" />
        <Input ten desc="Havrefras, Rågfras" _id="1.11_3" />
        <Input ten desc="Müsli (typ Crunchy, Start)" _id="1.11_4" />
        <Input ten desc="Müsli (typ blandade gryner med/utan torkad frukt)" _id="1.11_5" />
        <OBS sumTen="1.11" />

        <Validate className="next" answers={requiredAnswers}>
          <Link to="notter-fron#top">
            <button>Fortsätt &#8250;</button>
          </Link>
        </Validate><Previous to="smorgasar" />
      </div>
    );
  }
}

export default GrotFlingorMusli;
