import React from 'react'
import styled from 'styled-components';
import { connect } from "react-redux";
import { commitAnswer } from "../Actions";

const DefaultProps = {
  vad: Array(9).fill(''),
  mangdPerVecka: Array(9).fill(''),
  hurLange: Array(9).fill(''),
  varumarkesnamn: Array(9).fill(''),
  disabled: false
}

const Container = styled.div`
margin: 0 1em 0 2em;
`

const Table = styled.table`
width: 500px;
border-spacing: 0;
border-collapse: collapse;
border: 1px solid black;

th, td {
  border: 1px solid black;
}
`

const Input = styled.input`
margin: 0;
padding: 0.2em 0.5em;
border-color: white;
border-style: none;
height: 100%;

&:focus {
  outline: none;
}
`

const ExampleRow = styled.tr`
& td {
  padding: 0.2em 0.4em;
  font-style: italic;
  font-size: 0.9em;
}
`

const mapStateToProps = (state, ownProps) => {
  let stateObj = state.answers.find( answer => answer._id === ownProps._id );

  // If there is a checked checkbox on top, with ID X_0, disable the field
  let checkboxID = ownProps._id.replace(/_.+/g, "_0")
  let checkbox = state.answers.find( answer => answer._id === checkboxID)
  let checked = checkbox !== undefined && 'checked' in checkbox && checkbox.checked
  return {...DefaultProps, ...stateObj, disabled: checked}
};

const mapDispatchToProps = dispatch => {
  return {
    commitAnswer: answer => dispatch(commitAnswer(answer))
  };
};

class ConnectedVitaminTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: props._id,
      vad: props.vad,
      mangdPerVecka: props.mangdPerVecka,
      hurLange: props.hurLange,
      varumarkesnamn: props.varumarkesnamn,
      disabled: props.disabled
    };

    this.changeCellValue = this.changeCellValue.bind(this);
  }

  componentDidUpdate(prevProps) {
    Object.keys(DefaultProps).forEach( key => {
      if (this.props[key] !== prevProps[key])
        this.setState({ [key]: this.props[key] })
    })
  }

  changeCellValue(event) {
    const col = event.target.getAttribute('data-col')
    const row = event.target.getAttribute('data-row')
    const val = event.target.value
    const newColValues = Object.assign([...this.state[col]], {[row]: val})

    this.setState({
      [col]: newColValues
    }, () => this.saveChanges());
  }

  saveChanges() {
    const _id = this.props._id;
    let { vad, mangdPerVecka, hurLange, varumarkesnamn } = this.state;
    this.props.commitAnswer({ _id, vad, mangdPerVecka, hurLange, varumarkesnamn })
  }

  render() {
    const cols = ['vad', 'varumarkesnamn', 'mangdPerVecka', 'hurLange']

    const { examples } = this.props

    const renderedRows = [...Array(9).keys()].map( row => {
      //let ex = row < examples.length ? examples[row] : [];
      return (
        <tr key={row}>
          {cols.map( (col, i) => (
            <td key={col}>
              <Input
                type="text"
                value={this.props[col][row]}
                onChange={this.changeCellValue}
                disabled={this.props.disabled}
                data-col={col}
                data-row={row}
              />
            </td>
          ))}
        </tr>
      )
    })

    return(
      <Container>
        <Table>
          <thead>
            <tr>
              <th>Vad</th>
              <th>Varumärkesnamn</th>
              <th>Mängd per vecka</th>
              <th>Hur länge</th>
            </tr>
          </thead>
          <tbody>

            { examples.length > 0 && (
            <ExampleRow>
              <td>{ examples[0][0] }</td>
              <td>{ examples[0][1] }</td>
              <td>{ examples[0][2] }</td>
              <td>{ examples[0][3] }</td>
            </ExampleRow>
            ) }

            { examples.length > 1 && (
            <ExampleRow>
              <td>{ examples[1][0] }</td>
              <td>{ examples[1][1] }</td>
              <td>{ examples[1][2] }</td>
              <td>{ examples[1][3] }</td>
            </ExampleRow>
            ) }

            { examples.length > 2 && (
            <ExampleRow>
              <td>{ examples[2][0] }</td>
              <td>{ examples[2][1] }</td>
              <td>{ examples[2][2] }</td>
              <td>{ examples[2][3] }</td>
            </ExampleRow>
            ) }

            {renderedRows}
          </tbody>
        </Table>
      </Container>
    )
  }
}

const VitaminTable = connect(mapStateToProps, mapDispatchToProps)(ConnectedVitaminTable);

export default VitaminTable;
