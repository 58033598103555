import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { FreqInput, Input, Checkbox, Validate, Headline, Title, OBS, Ex, Previous, Progress } from '../../fields';
import { numberOfCategories } from './index';

export const requiredAnswers = [31, 32]

export class Fisk extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <div id="top">
        <Progress max={numberOfCategories} value={7} />
        <Title>FISK OCH SKALDJUR</Title>
        <Headline
            nr="31"
            question="Hur ofta äter du fisk och skaldjur?"
            desc="Fyll i antal gånger och klicka endast i ett alternativ (dag, vecka eller månad). Om du aldrig äter fisk, klicka i rutan för ”aldrig”."
        />
        <FreqInput title="Någon typ av fisk eller skaldjur" _id="1.31_1" />
        <Ex>(t ex lax, fiskpinnar, sill, räkor)</Ex>

        <Headline
            nr="32"
            question="Tänk dig att du äter fisk eller skaldjur <u>tio gånger</u> i följd."
            desc="Hur många gånger av dessa brukar fisken eller skaldjuren vara:"
        />
        <Checkbox hidden _id="1.32_0" aldrigToggle="1.31_1" />
        <Input ten desc="Sill, strömming, makrill eller liknande" _id="1.32_1" />
        <Input ten desc="Lax, röding eller liknande" _id="1.32_2" />
        <Input ten desc="Torsk, sej, spätta eller liknande" _id="1.32_3" />
        <Input ten desc="Fiskpinnar, panerad fisk eller liknande" _id="1.32_4" />
        <Input ten desc="Räkor, krabba, musslor eller liknande" _id="1.32_5" />
        <OBS sumTen="1.32" />

        <Validate className="next" answers={requiredAnswers}>
          <Link to="vegetarisk#top">
            <button>Fortsätt &#8250;</button>
          </Link>
        </Validate><Previous to="kott" />
      </div>
    );
  }
}

export default Fisk;
