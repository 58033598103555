import React, { Component } from "react";

import { Intro, Kvinnohalsa, ForeCancern, SexuellKrankning, Allmanna, Avslutande,
  requiredAnswers } from '.././enkat0';

import Panel from '../../Panel';

class Enkat0 extends Component {
  render() {
    return (
      <>
        <Panel admin answers={requiredAnswers} />

        <Intro />
        <Kvinnohalsa />
        <ForeCancern />
        <SexuellKrankning />
        <Allmanna />
        <Avslutande />
      </>
    );
  }
}

export default Enkat0;
