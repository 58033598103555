import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Headline, Validate, Radio, Title, Textarea, Progress, Previous } from '../../fields';
import styled from 'styled-components';
import { numberOfCategories } from './index';

export const requiredAnswers = [72, 73, 74, 75, 76]

const Italic = styled.div`
margin: 2.5em 0 -2em 0;
font-style: italic;
`

export class Fysisk extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <div id="top">
        <Progress max={numberOfCategories} value={16} />
        <Title>FYSISK AKTIVITET OCH MOTION</Title>

        <Italic>Frågan avser den senaste veckan:</Italic>
        <Headline
            nr="72"
            question="Hur mycket tid under den <u>senaste veckan</u> ägnade du dig åt fysisk träning som får dig att bli andfådd, till exempel löpning, motionsgymnastik eller bollsport?"
        />
        <Radio _id="1.72_1" value="ingenTid" desc="Ingen tid" />
        <Radio _id="1.72_1" value="uppTill0.5" desc="Upp till 0,5 timmar" />
        <Radio _id="1.72_1" value="0.5till1" desc="0,5–1 timmar" />
        <Radio _id="1.72_1" value="1till1.5" desc="1–1,5 timmar" />
        <Radio _id="1.72_1" value="1.5till2.5" desc="1,5–2,5 timmar " />
        <Radio _id="1.72_1" value="2.5till5" desc="2,5–5 timmar" />
        <Radio _id="1.72_1" value="merAn5" desc="Mer än 5 timmar" />

        <Italic>Frågan avser den senaste veckan:</Italic>
        <Headline
            nr="73"
            question="Hur mycket tid under den <u>senaste veckan</u> ägnade du dig åt vardagsmotion, till exempel promenader, cykling eller trädgårdsarbete?"
        />
        <Radio _id="1.73_1" value="ingenTid" desc="Ingen tid" />
        <Radio _id="1.73_1" value="uppTill0.5" desc="Upp till 0,5 timmar" />
        <Radio _id="1.73_1" value="0.5till1" desc="0,5–1 timmar" />
        <Radio _id="1.73_1" value="1till1.5" desc="1–1,5 timmar" />
        <Radio _id="1.73_1" value="1.5till2.5" desc="1,5–2,5 timmar " />
        <Radio _id="1.73_1" value="2.5till5" desc="2,5–5 timmar" />
        <Radio _id="1.73_1" value="merAn5" desc="Mer än 5 timmar" />

        <Italic>Frågan avser den senaste veckan:</Italic>
        <Headline
            nr="74"
            question="Hur mycket sitter eller ligger du under ett normalt dygn om man räknar bort din nattsömn?"
        />
        <Radio _id="1.74_1" value="16ellerMer" desc="16 timmar eller mer" />
        <Radio _id="1.74_1" value="13till15" desc="13–15 timmar" />
        <Radio _id="1.74_1" value="10till12" desc="10-12 timmar" />
        <Radio _id="1.74_1" value="7till9" desc="7-9 timmar" />
        <Radio _id="1.74_1" value="4till6" desc="4-6 timmar" />
        <Radio _id="1.74_1" value="1till3" desc="1-3 timmar" />
        <Radio _id="1.74_1" value="0" desc="0 timmar" />

        <Headline
            nr="75"
            question="Har du upplevt att någon fysisk aktivitet har <u>förvärrat</u> tidigare nämnda tarmsymtom (fråga 45-69)?"
        />
        <Radio _id="1.75_1" value="nej" desc="Nej" />
        <Radio _id="1.75_1" value="ja" desc="Ja" />
        <Radio _id="1.75_1" value="inteAktuellt" desc="Inte aktuellt, jag har inga tarmsymptom" />

        <Textarea
          _id="1.75_2"
          desc="<u>Om ja</u>, vilket slags fysisk aktivitet har förvärrat dina tarmsymtom?"
        />
        <Textarea
          _id="1.75_3"
          desc="<u>Om ja</u>, hur har tarmsymtomen förvärrats?"
        />

        <Headline
            nr="76"
            question="Har du upplevt att någon fysisk aktivitet har <u>förbättrat</u> tidigare nämnda tarmsymtom (fråga 45-69)?"
        />
        <Radio _id="1.76_1" value="nej" desc="Nej" />
        <Radio _id="1.76_1" value="ja" desc="Ja" />
        <Radio _id="1.76_1" value="inteAktuellt" desc="Inte aktuellt, jag har inga tarmsymptom" />

        <Textarea
          _id="1.76_2"
          desc="<u>Om ja</u>, vilket slags fysisk aktivitet har förbättrat dina tarmsymtom?"
        />
        <Textarea
          _id="1.76_3"
          desc="<u>Om ja</u>, hur har tarmsymtomen förbättrats?"
        />

        <Validate className="next" answers={requiredAnswers}>
          <Link to="mediciner#top">
            <button>Fortsätt &#8250;</button>
          </Link>
        </Validate><Previous to="matvanor" />

      </div>
    );
  }
}

export default Fysisk;
