import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import styled from 'styled-components';
import { Headline, Validate, Radio, Title, Checkbox, Progress, Previous, VitaminTable } from '../../fields';
import { numberOfCategories } from './index';

export const requiredAnswers = [80, 81, '82_1', '82_2', 83, 84]

const Text = styled.p`
margin: 1em 0 1em 2em;
`

export class Halsa extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <div id="top">
        <Progress max={numberOfCategories} value={19} />
        <Title>HÄLSA OCH MEDICINER</Title>

        <Headline
          nr="80"
          question="Har du någon eller några av följande sjukdomar (diagnostiserad av läkare)?"
        />
        <Radio multiple _id="1.80_1" value="hogtBlodtryck" desc="Högt blodtryck" />
        <Radio multiple _id="1.80_1" value="hjartsvikt" desc="Hjärtsvikt" />
        <Radio multiple _id="1.80_1" value="karlkramp" desc="Kärlkramp" />
        <Radio multiple _id="1.80_1" value="parkinson" desc="Parkinson" />
        <Radio multiple _id="1.80_1" value="ms" desc="MS (Multipel skleros)" />
        <Radio multiple _id="1.80_1" value="artrit" desc="Reumatoid artrit" />
        <Radio multiple _id="1.80_1" value="epilepsi" desc="Epilepsi" />
        <Radio multiple _id="1.80_1" value="njursjukdom" desc="Njursjukdom" />
        <Radio multiple _id="1.80_1" value="tarmsjukdom" desc="Inflammatorisk tarmsjukdom (t ex Crohns sjukdom, Ulcerös kolit)" />
        <Radio multiple _id="1.80_1" value="ibs" desc="Irritabel tarm (IBS)" />
        <Radio multiple _id="1.80_1" value="celiaki" desc="Celiaki (glutenintolerans)" />
        <Radio multiple _id="1.80_1" value="typ1diabetes" desc="Typ 1-diabetes" />
        <Radio multiple _id="1.80_1" value="typ2diabetes" desc="Typ 2-diabete" />
        <Radio multiple _id="1.80_1" value="annan" desc="Annan sjukdom (förutom din nuvarande cancerdiagnos):" custom />

        <br />

        <Checkbox _id="1.80_0" desc="Jag har ingen av dessa sjukdomar (diagnostiserad av läkare)" />

        <Headline
          nr="81"
          question="Vilken cancerdiagnos får eller ska du få behandling för nu?"
        />
        <Radio _id="1.81_1" value="livmodercancer" desc="Livmodercancer (Corpuscancer)" />
        <Radio _id="1.81_1" value="livmoderhalscancer" desc="Livmoderhalscancer (Cervixcancer)" />
        <Radio _id="1.81_1" value="vulvacancer" desc="Vulvacancer" />
        <Radio _id="1.81_1" value="prostatacancer" desc="Prostatacancer" />
        <Radio _id="1.81_1" value="urinblasecancer" desc="Urinblåsecancer" />
        <Radio _id="1.81_1" value="tjocktarmscancer" desc="Tjocktarmscancer (koloncancer)" />
        <Radio _id="1.81_1" value="andtarmscancer" desc="Ändtarmscancer (rektalcancer)" />
        <Radio _id="1.81_1" value="analcancer" desc="Analcancer" />
        <Radio _id="1.81_1" value="annat" desc="Annat, nämligen:" custom />

        <Headline
          nr="82"
          question="Hur många gånger har du ätit antibiotika mot infektioner (t ex penicillin, Kåvepenin, Ery-Max, Vibramycin)?"
        />
        <Text>Det senaste året:</Text>
        <Radio _id="1.82_1" value="0" desc="0 gånger" />
        <Radio _id="1.82_1" value="1-3" desc="1 – 3 gånger" />
        <Radio _id="1.82_1" value="4-6" desc="4 – 6 gånger" />
        <Radio _id="1.82_1" value="flerAn6" desc="fler än 6 gånger" />
        <Radio _id="1.82_1" value="vetEj" desc="vet ej" />

        <Text>De senaste (två till) fem åren:</Text>
        <Radio _id="1.82_2" value="0" desc="0 gånger" />
        <Radio _id="1.82_2" value="1-3" desc="1 – 3 gånger" />
        <Radio _id="1.82_2" value="4-6" desc="4 – 6 gånger" />
        <Radio _id="1.82_2" value="flerAn6" desc="fler än 6 gånger" />
        <Radio _id="1.82_2" value="vetEj" desc="vet ej" />

        <Headline
            nr="83"
            question="Fyll i ditt intag av gas- och tarmreglerande medel (förstoppande, laxerande) som du regelbundet tagit de tre senaste månaderna."
            desc="T ex Dimetikon, Minifom, Dimor, Imodium, Inolaxol, Vi-Siblin, Lunelax, Cilaxoral."
            small="Ange vad du ätit, varumärkesnamn (vad produkten kallas), ungefärlig mängd per vecka och ungefär hur länge du har använt det."
        />
        <Checkbox _id="1.83_0" desc="Jag har inte ätit tarmreglerande medel de senaste tre månaderna." />
        <VitaminTable _id="1.83_1" examples={[
          ['T ex gasdämpande', 'Dimetikon', '7 tabletter', '6 mån' ],
          ['T ex förstoppande medel', 'Dimor', '7 tabletter', '6 mån' ],
          ['T ex laxerande medel', 'Cilaxoral', '10 droppar', '1 mån' ]
        ]} />

        <Headline
          nr="84"
          question="Äter du <u>dagligen</u> något av nedanstående läkemedel?"
        />
        <Radio multiple _id="1.84_1" value="statin" desc="<b>Statin (läkemedel mot höga blodfetter): </b> <small>Atorvastatin, Cerivastatin, Crestor, Fluvastatin, Lovastatin, Simvastatin, Pitavastatin, Pravastatin, Rosuvastatin.</small>" />
        <Radio multiple _id="1.84_1" value="hogtBlodtryck" desc="<b>ACE-hämmare (läkemedel mot högt blodtryck):</b> <small>Benazepril, Cilazapril, Delapril, Enalapril, Fosinopril, Imidapril, Kaptopril, Kinapril, Lisinopril, Moexipril, Perindopril, Ramipril, Renitec, Spirapril, Temokapril, Trandolapril, Triatec, Zestril, Zofenopril.</small>" />
        <Radio multiple _id="1.84_1" value="inflammation" desc="<b>Något av följande läkemedel mot smärta eller inflammation:</b> <small>Acetylsalicylsyra, Aspirin, Bamyl, Diklofenak, Ibuprofen, Ipren, Naproxen, Pronaxen, Treo, Voltaren.</small>" />
        <Radio multiple _id="1.84_1" value="liknande" desc="Liknande läkemedel som inte är nämnda:" custom />

        <br />

        <Checkbox _id="1.84_0" desc="Jag äter inget av dessa läkemedel eller liknande <u>dagligen</u>" />

        <Validate className="next" answers={requiredAnswers}>

          <Link to="bakgrund#top">
            <button>Fortsätt &#8250;</button>
          </Link>
        </Validate><Previous to="tobak" />

      </div>
    );
  }
}

export default Halsa;
