import Intro from './Intro';
import { Smorgasar, requiredAnswers as SmorgasarRA } from './Smorgasar';
import { GrotFlingorMusli, requiredAnswers as GrotFlingorMusliRA } from './GrotFlingorMusli';
import { NotterFron, requiredAnswers as NotterFronRA } from './NotterFron';
import { Drycker, requiredAnswers as DryckerRA } from './Drycker';
import { GronsakerFrukt, requiredAnswers as GronsakerFruktRA } from './GronsakerFrukt';
import { Kott, requiredAnswers as KottRA } from './Kott';
import { Fisk, requiredAnswers as FiskRA } from './Fisk';
import { Vegetarisk, requiredAnswers as VegetariskRA } from './Vegetarisk';
import { Potatis, requiredAnswers as PotatisRA } from './Potatis';
import { Snabbmat, requiredAnswers as SnabbmatRA } from './Snabbmat';
import { Matfett, requiredAnswers as MatfettRA } from './Matfett';
import { Sas, requiredAnswers as SasRA } from './Sas';
import { Snacks, requiredAnswers as SnacksRA } from './Snacks';
import { Tarmsymptom, requiredAnswers as TarmsymptomRA } from './Tarmsymptom';
import { Matvanor, requiredAnswers as MatvanorRA } from './Matvanor';
import { Fysisk, requiredAnswers as FysiskRA } from './Fysisk';
import { Mediciner, requiredAnswers as MedicinerRA } from './Mediciner';
import { Tobak, requiredAnswers as TobakRA } from './Tobak';
import { Misc, requiredAnswers as MiscRA } from './Misc';
import Slutet from './Slutet';

export const requiredAnswers = [
  ...SmorgasarRA,
  ...GrotFlingorMusliRA,
  ...NotterFronRA,
  ...DryckerRA,
  ...GronsakerFruktRA,
  ...KottRA,
  ...FiskRA,
  ...VegetariskRA,
  ...PotatisRA,
  ...SnabbmatRA,
  ...MatfettRA,
  ...SasRA,
  ...SnacksRA,
  ...TarmsymptomRA,
  ...MatvanorRA,
  ...FysiskRA,
  ...MedicinerRA,
  ...TobakRA,
  ...MiscRA
].map( a => '3.'+ a)

export const numberOfCategories = 19

export { Intro, Smorgasar, GrotFlingorMusli, NotterFron, Drycker,
  GronsakerFrukt, Kott, Fisk, Vegetarisk, Potatis, Snabbmat, Matfett,
  Sas, Snacks, Tarmsymptom, Matvanor, Fysisk, Mediciner, Tobak, Misc, Slutet }
