import React from 'react';
import styled from 'styled-components';
import { FreqInput, Input, Headline, OBS } from '../fields';

const Container = styled.div`
margin: 1em 2em;
padding: 10px 1.5em 1em;
background: #c1fff3;
border: 5px solid #c1fff3;
position: relative;
`

const Overlay = styled.div`
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
z-index: 10;
background-color: transparent;
cursor: default;
`

const Exemplet = (props) => {

  return (
    <Container>
      <Overlay />
      <Headline
          question="Hur ofta äter du gröt?"
          desc="Fyll i antal gånger och klicka endast i ett alternativ (dag, vecka eller månad). Om du aldrig äter
          gröt, klicka i rutan för ”aldrig”."
      />
      <FreqInput title="Någon typ av gröt" _id="1.intro_0" exampleTimes="4" exampleFreq="vecka" />

      <Headline
          question="Tänk dig att du äter gröt <u>tio gånger</u> i följd."
          desc="Hur många gånger brukar gröten vara:"
      />
      <Input ten desc="Havregrynsgröt" _id="1.intro_1" value="6" />
      <Input ten desc="Mannagryns-/risgrynsgröt" _id="1.intro_2" />
      <Input ten desc="Rågmjöls-/rågflingegröt" _id="1.intro_3" value="4" />
      <OBS sumTen="1.0" />
    </Container>
  );

}

export default Exemplet;
