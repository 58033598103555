import React, { Component } from "react";
// eslint-disable-next-line
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

import { Intro, Smorgasar, GrotFlingorMusli, NotterFron, Drycker,
GronsakerFrukt, Kott, Fisk, Vegetarisk, Potatis, Snabbmat, Matfett,
Sas, Snacks, Tarmsymptom, Matvanor, Fysisk, Mediciner, Tobak, Misc,
Slutet, requiredAnswers } from './enkat3';

import Help from './Help';
import Logout from './Logout';

import Panel from '../Panel';
import { isAdmin } from '../DB';

class Enkat3 extends Component {
  render() {
    return (
      <div>
        <Route exact path="/fidura/enkat/3" render={() => (
          <Redirect to="/fidura/enkat/3/intro"/>
        )}/>

        { isAdmin && (
          <Panel answers={requiredAnswers} />
        )}

        <Help />
        <Logout />

        <Route exact path="/fidura/enkat/3/intro" component={Intro} />
        <Route exact path="/fidura/enkat/3/smorgasar" component={Smorgasar} />
        <Route exact path="/fidura/enkat/3/grot-flingor-musli" component={GrotFlingorMusli} />
        <Route exact path="/fidura/enkat/3/notter-fron" component={NotterFron} />
        <Route exact path="/fidura/enkat/3/drycker" component={Drycker} />
        <Route exact path="/fidura/enkat/3/gronsaker-frukt" component={GronsakerFrukt} />
        <Route exact path="/fidura/enkat/3/kott" component={Kott} />
        <Route exact path="/fidura/enkat/3/fisk" component={Fisk} />
        <Route exact path="/fidura/enkat/3/vegetarisk" component={Vegetarisk} />
        <Route exact path="/fidura/enkat/3/potatis" component={Potatis} />
        <Route exact path="/fidura/enkat/3/snabbmat" component={Snabbmat} />
        <Route exact path="/fidura/enkat/3/matfett" component={Matfett} />
        <Route exact path="/fidura/enkat/3/sas" component={Sas} />
        <Route exact path="/fidura/enkat/3/snacks" component={Snacks} />
        <Route exact path="/fidura/enkat/3/tarmsymptom" component={Tarmsymptom} />
        <Route exact path="/fidura/enkat/3/matvanor" component={Matvanor} />
        <Route exact path="/fidura/enkat/3/fysisk" component={Fysisk} />
        <Route exact path="/fidura/enkat/3/mediciner" component={Mediciner} />
        <Route exact path="/fidura/enkat/3/tobak" component={Tobak} />
        <Route exact path="/fidura/enkat/3/misc" component={Misc} />
        <Route exact path="/fidura/enkat/3/slutet" component={Slutet} />
      </div>
    );
  }
}

export default Enkat3;
