import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import styled from 'styled-components';
import { Textarea, Validate, Headline, Radio, Title, Previous } from '../../fields';

export const requiredAnswers = [24, 25, 26, 27, 28]

const Text = styled.p`
font-weight: bold;
font-size: 1.1em;
line-height: 1.3em;
text-align: justify;
`

export class SexuellKrankning extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <div id="top">
        <Title>FRÅGOR OM SEXUELL KRÄNKNING</Title>
        <Text>Det är vanligt med sexuella kränkningar, framför allt mot kvinnor. Undersökningar har visat att minst 15 procent av alla kvinnor har blivit utsatta för någon form av sexuell kränkning eller någon form av sexuell handling mot sin vilja någon gång under sitt liv. Har man varit med om en sexuell kränkning kan både sexuallivet och det totala välbefinnandet bli påverkat. Att då dessutom drabbas av en cancersjukdom med de behandlingar och undersökningar som hör till kan förstås ytterligare förstärka besvären. Vi erbjuder professionellt stöd (kurator, psykolog) vid behov.</Text>

        <Headline
            nr="1"
            anchor="24"
            question="Har du blivit utsatt för <u>någon form</u> av sexuella handlingar mot din vilja?"
        />
        <Radio _id="0.24_1" value="nej" desc="Nej" />
        <Radio _id="0.24_1" value="lite" desc="Ja, lite" />
        <Radio _id="0.24_1" value="mattligt" desc="Ja, måttligt" />
        <Radio _id="0.24_1" value="mycket" desc="Ja, svårt" />

        <Headline
            nr="2"
            anchor="25"
            question="Har du blivit utsatt för sexuella handlingar mot din vilja vid upprepade tillfällen?"
        />
        <Radio _id="0.25_1" value="inteAktuellt" desc="Inte aktuellt" />
        <Radio _id="0.25_1" value="nej" desc="Nej" />
        <Radio _id="0.25_1" value="ja" desc="Ja" />

        <Headline
            nr="3"
            anchor="26"
            question="Vid vilken ålder blev du första gången utsatt för någon sexuell handling mot din vilja?"
        />
        <Radio _id="0.26_1" value="inteAktuellt" desc="Inte aktuellt" />
        <Radio _id="0.26_1" value="arGammal" desc="Jag var" custom short after="år gammal" />

        <Headline
            nr="4"
            anchor="27"
            question="Om du har utsatts för någon sexuell handling mot din vilja, hur påverkar det ditt sexliv?"
        />

        <Radio _id="0.27_1" value="inteAktuellt" desc="Inte aktuellt" />
        <Radio _id="0.27_1" value="inteAlls" desc="Inte alls" />
        <Radio _id="0.27_1" value="lite" desc="Lite" />
        <Radio _id="0.27_1" value="mattligt" desc="Måttligt" />
        <Radio _id="0.27_1" value="mycket" desc="Mycket" />

        <Headline
            anchor="extra_1"
            question="Nedan följer några rader där Du själv kan berätta eller komplettera ovanstående frågor angående kvinnohälsa, sexualitet och sexuell kränkning"
        />
        <Textarea _id="0.extra_1" />

        <Headline
            nr="5"
            anchor="28"
            question="Om du har utsatts för någon sexuell handling mot din vilja, önskar du professionellt stöd (kurator, psykolog)?"
        />
        <Radio _id="0.28_1" value="inteAktuellt" desc="Inte aktuellt" />
        <Radio _id="0.28_1" value="nejInteSamtalsstod" desc="Nej, jag har blivit utsatt men önskar inte samtalsstöd" />
        <Radio _id="0.28_1" value="nejFattSamtalsstod" desc="Nej, jag har blivit utsatt och har fått samtalsstöd" />
        <Radio _id="0.28_1" value="ja" desc="Ja, jag har utsatts och jag önskar bli kontaktad för att boka tid för samtalsstöd " />

        <Validate className="next" answers={requiredAnswers} enkat="0" offset={23}>
          <Link to="allmanna#top">
            <button>Fortsätt &#8250;</button>
          </Link>
        </Validate><Previous to="forecancern" />
      </div>
    );
  }
}

export default requiredAnswers;
