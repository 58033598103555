import React from 'react'
//import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import ECProject from './ECProject';

class EnkatList extends React.Component {
  constructor(props) {
    super(props);

    let path = window.location.pathname

    let admin = /\/admin/g.test(path)

    let enkat = admin ?
      /\/admin\/([0-9])/g.exec(path)[1] :
      /\/enkat\/([0-9])/g.exec(path)[1]

    this.state = {
      admin: admin,
      enkat: enkat,
      redirect: false
    };

    this.changeEnkat = this.changeEnkat.bind(this);
  }

  getEnkat() {
    return this.state.admin ?
      /\/admin\/([0-9])/g.exec(window.location.pathname)[1] :
      /\/enkat\/([0-9])/g.exec(window.location.pathname)[1]
  }

  changeEnkat(event) {
    let enkat = event.target.value

    this.setState({
      enkat: enkat,
      redirect: true
    })
  }

  render() {

    const { admin, redirect, enkat } = this.state

    return(
      <div>
        { admin &&
        redirect &&
        this.getEnkat() !== enkat && (
          <Redirect to={`/${ECProject}/enkat/admin/${enkat}`}/>
        )}

        { !admin &&
        redirect &&
        this.getEnkat() !== enkat && (
          <Redirect to={`/${ECProject}/enkat/admin/${enkat}`}/>
        )}

        <strong>Enkat: &nbsp;</strong>
        { ECProject === "fidura" && (
          <select
            id="currentEnkat"
            onChange={this.changeEnkat}
            value={enkat}
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
          </select>
        )}

        { ECProject === "vista" && (
          <select
            id="currentEnkat"
            onChange={this.changeEnkat}
            value={enkat}
          >
            <option value="0">Basfrågor</option>
            <option value="1">Uppföljning 3 månader</option>
            <option value="2">Uppföljning 6 månader</option>
            <option value="3">Uppföljning 12 månader</option>
          </select>
        )}

      </div>
    )
  }
}

export default EnkatList;
