import React from 'react'
import styled from 'styled-components';

const Image = styled.img`
max-width: 200px;
max-height: 150px;
border: 1px solid black;
display: inline;
vertical-align: middle;
`

const Desc = styled.p`
display: block;
text-align: center;
width: 100%;
font-style: italic;
margin-top: 0.2rem;
font-size: 85%;
`

const Img = (props) => {

  const { src, inline, desc } = props

  const ContainerStyle = {
    display: inline ? 'inline-block': 'block',
    padding: inline ? '0rem 1rem 1rem 0': '0'
  }
  
  return (
    <div style={ContainerStyle}>
      <Image src={src} />
      { desc && (
        <Desc dangerouslySetInnerHTML={{__html: desc }} />
      )}
    </div>
  )
}

export default Img;
