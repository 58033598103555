import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import styled from 'styled-components';

const Row = styled.div`
margin: 2em 0 1em 2em;
`

const Title = styled.span`
font-size: 2em;
font-weight: bold;
`

const SubTitle = styled.span`
font-size: 1.5em;
font-weight: bold;
`

const Text = styled.p`
margin: 1em 0 1em 2em;
`

const Spacer = styled.div`
height: 1.2em;
`

const Intro = (props) => {

  return (
    <>
      <Row>
        <Title>VISTA STUDIEN - </Title>
        <SubTitle>UPPFÖLJNING 3-månader</SubTitle>
      </Row>
      <Text>
        Detta formulär vänder sig till deltagare i studien ”Vaginalstavsanvändning i samband med strålbehandling” (VISTA). Formuläret innehållet allmänna frågor, frågor om sexualitet, hur du har uppfattat användningen av vaginalstav och frågor om att använda digitalt stöd under strålbehandlingen.
      </Text>
      <Spacer />
      <Text>
          Dina svar är av stort värde för att förstå hur vården kan förbättras vad gäller information och uppföljning av vaginala förändringar och påverkan på sexualitet i samband med cancerbehandling. Svara på varje fråga så gott du kan och om du inte hittar något svarsalternativ som exakt passar in på vad du känner, får du välja det som ligger närmast. En del frågor kan upplevas som känsliga, du svarar helt anonymt.
      </Text>

      <Link className="next" to="allmanna#top">
        <button>Börja</button>
      </Link>
    </>
  );
}

export default Intro;
