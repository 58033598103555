import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import styled from 'styled-components';
import { Checkbox, Headline, Validate, Title, VitaminTable, Progress, Previous, Img } from '../../fields';
import { numberOfCategories } from './index';

import vitamin from '../images/vitamin.jpeg';
import fortimel from '../images/fortimel.jpeg';

export const requiredAnswers = [77]

const Spacer = styled.div`
height: 2em;
`

export class Mediciner extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <div id="top">
        <Progress max={numberOfCategories} value={17} />
        <Title>VITAMINER, MINERALER, HÄLSOKOSTPREPARAT OCH NÄRINGSDRYCK</Title>

        <Spacer />
        <Img inline src={vitamin} />
        <Img inline src={fortimel} />
        <Headline
            nr="77"
            question="Skriv in ditt intag av vitaminer, mineraler, hälsopreparat och näringsdryck som du regelbundet tagit de senaste tre månaderna."
            desc="Ange vad du ätit, varumärkesnamn (dvs vad produkten kallas), ungefärlig mängd per vecka och ungefär hur länge du  har använt det."
        />
        <Checkbox _id="1.77_0" desc="Jag har inte ätit några vitaminer, mineraler, hälsopreparat eller näringsdryck de tre senaste månaderna" />
        <VitaminTable _id="1.77_1" examples={[['t ex D-Vitamin', 'Apoteket', '7 tabletter', '2 år' ]]} />

        <Validate className="next" answers={requiredAnswers}>
          <Link to="tobak#top">
            <button>Fortsätt &#8250;</button>
          </Link>
        </Validate><Previous to="fysisk" />

      </div>
    );
  }
}

export default Mediciner;
