import Intro from './Intro';
import { Allmanna, requiredAnswers as AllmannaRA } from './Allmanna';
import { Sexualitet, requiredAnswers as SexualitetRA } from './Sexualitet';
import { DigitalaVerktyg, requiredAnswers as DigitalaVerktygRA } from './DigitalaVerktyg';
import Tack from './Tack';

export const requiredAnswers = [
  ...AllmannaRA,
  ...SexualitetRA,
  ...DigitalaVerktygRA
].map( a => '0.'+ a)

export { Intro, Allmanna, Sexualitet, DigitalaVerktyg, Tack }
