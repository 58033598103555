import React from 'react'
import styled from 'styled-components';
import { connect } from "react-redux";
import { commitAnswer } from "../Actions";

const DefaultProps = {
  year: undefined,
  month: undefined,
  day: undefined
}

const Row = styled.div`
margin-bottom: 1.5em;
`

const Select = styled.select`
margin-right: 0.5em;
`

const mapStateToProps = (state, ownProps) => {
  let stateObj = state.answers.find( answer => answer._id === ownProps._id );

  return {...DefaultProps, ...stateObj}
};

const mapDispatchToProps = dispatch => {
  return {
    commitAnswer: answer => dispatch(commitAnswer(answer))
  };
};

class ConnectedDatePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: props._id,
      year: props.year,
      month: props.month,
      day: props.day,
      days: this.getDays(props.month)
    };

    this.changeYear = this.changeYear.bind(this);
    this.changeMonth = this.changeMonth.bind(this);
    this.changeDay = this.changeDay.bind(this);
  }

  componentDidUpdate(prevProps) {
    Object.keys(DefaultProps).forEach( key => {
      if (this.props[key] !== prevProps[key])
        this.setState({
          [key]: this.props[key],
          days: this.getDays(this.props.month)
        })
    })
  }

  changeYear(event) {
    this.setState({
      year: event.target.value
    }, () => this.saveChanges());
  }

  changeMonth(event) {
    const { day } = this.state
    let month = event.target.value
    let days = this.getDays(month)

    this.setState({
      month: month,
      day: days.includes(day) ? day : '1'
    }, () => {
      this.saveChanges()
    });
  }

  changeDay(event) {
    this.setState({
      day: event.target.value
    }, () => this.saveChanges());
  }

  getDays(month) {
    let minDays = [...Array(28).keys()].map( d => d + 1)
    let days = []

    if (['januari', 'mars', 'maj', 'juli', 'augusti', 'oktober', 'december', undefined].includes(month)) {
      days = [...minDays, 29, 30, 31]
    } else if (month !== 'februari') {
      days = [...minDays, 29, 30]
    } else {
      days = [...minDays]
    }

    return days.map(d => d.toString())
  }

  saveChanges() {
    const _id = this.props._id;
    let { year, month, day } = this.state;
    console.log({ _id, year, month, day })
    this.props.commitAnswer({ _id, year, month, day })
  }

  render() {
    const { year, month, day, days } = this.state
    const { inline } = this.props

    const renderedYears = [...Array(91).keys()].map( i => {
      let y = i + 1930
      return (
        <option value={y} key={y}>{y}</option>
      )
    })

    const renderedMonth = ['Januari', 'Februari', 'Mars', 'April',
      'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober',
      'November', 'December'].map( m => (
        <option value={m.toLowerCase()} key={m}>{m}</option>
    ))

    const renderedDays = days.map( d => (
      <option value={d} key={d}>{d}</option>
    ))

    const RowStyle = {
      display: inline ? 'inline-block': 'block',
    }

    return(
      <Row style={RowStyle}>
        <Select onChange={this.changeYear} value={year}>
          <option disabled>År</option>
          {renderedYears}
        </Select>
        <Select onChange={this.changeMonth} value={month}>
          <option disabled>Månad</option>
          {renderedMonth}
        </Select>
        <Select onChange={this.changeDay} value={day}>
          <option disabled>Dag</option>
          {renderedDays}
        </Select>
      </Row>
    )
  }
}

const DatePicker = connect(mapStateToProps, mapDispatchToProps)(ConnectedDatePicker);

export default DatePicker;
