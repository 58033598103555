import React from 'react'
import styled from 'styled-components';
import { connect } from "react-redux";
import { commitAnswer } from "../Actions";

const DefaultProps = {
  value: '',
  disabled: false,
}

const Container = styled.div`
margin: 0;
padding: 0.5em;
`

const Headline = styled.div`
width: 45%;
display: inline-block;
vertical-align: middle;
`

const Title = styled.div`
margin: 0 1rem;
font-weight: bold;
`
const Desc = styled.div`
margin: 0 1rem;
font-size: 0.8em;
`

const RadioForm = styled.form`
display: inline-block;
width: 55%;
`

const RadioLabel = styled.label`
display: inline-block;
width: 33%;
`

const RadioInput = styled.input`
`

const mapStateToProps = (state, ownProps) => {
  let stateObj = state.answers.find( answer => answer._id === ownProps._id );

  if (ownProps.omJa) {
    // If there is a checked checkbox on top, with ID X_0, disable the field
    let radioID = ownProps._id.replace(/_.+/g, "_1")
    let radio = state.answers.find( answer => answer._id === radioID)
    let ja = radio !== undefined && 'value' in radio && radio.value === 'ja'

    return {...DefaultProps, ...stateObj, disabled: !ja}
  } else {
    return {...DefaultProps, ...stateObj}
  }
};

const mapDispatchToProps = dispatch => {
  return {
    commitAnswer: answer => dispatch(commitAnswer(answer))
  };
};

class ConnectedMerEllerMindre extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: props._id,
      value: props.value,
      disabled: props.disabled,
    };

    this.changeFreq = this.changeFreq.bind(this);
  }

  componentDidUpdate(prevProps) {
    Object.keys(DefaultProps).forEach( key => {
      if (this.props[key] !== prevProps[key]) {
        this.setState({
          [key]: this.props[key]
        })
      }
    })
  }

  changeFreq(event) {
    let value = event.target.value

    this.setState({
      value: value,
    }, () => {
      this.saveChanges()
    });
  }

  saveChanges() {
    const _id = this.props._id;
    let { value } = this.state;
    this.props.commitAnswer({ _id, value })
  }

  render() {
    const { value } = this.state

    const { title, desc, dricker, disabled } = this.props

    const Verb = dricker ? 'Dricker' : 'Äter'

    const RadioFormStyle = {
      color: disabled ? "#aaa" : "inherit"
    }

    return(
      <Container>
        <Headline>
          <Title>{title}</Title>
          { desc && <Desc>{desc}</Desc> }
        </Headline>
        <RadioForm style={RadioFormStyle} >
          <RadioLabel>
            <RadioInput
              type="radio"
              value="mindre"
              checked={value === 'mindre'}
              onChange={this.changeFreq}
              disabled={disabled}
            />
            {Verb} mindre
          </RadioLabel>
          <RadioLabel>
            <RadioInput
              type="radio"
              value="samma"
              checked={value === 'samma'}
              onChange={this.changeFreq}
              disabled={disabled}
            />
            Ingen ändring
          </RadioLabel>
          <RadioLabel>
            <RadioInput
              type="radio"
              value="mer"
              checked={value === 'mer'}
              onChange={this.changeFreq}
              disabled={disabled}
            />
            {Verb} mer
          </RadioLabel>
        </RadioForm>
      </Container>
    )
  }
}

const MerEllerMindre = connect(mapStateToProps, mapDispatchToProps)(ConnectedMerEllerMindre);

export default MerEllerMindre;
