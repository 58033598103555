import React from 'react'
import styled from 'styled-components';

const LogoutButton = styled.a`
position: fixed;
z-index: 1000;
top: 22px;
right: 22px;
text-align: center;
color: blue;
text-decoration: underline;

&:active, &:hover {
  color:purple;
}

`;

const Logout = (props) => {

  return (
    <LogoutButton href="/fidura/wp-login.php?action=logout">
      Logga ut
    </LogoutButton>
  )
}

export default Logout;
