import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Textarea, Validate, Headline, Radio, Title, Previous } from '../../fields';

export const requiredAnswers = [7, 8, 9, 10, 11, 12, 13, 14, 15, 16]

export class Sexualitet extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <div id="top">
        <Title>SEXUALITET</Title>

        <Headline
            nr="1"
            anchor="7"
            question="Kvinnor som får strålbehandling mot bäckenområdet kommer vanligtvis in i menopaus (slutar menstruera regelbundet, kommer in i ett klimakterium) under behandlingstiden. Har du noterat att du kommit in i menopaus under eller efter strålbehandlingstiden?"
        />
        <Radio _id="1.7_1" value="inteAktuellt" desc="<b>Inte aktuellt</b>, jag hade kommit in i menopaus före strålbehandlingen" />
        <Radio _id="1.7_1" value="nej" desc="Nej, jag har fortfarande regelbunden menstruation" />
        <Radio _id="1.7_1" value="ja" desc="Ja, jag har kommit in i menopaus under strålbehandlingen" />

        <Headline
            nr="2"
            anchor="8"
            question="Har du använt någon lokal östrogenkräm eller östrogengel (t ex Ovesterin, Blissel, Estrokad, Vagifem) i underlivet regelbundet under strålbehandlingstiden?"
        />
        <Radio _id="1.8_1" value="nej" desc="Nej" />
        <Radio _id="1.8_1" value="ja" desc="Ja" />

        <Headline
            nr="3"
            anchor="9"
            question="Har du använt någon hormonfri fuktgivande gel i underlivet regelbundet under strålbehandlingen (t ex Repadina, Replens, Vagisan)?"
        />
        <Radio _id="1.9_1" value="nej" desc="Nej" />
        <Radio _id="1.9_1" value="ja" desc="Ja" />
          
        <Headline
            nr="4"
            anchor="10"
            question="Om du har använt vaginalstav, har du upplevt obehag i samband med det?"
        />
        <Radio _id="1.10_1" value="inteAktuellt" desc="<b>Inte aktuellt</b>, jag har inte använt vaginalstav" />
        <Radio _id="1.10_1" value="nej" desc="Nej" />
        <Radio _id="1.10_1" value="lite" desc="Ja, lite" />
        <Radio _id="1.10_1" value="mattligt" desc="Ja, måttligt" />
        <Radio _id="1.10_1" value="mycket" desc="Ja, mycket" />

        <Headline
            nr="5"
            anchor="11"
            question="Hos vissa kan slidan i samband med eller efter strålbehandlingen kännas förkortad vid användning av vaginalstav, är det något som du har noterat?"
        />
        <Radio _id="1.11_1" value="inteAktuellt" desc="<b>Inte aktuellt</b>, jag har inte använt vaginalstav" />
        <Radio _id="1.11_1" value="nej" desc="Nej" />
        <Radio _id="1.11_1" value="lite" desc="Ja, lite" />
        <Radio _id="1.11_1" value="mattligt" desc="Ja, måttligt" />
        <Radio _id="1.11_1" value="mycket" desc="Ja, mycket" />

        <Headline
            nr="6"
            anchor="12"
            question="Hos vissa kan slidan i samband med eller efter strålbehandling kännas oelastisk vid användning av vaginalstav, är det något som du har noterat?"
        />
        <Radio _id="1.12_1" value="inteAktuellt" desc="<b>Inte aktuellt</b>, jag har inte använt vaginalstav" />
        <Radio _id="1.12_1" value="nej" desc="Nej" />
        <Radio _id="1.12_1" value="lite" desc="Ja, lite" />
        <Radio _id="1.12_1" value="mattligt" desc="Ja, måttligt" />
        <Radio _id="1.12_1" value="mycket" desc="Ja, mycket" />

        <Headline
            nr="7"
            anchor="13"
            question="När du använder vaginalstav idag, vilken storlek är då möjlig att använda?"
        />
        <Radio _id="1.13_1" value="inteAktuellt" desc="<b>Inte aktuellt</b>, jag har inte använt vaginalstav" />
        <Radio _id="1.13_1" value="inteMojligt" desc="Det är inte möjligt att föra in någon vaginalstav" />
        <Radio _id="1.13_1" value="mindre" desc="Den <u>mindre</u> staven" />
        <Radio _id="1.13_1" value="storre" desc="Den <u>större</u> staven" />

        <Headline
            nr="8"
            anchor="14"
            question="När du använder vaginalstav idag, vilken nummer-markering kommer du då närmast, när du håller staven införd och håller fingret invid slidmynningen?"
        />
        <Radio _id="1.14_1" value="inteAktuellt" desc="<b>Inte aktuellt</b>, jag har inte använt vaginalstav" />
        <Radio _id="1.14_1" value="inteMojligt" desc="Det är inte möjligt att föra in någon vaginalstav" />
        <Radio _id="1.14_1" value="1" desc="1" />
        <Radio _id="1.14_1" value="2" desc="2" />
        <Radio _id="1.14_1" value="3" desc="3" />
        <Radio _id="1.14_1" value="4" desc="4" />

        <Headline
            nr="9"
            anchor="15"
            question="Hur viktigt är det för dig att bibehålla eller återfå vaginal längd och elasticitet?"
        />
        <Radio _id="1.15_1" value="inteAlls" desc="Inte alls viktigt" />
        <Radio _id="1.15_1" value="lite" desc="Lite viktigt" />
        <Radio _id="1.15_1" value="mattligt" desc="Måttligt viktigt" />
        <Radio _id="1.15_1" value="mycket" desc="Mycket viktigt" />

        <Headline
            nr="10"
            anchor="16"
            question="Hur viktigt är det för dig att bibehålla eller återfå så normal sexuell funktion som möjligt efter din cancerbehandling?"
        />
        <Radio _id="1.16_1" value="inteAlls" desc="Inte alls viktigt" />
        <Radio _id="1.16_1" value="lite" desc="Lite viktigt" />
        <Radio _id="1.16_1" value="mattligt" desc="Måttligt viktigt" />
        <Radio _id="1.16_1" value="mycket" desc="Mycket viktigt" />

        <Headline
            anchor="extra_1"
            question="Här kan du skriva en fri kommentar om dina upplevelser av att använda vaginalstav:"
        />
        <Textarea id="1.extra_1" />

        <Validate className="next" answers={requiredAnswers} enkat="0" offset={6}>
          <Link to="digitalaverktyg#top">
            <button>Fortsätt &#8250;</button>
          </Link>
        </Validate><Previous to="allmanna" />
      </div>
    );
  }
}

export default requiredAnswers;
