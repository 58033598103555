import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import styled from 'styled-components';

const Row = styled.div`
margin: 2em 0 1em 2em;
`

const Title = styled.span`
font-size: 2em;
font-weight: bold;
`

const SubTitle = styled.span`
font-size: 1.5em;
font-weight: bold;
`

const Text = styled.p`
margin: 1em 0 1em 2em;
`

const Spacer = styled.div`
height: 1.2em;
`

const Intro = (props) => {

  return (
    <>
      <Row>
        <Title>VISTA STUDIEN - </Title>
        <SubTitle>Basfrågor</SubTitle>
      </Row>
      <Text>
        Detta formulär vänder sig till deltagare i studien ”Vaginalstavsanvändning i samband med strålbehandling” (VISTA).
      </Text>
      <Spacer />
      <Text>
        Syftet med studien är att kvinnor ska återfå sin sexuella hälsa efter strålbehandling, att utveckla vården vad gäller att förebygga vaginala förändringar och begränsa behandlingens påverkan på sexuell hälsa.
      </Text>
      <Text>
        Vi vill undersöka vid vilken tidpunkt som det är mest fördelaktigt att börja med vaginalstavsanvändning. Du har blivit lottad till Grupp 1. Det innebär att du kommer att påbörja användning av vaginalstav tidigt, det vill säga före start av din strålbehandling.
      </Text>
      <Spacer />
      <Text>
        Formuläret innehåller frågor om kvinnohälsa, sexuell hälsa, förlossning, menstruation och allmänna frågor. Med hjälp av dina svar kan vi i sjukvården få ökad kunskap och förbättra vårdens sätt att informera om och behandla vaginala förändringar samt påverkan på sexualitet i samband med cancerbehandling. 
      </Text>
      <Text>
        Svara på varje fråga så gott du kan och om du inte hittar något svarsalternativ som exakt passar in på vad du känner, får du välja det som ligger närmast. En del frågor kan upplevas som känsliga, du svarar helt anonymt.
      </Text>

      <Link className="next" to="kvinnohalsa#top">
        <button>Börja</button>
      </Link>
    </>
  );
}

export default Intro;
