import React, { Component } from "react";
import { BrowserRouter as Router, Route } from 'react-router-dom';
import styled from 'styled-components';
import { createGlobalStyle } from "styled-components"

import ECProject from './ECProject';
import { isAdmin } from './DB';
import Home from "./Home";
import Admin from "./Admin";
import FEnkat1 from "./fidura/Enkat1";
import FEnkat2 from "./fidura/Enkat2";
import FEnkat3 from "./fidura/Enkat3";
//TODO: Import vista surveys
import VEnkat0 from "./vista/Enkat0";
import VEnkat1 from "./vista/Enkat1";

console.log(`Running ${ECProject.toUpperCase()} surveys`)

const isEdge = window.navigator.userAgent.indexOf('Edge') != -1
const isIE = window.navigator.userAgent.indexOf('Trident') != -1 && !isEdge

const Container = styled.div`
  margin: 0 auto;
  max-width: 920px;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`

const ObsoleteWarning = styled.div`
  position: fixed;
  top: 2em;
  padding: 1em 2em;
  text-align: center;
  color: #8b0000;
  background-color: white;
  border: 2px solid #8b0000;
`

const GlobalStyle = createGlobalStyle`
  .next {
    display: block;
    width: 100%;
    text-align: center;
    margin: 4em 0 2em 0;
    cursor: default;
  }

  .admin .next {
    display: none;
  }

  .next button {
    font-size: 1.5em;
    font-weight: bold;
    color: #20bf6b !important;
    text-transform: uppercase;
    background: #ffffff;
    padding: 20px;
    border: 4px solid #20bf6b !important;
    border-radius: 6px;
    display: inline-block;
    cursor: pointer;
  }

  .next button:hover {
    color: #494949 !important;
    border-color: #494949 !important;
    transition: all 0.3s ease 0s;
  }

  .previous {
    display: block;
    width: 100%;
    text-align: center;
    margin: 4em 0 2em 0;
    cursor: default;
  }

  .admin .previous {
    display: none;
  }

  .previous button {
    font-size: 1em;
    font-weight: bold;
    color: #999 !important;
    text-transform: uppercase;
    background: #ffffff;
    padding: 16px;
    border: 3px solid #999 !important;
    border-radius: 6px;
    display: inline-block;
    cursor: pointer;
  }

  .previous button:hover {
    color: #494949 !important;
    border-color: #494949 !important;
    transition: all 0.3s ease 0s;
  }
`

class App extends Component {
  render() {
    return (
      <Router>
        <Container>
          { isIE && <ObsoleteWarning>
            Din webbläsare behöver uppdateras för att enkäten ska sparas korrekt. Var vänlig uppdatera den eller ladda ner <a href="https://www.mozilla.org/sv-SE/firefox/new/">Firefox</a>
          </ObsoleteWarning> }
          { ECProject === 'fidura' && (
            <main>
              { isAdmin && (
                <Route path="/fidura/enkat/admin" component={Admin} />
              ) }

              <Route exact path="/fidura/enkat/" component={Home} />

              <Route path="/fidura/enkat/1" component={FEnkat1} />
              <Route path="/fidura/enkat/2" component={FEnkat2} />
              <Route path="/fidura/enkat/3" component={FEnkat3} />
            </main>
          )}

          { ECProject === 'vista' && (
            <main>
              { isAdmin && (
                <Route path="/vista/enkat/admin" component={Admin} />
              ) }

              <Route exact path="/vista/enkat/" component={Home} />

              <Route path="/vista/enkat/0" component={VEnkat0} />
              <Route path="/vista/enkat/1" component={VEnkat1} />
            </main>
          )}
          <GlobalStyle />
        </Container>
      </Router>
    );
  }
}

export default App;
