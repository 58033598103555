import React, { Component } from 'react';
import styled from 'styled-components';
import { Previous } from '../../fields';

const Text = styled.p`
margin: 1em 0 1em 2em;
font-size: 1.5em;
`

const Spacer = styled.div`
height: 2em;
`

export class Tack extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <div>
        <Spacer />
        <Text><strong>Tack för din medverkan i studien! Du kan stänga denna enkät nu.</strong></Text>

        <br />
        <Previous to="digitalaverktyg" />
      </div>
    );
  }
}

export default Tack;
