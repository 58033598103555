import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Headline, Validate, Radio, Title, Progress, Previous } from '../../fields';
import { numberOfCategories } from './index';

export const requiredAnswers = [78, 79]

export class Tobak extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <div id="top">
        <Progress max={numberOfCategories} value={18} />
        <Title>TOBAK</Title>
        <Headline
          nr="78"
          question="Röker du cigaretter regelbundet?"
        />
        <Radio _id="1.78_1" value="nej" desc="Nej, jag har aldrig rökt" />
        <Radio _id="1.78_1" value="ja" desc="Ja, jag röker" />
        <Radio _id="1.78_1" value="harSlutat"
          desc="Jag har rökt tidigare, men slutat vid"
          after="års ålder"
          custom short />

        <Headline
          nr="79"
          question="Snusar du regelbundet?"
        />
        <Radio _id="1.79_1" value="nej" desc="Nej, jag har aldrig snusat" />
        <Radio _id="1.79_1" value="ja" desc="Ja, jag snusar" />
        <Radio _id="1.79_1" value="harSlutat"
          desc="Jag har snusat tidigare, men slutat vid"
          after="års ålder"
          custom short />

        <Validate className="next" answers={requiredAnswers}>
          <Link to="halsa#top">
            <button>Fortsätt &#8250;</button>
          </Link>
        </Validate><Previous to="mediciner" />

      </div>
    );
  }
}

export default Tobak;
