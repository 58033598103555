import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import styled from 'styled-components';
import { Input, Validate, Title, Progress, Previous } from '../../fields';
import { numberOfCategories } from './index';
import { validateSurvey } from '../../DB';

export const requiredAnswers = [84]

const Spacer = styled.div`
height: 2em;
`

export class Misc extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <div id="top">
        <Progress max={numberOfCategories} value={19} />
        <Title>VIKT</Title>

        <Spacer />
        <Input _id="3.84_1" headline="84. Vad väger du nu?" large line desc="kg" int min="30" max="500" />

        <Spacer />
        <p><strong>Du får gärna gå igenom formuläret igen och kontrollera att du besvarat alla frågorna korrekt.</strong></p>

        <Validate className="next" answers={requiredAnswers} enkat="3">
          <Link to="slutet#top">
            <button onClick={ () => validateSurvey('enkat3') }>Skicka &#8250;</button>
          </Link>
        </Validate><Previous to="tobak" />

      </div>
    );
  }
}

export default Misc;
