import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Checkbox, Headline, Validate, Title, VitaminTable, Progress, Previous, Img } from '../../fields';
import { numberOfCategories } from './index';

import vitaminer from '../images/vitaminer.png';
import naringsdryck from '../images/naringsdryck.png';

export const requiredAnswers = [42]

export class Mediciner extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <div id="top">
        <Progress max={numberOfCategories} value={5} />
        <Title>VITAMINER, MINERALER, HÄLSOKOSTPREPARAT OCH NÄRINGSDRYCK</Title>
        <Headline
            nr="42"
            question="Fyll i ditt intag av vitaminer, mineraler, hälsopreparat och näringsdryck som du regelbundet tagit <u>under din strålbehandling</u>."
            desc="Ange vad du ätit, varumärkesnamn (dvs vad produkten kallas), ungefärlig mängd per vecka och ungefär hur länge du  har använt det."
        />
        <Img inline src={vitaminer} />
        <Img inline src={naringsdryck} />

        <Checkbox _id="2.42_0" desc="Jag har inte ätit några vitaminer, mineraler, hälsopreparat eller näringsdryck under min strålbehandling." />
        <VitaminTable _id="2.42_1" examples={[['t ex D-Vitamin', 'Apoteket', '7 tabletter', '2 år' ]]} />

        <Validate className="next" answers={requiredAnswers} enkat="2">
          <Link to="halsa#top">
            <button>Fortsätt &#8250;</button>
          </Link>
        </Validate><Previous to="fysisk" />

      </div>
    );
  }
}

export default Mediciner;
