import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Headline, Validate, Radio, Title, CategoryTitle, Textarea, Progress, Previous } from '../../fields';
import { numberOfCategories } from './index';

export const requiredAnswers = [31, 32, 33, 34, '35_1', '35_2']

export class Matvanor extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <div id="top">
        <Progress max={numberOfCategories} value={3} />
        <CategoryTitle>C. ALLMÄNNA FRÅGOR</CategoryTitle>
        <Title>MATVANOR</Title>

        <Headline
            nr="31"
            question="Har du upplevt att något slags mat eller livsmedel har <u>förvärrat</u> tidigare nämnda tarmsymtom (fråga 6-30)?"
        />
        <Radio _id="2.31_1" value="nej" desc="Nej" />
        <Radio _id="2.31_1" value="ja" desc="Ja" />
        <Radio _id="2.31_1" value="inteAktuellt" desc="Inte aktuellt, jag har inga tarmsymptom" />

        <Textarea
          _id="2.31_2"
          desc="<u>Om ja</u>, vilket slags mat eller livsmedel har förvärrat dina tarmsymtom?"
        />
        <Textarea
          _id="2.31_3"
          desc="<u>Om ja</u>, hur har tarmsymtomen förvärrats?"
        />

        <Headline
            nr="32"
            question="Har du upplevt att något slags mat eller livsmedel har <u>förbättrat</u> tidigare nämnda tarmsymtom (fråga 6-30)?"
        />
        <Radio _id="2.32_1" value="nej" desc="Nej" />
        <Radio _id="2.32_1" value="ja" desc="Ja" />
        <Radio _id="2.32_1" value="inteAktuellt" desc="Inte aktuellt, jag har inga tarmsymptom" />

        <Textarea
          _id="2.32_2"
          desc="<u>Om ja</u>, vilket slags mat eller livsmedel har förbättrat dina tarmsymtom?"
        />
        <Textarea
          _id="2.32_3"
          desc="<u>Om ja</u>, hur har tarmsymtomen förbättrats?"
        />

        <Headline
            nr="33"
            question="Finns det maträtter eller livsmedel som du undviker till följd av din strålbehandling av bäckenområdet."
        />
        <Radio _id="2.33_1" value="nej" desc="Nej" />
        <Radio _id="2.33_1" value="ja" desc="Ja" />

        <Textarea
          _id="2.33_2"
          desc="<u>Om ja</u>, vad för maträtter eller livsmedel undviker du?"
        />
        <Textarea
          _id="2.33_3"
          desc="<u>Om ja</u>, av vilken anledning undviker du nämnda maträtter eller livsmedel?"
        />

        <Headline
          nr="34"
          question="Under tiden du varit med i studien har du fått kapslar som vi föreslagit att du ska äta. Hur är din uppfattning om innehållet av kostfibrer i kapslarna?"
        />
        <Radio _id="2.34_1" value="ingenUppfattning" desc="Jag har ingen uppfattning om innehållet av kostfibrer i kapslarna jag har ätit" />
        <Radio _id="2.34_1" value="trorHog" desc="Jag tror att kapslarna innehöll kostfibrer" />
        <Radio _id="2.34_1" value="trorLag" desc="Jag tror att kapslarna <u>inte</u> innehöll kostfibrer" />
        <Radio _id="2.34_1" value="inteAtit" desc="Jag har inte ätit kapslarna jag fick i studien" />

        <Headline
            nr="35"
            question="Om du skulle vilja få i dig kostfibrer, vilket sätt skulle du då helst välja?"
        />
        <Radio _id="2.35_1" value="maten" desc="Genom den vanliga maten" />
        <Radio _id="2.35_1" value="kosttillskott" desc="Genom ett kosttillskott (till exempel pulver, kapslar)" />
        <Radio _id="2.35_1" value="lakemedel" desc="Genom ett läkemedel" />

        <Textarea
          _id="2.35_2"
          desc="Motivera ditt svar:"
        />

        <Validate className="next" answers={requiredAnswers} enkat="2">
          <Link to="fysisk#top">
            <button>Fortsätt &#8250;</button>
          </Link>
        </Validate><Previous to="tarmsymptom" />

      </div>
    );
  }
}

export default Matvanor;
