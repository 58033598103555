import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Headline, Validate, Radio, Title, CategoryTitle, Textarea, Progress, Previous } from '../../fields';
import { numberOfCategories } from './index';

export const requiredAnswers = [70, 71]

export class Matvanor extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <div id="top">
        <Progress max={numberOfCategories} value={15} />
        <CategoryTitle>C. ALLMÄNNA FRÅGOR</CategoryTitle>
        <Title>MATVANOR</Title>
        <Headline
            nr="70"
            question="Har du upplevt att något slags mat eller livsmedel har <u>förvärrat</u> tidigare nämnda tarmsymtom (fråga 45-69)?"
        />
        <Radio _id="1.70_1" value="nej" desc="Nej" />
        <Radio _id="1.70_1" value="ja" desc="Ja" />
        <Radio _id="1.70_1" value="inteAktuellt" desc="Inte aktuellt, jag har inga tarmsymptom" />

        <Textarea
          _id="1.70_2"
          desc="<u>Om ja</u>, vilket slags mat eller livsmedel har förvärrat dina tarmsymtom?"
        />
        <Textarea
          _id="1.70_3"
          desc="<u>Om ja</u>, hur har tarmsymtomen förvärrats?"
        />

        <Headline
            nr="71"
            question="Har du upplevt att något slags mat eller livsmedel har <u>förbättrat</u> tidigare nämnda tarmsymtom (fråga 45-69)?"
        />
        <Radio _id="1.71_1" value="nej" desc="Nej" />
        <Radio _id="1.71_1" value="ja" desc="Ja" />
        <Radio _id="1.71_1" value="inteAktuellt" desc="Inte aktuellt, jag har inga tarmsymptom" />

        <Textarea
          _id="1.71_2"
          desc="<u>Om ja</u>, vilket slags mat eller livsmedel har förbättrat dina tarmsymtom?"
        />
        <Textarea
          _id="1.71_3"
          desc="<u>Om ja</u>, hur har tarmsymtomen förbättrats?"
        />

        <Validate className="next" answers={requiredAnswers}>
          <Link to="fysisk#top">
            <button>Fortsätt &#8250;</button>
          </Link>
        </Validate><Previous to="tarmsymptom" />

      </div>
    );
  }
}

export default Matvanor;
