import React, { Component } from "react";

import { Intro, Allmanna, Sexualitet, DigitalaVerktyg,
  requiredAnswers } from '.././enkat1';

import Panel from '../../Panel';

class Enkat1 extends Component {
  render() {
    return (
      <>
        <Panel admin answers={requiredAnswers} />

        <Intro />
        <Allmanna />
        <Sexualitet />
        <DigitalaVerktyg />
      </>
    );
  }
}

export default Enkat1;
