import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Headline, Validate, Radio, Title, Previous, Progress } from '../../fields';
import { numberOfCategories } from './index';

export const requiredAnswers = [47, 48]

export class Tobak extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <div id="top">
        <Progress max={numberOfCategories} value={8} />
        <Title>TOBAK</Title>
        <Headline
          nr="47"
          question="Har du rökt cigaretter regelbundet under din strålbehandling?"
        />
        <Radio _id="2.47_1" value="nej" desc="Nej" />
        <Radio _id="2.47_1" value="ja" desc="Ja" />

        <Headline
          nr="48"
          question="Har du snusat regelbundet under din strålbehandling?"
        />
        <Radio _id="2.48_1" value="nej" desc="Nej" />
        <Radio _id="2.48_1" value="ja" desc="Ja" />

        <Validate className="next" answers={requiredAnswers} enkat="2">
          <Link to="misc#top">
            <button>Fortsätt &#8250;</button>
          </Link>
        </Validate><Previous to="stralbehandling" />

      </div>
    );
  }
}

export default Tobak;
