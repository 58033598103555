import React from 'react'
import styled from 'styled-components';
import { connect } from "react-redux";
import { commitAnswer } from "../Actions";

const DefaultProps = {
  text: '',
  sum: undefined,
  disabled: false,
  max: undefined,
  min: undefined
}

const Row = styled.div`
margin-bottom: 0.5em;
margin-left: 1.5em;
`

const Description = styled.span`
margin: 0;
`

const Span = styled.span`
margin: 0;
padding-left: 1em;
`

const Input = styled.input`
display: inline;
width: 3em;
text-align: right;
margin: 0 1em 0 0.5em;
border-style: solid;
padding: 0.2em;
`

const Headline = styled.span`
margin: 0;
margin-left: -1em;
padding: 0 0em;
font-size: 110%;
font-weight: bold;
`

const ExImg = styled.img`
max-width: 200px;
max-height: 150px;
border: 1px solid black;
display: inline;
vertical-align: middle;
margin: 1em;
`

const mapStateToProps = (state, ownProps) => {
  let stateObj = state.answers.find( answer => answer._id === ownProps._id );

  // If there is a checked checkbox on top, with ID X_0, disable the field
  let checkboxID = ownProps._id.replace(/_.+/g, "_0")
  let checkbox = state.answers.find( answer => answer._id === checkboxID)
  let checked = checkbox !== undefined && 'checked' in checkbox && checkbox.checked
  let minMax = { min: ownProps.min, max: ownProps.max }

  return {...DefaultProps, ...stateObj, ...minMax, disabled: checked}
};

const mapDispatchToProps = dispatch => {
  return {
    commitAnswer: answer => dispatch(commitAnswer(answer))
  };
};

class ConnectedSimpleInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: props._id,
      text: props.text,
      sum: props.sum,
      max: props.max,
      min: props.min,
      disabled: props.disabled,
      warning: false
    };

    this.changeText = this.changeText.bind(this);
  }

  componentDidUpdate(prevProps) {
    Object.keys(DefaultProps).forEach( key => {
      if (this.props[key] !== prevProps[key])
        this.setState({ [key]: this.props[key] },
          () => this.checkAnswer())
    })
  }

  checkAnswer() {
    const { disabled, text } = this.state
    var { int, max, min, ten, float } = this.props

    if (ten) {
      int = true
      max = 10
    }

    if (int) {
      this.setState({
        warning: !(ten && text === '') &&
        ((!disabled && !/^(-|\+)?(\d+)$/.test(text)) ||
        (max && parseInt(text) > parseInt(max)) ||
        (min && parseInt(text) < parseInt(min)))
      });
    }

    if (float) {
      this.setState({
        warning: !(ten && text === '') &&
        ((!disabled && !/^[+-]?\d+([.,])?(\d+)?$/.test(text)) ||
        (max && parseFloat(text) > parseFloat(max)) ||
        (min && parseFloat(text) < parseFloat(min)))
      });
    }
  }

  changeText(event) {
    this.setState({
      text: event.target.value,
      sum: this.props.ten
    }, () => {
      this.checkAnswer()
      this.saveChanges()
    });
  }

  saveChanges() {
    const _id = this.props._id;
    let { text, sum, min, max } = this.state;
    this.props.commitAnswer({ _id, text, sum, min, max })
  }

  render() {

    const { left, large, line, headline, beforeText, exImg, exImg2, exImg3, exImg4,
      desc, text, disabled, value } = this.props

    const { warning } = this.state

    const InputStyle = {
      width: large ? '6em': '3em',
      fontSize: large ? '1.1em': 'inherit',
      marginBottom: large ? '1.5em': 'inherit',
      textAlign: left ? 'left': 'right',
      borderWidth: line ? '0 0 1px 0' : '1px',
      borderBottomColor: line ? 'black' : 'inherit',
      color: warning ? 'red': 'inherit'
    }

    const DescriptionStyle = {
      color: warning ? 'red': 'inherit'
    }

    return(
      <Row>
        { headline && (
          <Headline>{headline}</Headline>
        )}
        { beforeText && (
          <Span>{beforeText}</Span>
        )}
        <Input
          style={InputStyle}
          type="text"
          value={value ? value : text}
          onChange={this.changeText}
          onBlur={this.changeText}
          disabled={disabled}
        />
        { desc && (
          <Description style={DescriptionStyle}>{desc}</Description>
        )}
        { exImg && (
          <ExImg src={exImg} />
        )}
        { exImg2 && (
          <ExImg src={exImg2} />
        )}
        { exImg3 && (
          <ExImg src={exImg3} />
        )}
        { exImg4 && (
          <ExImg src={exImg4} />
        )}
      </Row>
    )
  }
}

const SimpleInput = connect(mapStateToProps, mapDispatchToProps)(ConnectedSimpleInput);

export default SimpleInput;
