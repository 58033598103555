import React from 'react';
import styled from 'styled-components';
import { FreqInput } from '../fields';

const Container = styled.div`
margin: 1em 2em 3em;
padding: 10px 1.5em 1em;
background: #c1fff3;
position: relative;
`

const Overlay = styled.div`
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
z-index: 10;
background-color: transparent;
cursor: default;
`

const Example = styled.div`
text-align: center;
font-style: italic;
padding: 1em 0;
`

const Border = styled.hr`
border-style: solid;
border-width: 1px;
border-color: black;
border-bottom: none;
`

const Exemplet = (props) => {

  return (
    <Container>
      <Overlay />
      <Example>
        <strong>Exempel:</strong> Om du drycker 1 glas (2 dl) lättmjölk per dag och 3 glas (6 dl) mellanmjölk per vecka skriv så här:
      </Example>
      <Border />
      <FreqInput titleWidth="200px" title="Lätt/minimjölk" _id="1.dryck_0" exampleTimes="1" exampleFreq="dag" />
      <Border />
      <FreqInput titleWidth="200px" title="Mellanmjölk" _id="1.dryck_1" exampleTimes="3" exampleFreq="vecka" />
      <Border />
    </Container>
  );

}

export default Exemplet;
