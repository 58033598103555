import styled from 'styled-components';

const Title = styled.div`
margin: 1.5em 0em 0em;
padding: 1em 1em;
font-size: 2em;
text-transform: uppercase;
color: white;
background: #67c9d2;
border-bottom: 5px solid #95dae0;
`

export default Title;
