import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Textarea, Validate, Headline, Radio, Title, Previous } from '../../fields';
import { validateSurvey } from '../../DB';

export const requiredAnswers = [17, 18, 19, 20]

export class DigitalaVerktyg extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <div id="top">
        <Title>DIGITALA VERKTYG</Title>

        <Headline
            nr="1"
            anchor="17"
            question="Har du använt mobil-appen med dagligt formulär (VISTA-appen) i samband med din medverkan i studien?"
        />
        <Radio _id="1.17_1" value="nej" desc="Nej" />
        <Radio _id="1.17_1" value="ja" desc="Ja" />

        <Headline
            nr="2"
            anchor="18"
            question="Om du har använt mobil-appen VISTA i samband med din medverkan i studien, har stödet varit till hjälp för dig?"
        />
        <Radio _id="1.18_1" nejToggle="1.17_1" value="inteAktuellt" desc="Inte aktuellt, jag har inte använt appen" />
        <Radio _id="1.18_1" value="inteAlls" desc="Nej, inte alls" />
        <Radio _id="1.18_1" value="lite" desc="Ja, lite" />
        <Radio _id="1.18_1" value="mattligt" desc="Ja, måttligt" />
        <Radio _id="1.18_1" value="mycket" desc="Ja, mycket" />

        <Headline
            anchor="extra_2"
            question="Här kan du skriva en fri kommentar om din uppfattning om att använda mobil-appen VISTA:"
        />
        <Textarea id="1.extra_2" />

        <Headline
            nr="3"
            anchor="19"
            question="Har du besökt webb-sidan eftercancern.se i samband med din medverkan i studien?"
        />
        <Radio _id="1.19_1" value="nej" desc="Nej" />
        <Radio _id="1.19_1" value="ja" desc="Ja" />

        <Headline
            nr="2"
            anchor="20"
            question="Om du har besökt webb-sidan eftercancern.se i samband med din medverkan i studien, har informationen varit till hjälp för dig?"
        />
        <Radio _id="1.20_1" nejToggle="1.19_1" value="inteAktuellt" desc="Inte aktuellt, jag har inte besökt webb-sidan Efter Cancern (www.eftercancern.se)" />
        <Radio _id="1.20_1" value="inteAlls" desc="Nej, inte alls" />
        <Radio _id="1.20_1" value="lite" desc="Ja, lite" />
        <Radio _id="1.20_1" value="mattligt" desc="Ja, måttligt" />
        <Radio _id="1.20_1" value="mycket" desc="Ja, mycket" />

        <Headline
            anchor="extra_3"
            question="Här kan du skriva en fri kommentar om din uppfattning om att besöka webb-sidan EfterCancern (www.eftercancern.se):"
        />
        <Textarea id="1.extra_3" />

        <Validate className="next" answers={requiredAnswers} enkat="1" offset={16}>
          <Link to="tack#top">
            <button onClick={ () => validateSurvey('enkat1') }>Skicka &#8250;</button>
          </Link>
        </Validate><Previous to="sexualitet" />
      </div>
    );
  }
}

export default requiredAnswers;
