import React, { Component } from "react";
// eslint-disable-next-line
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

import { Intro, Allmanna, Sexualitet, DigitalaVerktyg, Tack,
  requiredAnswers } from './enkat1';

import Help from './Help';

import Panel from '../Panel';
import { isAdmin } from '../DB';

class Enkat1 extends Component {
  render() {
    return (
      <div>
        <Route exact path="/vista/enkat/1" render={() => (
          <Redirect to="/vista/enkat/1/intro"/>
        )}/>

        { isAdmin && (
          <Panel answers={requiredAnswers} />
        )}

        <Help />

        <Route exact path="/vista/enkat/1/intro" component={Intro} />
        <Route exact path="/vista/enkat/1/allmanna" component={Allmanna} />
        <Route exact path="/vista/enkat/1/sexualitet" component={Sexualitet} />
        <Route exact path="/vista/enkat/1/digitalaverktyg" component={DigitalaVerktyg} />
        <Route exact path="/vista/enkat/1/tack" component={Tack} />
      </div>
    );
  }
}

export default Enkat1;
