import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { FreqInput, Headline, Validate, Title, Progress, Previous } from '../../fields';
import { numberOfCategories } from './index';
import styled from 'styled-components';

export const requiredAnswers = ['44_1', '44_2', '44_3', '44_4', '44_5', '44_6', '44_7', '44_8']

const GreyRow = styled.div`
border-top: 1px solid black;
border-bottom: 1px solid black;
background-color: rgb(238, 238, 238);
padding-top: 0.3em;
margin-bottom: 0.3em;
`

export class Snacks extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <div id="top">
        <Progress max={numberOfCategories} value={13} />
        <Title>SÖTSAKER, SNACKS OCH DESSERTOST</Title>
        <Headline
            nr="44"
            question="Hur ofta äter du sötsaker, snacks och dessertost?"
            desc="Fyll i antal gånger och klicka endast i ett alternativ (dag, vecka eller månad) per typ av livsmedel du använder. Om det är någon typ av godis, kakor eller snacks du aldrig äter, klicka i rutan för ”aldrig”."
        />
        <GreyRow>
        <FreqInput titleWidth="320px" title="Kaffebröd, t ex bullar, mjuka kakor" _id="1.44_1" />
        </GreyRow>
        <FreqInput titleWidth="320px" title="Kex, rån, skorpor, småkakor eller liknande" _id="1.44_2" />
        <GreyRow>
        <FreqInput titleWidth="320px" title="Tårta, konditoribit eller liknande" _id="1.44_3" />
        </GreyRow>
        <FreqInput titleWidth="320px" title="Choklad" _id="1.44_4" />
        <GreyRow>
        <FreqInput titleWidth="320px" title="Godis (ej choklad)" _id="1.44_5" />
        </GreyRow>
        <FreqInput titleWidth="320px" title="Glass" _id="1.44_6" />
        <GreyRow>
        <FreqInput titleWidth="320px" title="Chips, ostbågar, popcorn eller liknande" _id="1.44_7" />
        </GreyRow>
        <FreqInput titleWidth="320px" title="Dessertost" _id="1.44_8" />

        <Validate className="next" answers={requiredAnswers}>
          <Link to="tarmsymptom#top">
            <button>Fortsätt &#8250;</button>
          </Link>
        </Validate><Previous to="sas" />
      </div>
    );
  }
}

export default Snacks;
