import styled from 'styled-components';

const Ex = styled.div`
margin: 0.6em 0 1.3em 1.5em;
font-size: 0.8em;
/*font-style: italic;*/
color: #666;
`

export default Ex;
