import Intro from './Intro';
import { Kvinnohalsa, requiredAnswers as KvinnohalsaRA } from './Kvinnohalsa';
import { ForeCancern, requiredAnswers as ForeCancernRA } from './ForeCancern';
import { SexuellKrankning, requiredAnswers as SexuellKrankningRA } from './SexuellKrankning';
import { Allmanna, requiredAnswers as AllmannaRA } from './Allmanna';
import { Avslutande, requiredAnswers as AvslutandeRA } from './Avslutande';
import Tack from './Tack';

export const requiredAnswers = [
  ...KvinnohalsaRA,
  ...ForeCancernRA,
  ...SexuellKrankningRA,
  ...AllmannaRA,
  ...AvslutandeRA
].map( a => '0.'+ a)

export { Intro, Kvinnohalsa, ForeCancern, SexuellKrankning, Allmanna, Avslutande, Tack }
