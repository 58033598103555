import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Headline, Validate, Radio, Title, Progress, Previous } from '../../fields';
import { numberOfCategories } from './index';

export const requiredAnswers = [85, 86, 87]

export class Bakgrund extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <div id="top">
        <Progress max={numberOfCategories} value={20} />
        <Title>BAKGRUND</Title>

        <Headline
          nr="85"
          question="Vilken utbildning har du? Välj den högsta utbildningen."
        />
        <Radio _id="1.85_1" value="grundskola" desc="Grundskola eller motsvarande" />
        <Radio _id="1.85_1" value="gymnasium" desc="Gymnasium, yrkesskola eller motsvarande" />
        <Radio _id="1.85_1" value="universitet" desc="Universitet eller högskola" />

        <Headline
          nr="86"
          question="Är du idag (civilstånd):"
        />
        <Radio _id="1.86_1" value="gift" desc="Gift/sammanboende" />
        <Radio _id="1.86_1" value="anka" desc="Änka/änkeman" />
        <Radio _id="1.86_1" value="sarbo" desc="Ensamboende med partner, ”särbo”" />
        <Radio _id="1.86_1" value="ensamboende" desc="Ensamboende utan fast partner" />

        <Headline
          nr="87"
          question="Ditt kön:"
        />
        <Radio _id="1.87_1" value="kvinna" desc="Kvinna" />
        <Radio _id="1.87_1" value="man" desc="Man" />
        <Radio _id="1.87_1" value="annat" desc="Annat" />

        <Validate className="next" answers={requiredAnswers}>
          <Link to="misc#top">
            <button>Fortsätt &#8250;</button>
          </Link>
        </Validate><Previous to="halsa" />

      </div>
    );
  }
}

export default Bakgrund;
