import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Headline, Radio, CategoryTitle, Validate, FreqQuestion2, Previous, Progress } from '../../fields';
import { numberOfCategories } from './index';

// 6 to 30
export const requiredAnswers = Array.from(new Array(25), (x,i) => i + 6)

export class Tarmsymptom extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <div id="top">
        <Progress max={numberOfCategories} value={2} />
        <CategoryTitle>B. FRÅGOR OM TARMHÄLSA</CategoryTitle>
        <FreqQuestion2
          nr="6" enkat="2"
          question="Har du haft hård avföring som har varit svår att få ut den senaste veckan?"
        />
        <FreqQuestion2
          nr="7" enkat="2"
          question="Har du haft lös avföring den senaste veckan?"
        />
        <FreqQuestion2
          nr="8" enkat="2"
          question="Har du återvänt till toaletten inom en timme efter avföring för att återigen tömma tarmen den senaste veckan?"
        />
        <FreqQuestion2
          nr="9" enkat="2"
          question="Har du haft plötsligt påkomna avföringsträngningar som krävt omedelbart toalettbesök den senaste veckan?"
        />

        <Headline
            nr="10"
            question="Hur länge har du kunnat hålla avföringen vid trängningar den senaste veckan?"
        />
        <Radio _id="2.10_1" value="inteAktuellt" desc="Inte aktuellt, jag har inte haft avföringsträngningar den senaste veckan" />
        <Radio _id="2.10_1" value="kortareAn1Min" desc="Kortare tid än 1 minut" />
        <Radio _id="2.10_1" value="mellan1och5Min" desc="Mellan 1 och 5 minuter" />
        <Radio _id="2.10_1" value="mellan5och10Min" desc="Mellan 5 och 10 minuter" />
        <Radio _id="2.10_1" value="mellan10och30Min" desc="Mellan 10 och 30 minuter" />
        <Radio _id="2.10_1" value="langreAn30Min" desc="30 minuter eller längre" />

        <FreqQuestion2
          nr="11" enkat="2"
          question="Har du utan förvarning läckt avföring i kläderna, trots att du tidigare tömt tarmen, den senaste veckan?"
        />
        <FreqQuestion2
          nr="12" enkat="2"
          question="Har du utan förvarning tömt <u>all</u> avföring i kläderna den senaste veckan?"
        />
        <FreqQuestion2
          nr="13" enkat="2"
          question="Har du haft läckage av lös avföring, när du varit <u>vaken</u>, den senaste veckan?"
        />
        <FreqQuestion2
          nr="14" enkat="2"
          question="Har du haft läckage av lös avföring, när du <u>sovit</u>, den senaste veckan?"
        />
        <FreqQuestion2
          nr="15" enkat="2"
          question="Har du haft läckage av fast avföring, när du varit <u>vaken</u>, den senaste veckan?"
        />
        <FreqQuestion2
          nr="16" enkat="2"
          question="Har du haft läckage av fast avföring, när du <u>sovit</u>, den senaste veckan?"
        />
        <FreqQuestion2
          nr="17" enkat="2"
          question="Har du tyckt att du har luktat avföring någon gång den senaste veckan?"
        />
        <FreqQuestion2
          nr="18" enkat="2"
          question="Har du haft en känsla av uppblåsthet i magen den senaste veckan?"
        />
        <FreqQuestion2
          nr="19" enkat="2"
          question="Har det hänt att du inte har kunnat hålla kvar gas (fjärtar, fisar) i ändtarmen när det behövts den senaste veckan?"
        />
        <FreqQuestion2
          nr="20" enkat="2"
          question="Har du haft ljudliga gasavgångar (fjärtar, fisar) som du inte kunnat stoppa den senaste veckan?"
        />
        <FreqQuestion2
          nr="21" enkat="2"
          question="Har du haft illaluktande gasavgångar (fjärtar, fisar) som du inte kunnat stoppa den senaste veckan?"
        />
        <FreqQuestion2
          nr="22" enkat="2"
          question="Har det kommit blod från tarmen den senaste veckan?"
        />
        <FreqQuestion2
          nr="23" enkat="2"
          question="Har du haft läckage av blod från ändtarmen, när du varit <u>vaken</u>, den senaste veckan?"
        />
        <FreqQuestion2
          nr="24" enkat="2"
          question="Har du haft läckage av blod från ändtarmen, när du <u>sovit</u>, den senaste veckan?"
        />
        <FreqQuestion2
          nr="25" enkat="2"
          question="Har det kommit slem från tarmen den senaste veckan?"
        />
        <FreqQuestion2
          nr="26" enkat="2"
          question="Har du haft läckage av slem från ändtarmen, när du varit <u>vaken</u>, den senaste veckan?"
        />
        <FreqQuestion2
          nr="27" enkat="2"
          question="Har du haft läckage av slem från ändtarmen, när du <u>sovit</u>, den senaste veckan?"
        />
        <FreqQuestion2
          nr="28" enkat="2"
          question="Har du haft klåda vid ändtarmsöppningen den senaste veckan?"
        />
        <FreqQuestion2
          nr="29" enkat="2"
          question="Har du haft smärta i ändtarmsöppningen den senaste veckan?"
        />
        <FreqQuestion2
          nr="30" enkat="2"
          question="Har du haft smärtor i buken den senaste veckan?"
        />

        <Validate className="next" answers={requiredAnswers} enkat="2">
          <Link to="matvanor#top">
            <button>Fortsätt &#8250;</button>
          </Link>
        </Validate><Previous to="snacks" />
      </div>
    );
  }
}

export default Tarmsymptom;
