import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Checkbox, Headline, Validate, Title, VitaminTable, Radio, Progress, Previous } from '../../fields';
import { numberOfCategories } from './index';

export const requiredAnswers = [79, 80, 81]

export class Mediciner extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {

    return (
      <div id="top">
        <Progress max={numberOfCategories} value={17} />
        <Title>HÄLSOKOSTPREPARAT, NÄRINGSDRYCK OCH MEDICINER</Title>
        <Headline
            nr="79"
            question="Fyll i ditt intag av vitaminer, mineraler, hälsopreparat och näringsdryck som du regelbundet tagit de senaste tre månaderna."
            desc="Ange vad du ätit, varumärkesnamn (dvs vad produkten kallas), ungefärlig mängd per vecka och ungefär hur länge du  har använt det."
        />
        <Checkbox _id="3.79_0" desc="Jag har inte ätit några vitaminer, mineraler, hälsopreparat eller näringsdryck de senaste tre månaderna." />
        <VitaminTable _id="3.79_1" examples={[['t ex D-Vitamin', 'Apoteket', '7 tabletter', '2 år' ]]} />

        <Headline
            nr="80"
            question="Fyll i ditt intag av gas- och tarmreglerande medel (förstoppande, laxerande) som du regelbundet tagit de tre senaste månaderna."
            desc="T ex Dimetikon, Minifom, Dimor, Imodium, Inolaxol, Vi-Siblin, Lunelax, Cilaxoral."
            small="Ange vad du ätit, varumärkesnamn (vad produkten kallas), ungefärlig mängd per vecka och ungefär hur länge du har använt det."
        />
        <Checkbox _id="3.80_0" desc="Jag har inte ätit tarmreglerande medel de senaste tre månaderna." />
        <VitaminTable _id="3.80_1" examples={[
          ['T ex gasdämpande', 'Dimetikon', '7 tabletter', '6 mån' ],
          ['T ex förstoppande medel', 'Dimor', '7 tabletter', '6 mån' ],
          ['T ex laxerande medel', 'Cilaxoral', '10 droppar', '1 mån' ]
        ]} />

        <Headline
            nr="81"
            question="Hur många gånger har du ätit antibiotika mot infektioner (t ex penicillin, Kåvepenin, Ery-Max, Vibramycin) det senaste året?"
        />
        <Radio _id="3.81_1" value="0ganger" desc="0 gånger" />
        <Radio _id="3.81_1" value="1-3ganger" desc="1 – 3 gånger" />
        <Radio _id="3.81_1" value="4-6ganger" desc="4 – 6 gånger" />
        <Radio _id="3.81_1" value="flerAn6ganger" desc="fler än 6 gånger" />
        <Radio _id="3.81_1" value="vetEj" desc="vet ej" />

        <Validate className="next" answers={requiredAnswers} enkat="3">
          <Link to="tobak#top">
            <button>Fortsätt &#8250;</button>
          </Link>
        </Validate><Previous to="fysisk" />

      </div>
    );
  }
}

export default Mediciner;
