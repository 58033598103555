import React, { Component } from "react";
// eslint-disable-next-line
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

import { Intro, Kostvanor, Tarmsymptom, Matvanor, Fysisk, Mediciner, Tobak, Halsa,
Stralbehandling, Misc, Slutet, requiredAnswers } from './enkat2';

import Help from './Help';
import Logout from './Logout';

import Panel from '../Panel';
import { isAdmin } from '../DB';

class Enkat2 extends Component {
  render() {
    return (
      <div>
        <Route exact path="/fidura/enkat/2" render={() => (
          <Redirect to="/fidura/enkat/2/intro"/>
        )}/>

        { isAdmin && (
          <Panel answers={requiredAnswers} />
        )}

        <Help />

        <Logout />

        <Route exact path="/fidura/enkat/2/intro" component={Intro} />
        <Route exact path="/fidura/enkat/2/kostvanor" component={Kostvanor} />
        <Route exact path="/fidura/enkat/2/tarmsymptom" component={Tarmsymptom} />
        <Route exact path="/fidura/enkat/2/matvanor" component={Matvanor} />
        <Route exact path="/fidura/enkat/2/fysisk" component={Fysisk} />
        <Route exact path="/fidura/enkat/2/mediciner" component={Mediciner} />
        <Route exact path="/fidura/enkat/2/halsa" component={Halsa} />
        <Route exact path="/fidura/enkat/2/stralbehandling" component={Stralbehandling} />
        <Route exact path="/fidura/enkat/2/tobak" component={Tobak} />
        <Route exact path="/fidura/enkat/2/misc" component={Misc} />
        <Route exact path="/fidura/enkat/2/slutet" component={Slutet} />
      </div>
    );
  }
}

export default Enkat2;
