import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { FreqInput, Input, Checkbox, Validate, Headline, Title, OBS, Ex, Progress, Previous } from '../../fields';
import { numberOfCategories } from './index';

import quorn from '../images/quorn.png';
import vegofars from '../images/vegofars.png';
import falafel from '../images/falafel.png';

export const requiredAnswers = ['33_1', '33_2', 34, 35]

export class Vegetarisk extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <div id="top">
        <Progress max={numberOfCategories} value={8} />
        <Title>ÄGGRÄTTER OCH VEGETARISKA ALTERNATIV</Title>
        <Headline
            nr="33"
            question="Hur ofta äter du äggrätter och vegetariska alternativ?"
            desc="Fyll i antal gånger och klicka endast i ett alternativ (dag, vecka eller månad) per typ av livsmedel du använder. Om det är någon typ av livsmedel du aldrig äter, klicka i rutan för ”aldrig”."
        />
        <FreqInput title="Äggrätter" _id="3.33_1" />
        <Ex>(t ex kokt/stekt ägg, äggröra, pannkakor, omelett)</Ex>
        <FreqInput title="Vegetariska produkter" _id="3.33_2" />
        <Ex>(t ex grönsaksbiffar, falafel, quorn, sojaprodukter, tofu)</Ex>

        <Headline
            nr="34"
            question="Tänk dig att du äter äggrätter <u>tio gånger</u> i följd."
            desc="Hur många gånger av dessa brukar du äta:"
        />
        <Checkbox hidden _id="3.34_0" aldrigToggle="3.33_1" />
        <Input ten desc="Kokt ägg" _id="3.34_1" />
        <Input ten desc="Stekt ägg, omelett, äggröra" _id="3.34_2" />
        <Input ten desc="Pannkaka" _id="3.34_3" />
        <OBS sumTen="3.34" />

        <Headline
            nr="35"
            question="Tänk dig att du äter vegetariska produkter <u>tio gånger</u> i följd."
            desc="Hur många gånger av dessa brukar du äta:"
        />
        <Checkbox hidden _id="3.35_0" aldrigToggle="3.33_2" />
        <Input ten desc="Quornprodukter (t ex filéer, bitar)" _id="3.35_1"
          exImg={quorn} />
        <Input ten desc="Sojaprodukter (t ex sojakorv, sojafärs, sojabiff, tofu)" _id="3.35_2"
          exImg={vegofars} />
        <Input ten desc="Grönsaksbiffar (t ex morotsbiff, vegetariska burgare, falafel)" _id="3.35_3"
          exImg={falafel} />
        <OBS sumTen="3.35" />

        <Validate className="next" answers={requiredAnswers} enkat="3">
          <Link to="potatis#top">
            <button>Fortsätt &#8250;</button>
          </Link>
        </Validate><Previous to="fisk" />
      </div>
    );
  }
}

export default Vegetarisk;
