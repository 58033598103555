import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import styled from 'styled-components';
import { Exemplet } from '../../fields';

const Row = styled.div`
margin: 2em 0 1em 2em;
`

const Title = styled.span`
font-size: 2em;
font-weight: bold;
`

const SubTitle = styled.span`
font-size: 1.5em;
font-weight: bold;
`

const Text = styled.p`
margin: 1em 0 1em 2em;
`

const Spacer = styled.div`
height: 1.2em;
`

const Intro = (props) => {

  return (
    <>
      <Row>
        <Title>FIDURA - </Title>
        <SubTitle>ENKÄT 1</SubTitle>
      </Row>
      <Text>
        Välkommen till FIDURA, en studie om kostfiberintag under strålbehandling av bäckenområdet.
      </Text>
      <Spacer />
      <Text>
        I detta frågeformulär finns frågor om mat- och levnadsvanor, kosttillskott och läkemedel samt vissa sjukdomsrelaterade frågor. Vi ber dig göra en uppskattning av hur du har ätit och i övrigt levt under de <u>senaste tre månaderna</u>, om inget annat anges. Enkäten är uppdelad i tre olika delar A, B och C.
      </Text>
      <Spacer />
      <Text>
        <u>Vissa av frågorna är ställda på samma vis som i exemplet nedan:</u>
      </Text>

      <Exemplet />

      <Link className="next" to="smorgasar#top">
        <button>Börja</button>
      </Link>
    </>
  );
}

export default Intro;
