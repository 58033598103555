import React, { Component } from 'react';
import styled from 'styled-components';
import { Previous } from '../../fields';

const Text = styled.p`
margin: 1em 0 1em 2em;
`

const Spacer = styled.div`
height: 2em;
`

const BigAssTack = styled.p`
margin: 1em 0 1em 2em;
font-size: 3em;
`

export class Tack extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <div>
        <Spacer />
        <Text>Forskningsresultaten kommer kontinuerligt att publiceras på <a href="https://eftercancern.se">eftercancern.se</a></Text>
        <Spacer />
        <Text><strong>Stort tack för din medverkan! Du kan stänga denna enkät nu.</strong></Text>
        <BigAssTack>Tack</BigAssTack>

        <br />
        <Previous to="slutet" />
      </div>
    );
  }
}

export default Tack;
