import React, { Component } from "react";
// eslint-disable-next-line
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

import ECProject from './ECProject';

import FEnkat1 from './fidura/admin/Enkat1';
import FEnkat2 from './fidura/admin/Enkat2';
import FEnkat3 from './fidura/admin/Enkat3';

import VEnkat0 from './vista/admin/Enkat0';
import VEnkat1 from './vista/admin/Enkat1';

class Admin extends Component {
  render() {
    return (
      <div className="admin">
        { ECProject === 'fidura' && (
          <div>
            <Route exact path="/fidura/enkat/admin/" render={() => (
              <Redirect to="/fidura/enkat/admin/1"/>
            )}/>

            <Route exact path="/fidura/enkat/admin/1" component={FEnkat1} />
            <Route exact path="/fidura/enkat/admin/2" component={FEnkat2} />
            <Route exact path="/fidura/enkat/admin/3" component={FEnkat3} />
          </div>
        )}
        { ECProject === 'vista' && (
          <div>
            <Route exact path="/vista/enkat/admin/" render={() => (
              <Redirect to="/vista/enkat/admin/0"/>
            )}/>

            <Route exact path="/vista/enkat/admin/0" component={VEnkat0} />
            <Route exact path="/vista/enkat/admin/1" component={VEnkat1} />
          </div>
        )}
      </div>
    );
  }
}

export default Admin;
