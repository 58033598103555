import React from 'react'
import styled from 'styled-components';

const HelpTip = styled.div`
position: fixed;
z-index: 1000;
top: 22px;
left: 22px;
text-align: center;
background-color: #1d62d1;
border-radius: 50%;
width: 34px;
height: 34px;
font-size: 21px;
line-height: 35px;
cursor: default;

&:before {
    content:'?';
    font-weight: bold;
    color:#fff;
}

&:hover p {
    display:block;
    transform-origin: 100% 0%;

    -webkit-animation: fadeIn 0.3s ease-in-out;
    animation: fadeIn 0.3s ease-in-out;

}
`;

const Content = styled.p`
display: none;
text-align: left;
background-color: #1E2021;
padding: 20px;
max-width: 500px;
width: 500px;
position: absolute;
left: 0;
border-radius: 3px;
box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
right: 0px;
color: #FFF;
font-size: 18px;
line-height: 1.4;

&:before { 
    position: absolute;
    content: '';
    width:0;
    height: 0;
    border:6px solid transparent;
    border-bottom-color:#1E2021;
    left:10px;
    top:-12px;
}

&:after {
    width:100%;
    height:40px;
    content:'';
    position: absolute;
    top:-40px;
    left:0;
}

& a {
    color: #96beff;
}
`

const Help = (props) => {

  return (
    <HelpTip>
      <Content>
        Frågor? Kontakta oss på telefonnummer 031-786 51 30 / 076-618 51 30 eller mejladress <a href="mailto:fidura@vgregion.se">fidura@vgregion.se</a>. Vi har ambitionen att svara under vardagar klockan 8–15. Om vi inte svarar i telefon, lämna namn och telefonnummer och tider som vi kan ringa upp dig på.
      </Content>
    </HelpTip>
  )
}

export default Help;
