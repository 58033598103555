export const checkAnswer = (id, allAnswers) => {
  let fields = []

  if (id.includes('_')) {
    fields = [ allAnswers.find( a => a._id === id) ]
    if (fields[0] === undefined) fields = []
  } else {
    fields = allAnswers.filter( answer =>
    answer._id.startsWith(id+'_') && answer._id !== id+'_0' )
  }

  // Exception for "om ja" questions
  if (['1.70', '1.71', '1.75', '1.76',
    '2.31', '2.32', '2.33', '2.39', '2.40', '2.41',
    '3.70', '3.71', '3.72', '3.76', '3.77', '3.78'].includes(id)) {
    let radio = fields.find( answer => answer._id === id+'_1')
    let field1 = fields.find( answer => answer._id === id+'_2' )
    let field2 = fields.find( answer => answer._id === id+'_3' )

    return (
      (radio !== undefined && 'value' in radio && radio.value !== "ja") ||
      (field1 !== undefined && 'text' in field1 && field1.text !== '' &&
       field2 !== undefined && 'text' in field2 && field2.text !== '')
    )
  }

  // Exception for "om ja" questions
  if (['2.2'].includes(id)) {
    let checkbox = allAnswers.find( answer => answer._id === id+'_0' )
    let disabled = checkbox !== undefined && 'checked' in checkbox && checkbox.checked
    let radio = fields.find( answer => answer._id === id+'_1')
    let field1 = fields.find( answer => answer._id === id+'_2' )

    return (
      disabled ||
      (radio !== undefined && 'value' in radio && radio.value !== "ja") ||
      (field1 !== undefined && 'text' in field1 && field1.text !== '')
    )
  }

  // Exception for "om ja" questions
  if (['2.1'].includes(id)) {
    let radio = fields.find( answer => answer._id === id+'_1')
    let allFields = fields.filter( answer =>
    answer._id.startsWith(id+'_') && answer._id !== id+'_1' && answer.value !== undefined)

    return (
      (radio !== undefined && 'value' in radio && radio.value !== "ja") ||
      allFields.length === 17
    )
  }

  // Exception for "om ja" radio questions
  if (['2.45'].includes(id)) {
    let radio = fields.find( answer => answer._id === id+'_1')
    let jaField = fields.find( answer => answer._id === id+'_2' )

    return (
      (radio !== undefined && 'value' in radio && radio.value !== "ja") ||
      (jaField !== undefined && 'values' in jaField && jaField.values.length > 0)
    )
  }

  // Single sum field < 11
  if (['1.4', '3.4', '1.16', '3.16'].includes(id)) {
    let checkbox = allAnswers.find( answer => answer._id === id+'_0' )
    let disabled = checkbox !== undefined && 'checked' in checkbox && checkbox.checked

    let a = allAnswers.find( answer => answer._id === id+'_1' )
    return disabled || (a && !isNaN(parseInt(a.text)) && parseInt(a.text) < 11)
  }

  // FreqInput fields
  let freqInputFields = fields.filter( answer =>
    answer._id.startsWith(id) && ('times' in answer || 'freq' in answer))

  if (freqInputFields.length > 0) {

    let validFreqInputFields = freqInputFields.filter( answer => {
      if (!('freq' in answer)) {
        return false
      }

      if (answer.freq === 'aldrig') {
        return true
      } else {
        return (('times' in answer) && ('freq' in answer)
          && answer.freq !== '' &&
          !isNaN(parseInt(answer.times)) &&
          answer.times !== '')
      } 
    })

    return validFreqInputFields.length === freqInputFields.length
  }

  // Potentially disabled fields
  let checkbox = allAnswers.find( answer => answer._id === id+'_0' )
  let disabled = checkbox !== undefined && 'checked' in checkbox && checkbox.checked

  if (fields.length === 0) {
    return disabled
  } else if (fields.length === 1) {
    // Case of a solo input field, radio choices or of the vitamin table
    let field = fields[0]

    if ('omJa' in field) {
      let omJaObj = allAnswers.find( answer => answer._id === field.omJa )
      disabled = disabled ||
        (omJaObj !== undefined && 'value' in omJaObj && omJaObj.value !== 'ja')
    }

    return disabled ||
      ('month' in field && 'month' !== undefined
        || 'year' in field && 'year' !== undefined
        || 'day' in field && 'day' !== undefined) ||
      ('sum' in field && field.sum === '10') ||
      ('sum' in field && field.sum && field.text === '10') ||
      ((!('sum' in field) || field.sum === undefined || field.sum === false || field.sum === '')
        && 'text' in field && field.text !== ''
        && (!('min' in field) || field.min === undefined || parseInt(field.text) >= parseInt(field.min))
        && (!('max' in field) || field.max === undefined || parseInt(field.text) <= parseInt(field.max))) ||
      ('vad' in field && field.vad[0] !== ''
        && 'mangdPerVecka' in field && field.mangdPerVecka[0] !== ''
        && 'hurLange' in field && field.hurLange[0] !== ''
        && 'varumarkesnamn' in field && field.varumarkesnamn[0] !== '') ||
      ('value' in field && !('customValue' in field) && field.value !== '') ||
      ('value' in field && 'customValue' in field && field.customValue !== '') ||
      ('values' in field && field.values.length > 0)

  } else {
    // Case of sum ten fields
    let sum = 0
    fields.forEach( field => {
      if ('text' in field && !isNaN(parseInt(field.text))) {
        sum += parseInt(field.text)
      }
    });

    return disabled || sum === 10
  }
}


export const getInvalidQuestions = (answers, required, enkat = '1') => {

  // Prepend enkat number to answer ID if needed
  let requiredAnswers = required.map( id => {
    id = id.toString()
    return (!id.startsWith('0.') &&
    !id.startsWith('1.') &&
    !id.startsWith('2.') &&
    !id.startsWith('3.') ? enkat + '.' + id : id)
  })

  let badAnswers = requiredAnswers.filter( id =>
    !checkAnswer(id, answers));

  let badQuestions = badAnswers.map( id => {
    id = id.toString()
    return id.replace(/([0123]\.)?([0-9]+)(_[0-9]+)?/, "$2")
  })

  return [...new Set(badQuestions)]

};
