import React from 'react'
//import styled from 'styled-components';
import { connect } from "react-redux";
import { swapDB } from "./Actions";
import { dbList, userList, initialUser } from './DB';

const DefaultProps = {
  user: '',
  fetching: false
}

const mapStateToProps = (state, ownProps) => {
  let { user, fetching } = state

  return {...DefaultProps, user: user, fetching: fetching }
};

const mapDispatchToProps = dispatch => {
  return {
    swapDB: db => dispatch(swapDB(db))
  };
};

class ConnectedUserList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: props.user,
      fetching: props.fetching
    };

    this.changeDB = this.changeDB.bind(this);
  }

  componentDidUpdate(prevProps) {
    Object.keys(DefaultProps).forEach( key => {
      if (this.props[key] !== prevProps[key])
        this.setState({ [key]: this.props[key] })
    })
  }

  changeDB(event) {
    let user = event.target.value

    console.log(user)

    this.setState({ user: user },
      () => { this.props.swapDB( {
        id: dbList[user],
        user: user
      }) });
  }

  renderOptions() {
    let children = []

    for (var user in userList) {
      let login = userList[user]
      let username = initialUser === user ? login + ' (dig)' : login
      children.push(<option key={user} value={user}>{username}</option>)
    };

    return children
  }

  render() {

    const { user, fetching } = this.props

    return(
      <div>
        <strong>User ID: &nbsp;</strong>
        <select
          id="currentUser"
          onChange={this.changeDB}
          disabled={fetching}
          value={user}
        >
          {this.renderOptions()}
        </select>
      </div>
    )
  }
}

const UserList = connect(mapStateToProps, mapDispatchToProps)(ConnectedUserList);

export default UserList;
