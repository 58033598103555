import React from 'react'
import { connect } from "react-redux";
import styled from 'styled-components';
import { getInvalidQuestions } from './Validation';

const DefaultProps = {
  questionsLeft: [],
  loading: true
}

const mapStateToProps = (state, ownProps) => {

  let questionsLeft = getInvalidQuestions(
    state.answers,
    ownProps.answers,
    ownProps.enkat
  )

  return {...DefaultProps, ...{
    questionsLeft: questionsLeft,
    loading: state.fetching
  }}
};

class ConnectedInvalidList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      questionsLeft: props.questionsLeft,
      loading: props.loading,
      enkat: props.enkat
    };
  }

  componentDidUpdate(prevProps) {
    Object.keys(DefaultProps).forEach( key => {
      if (this.props[key] !== prevProps[key])
        this.setState({ [key]: this.props[key] })
    })
  }

  render() {

    const { loading, questionsLeft } = this.props

    const Note = styled.small`
    margin: 0 0 1em;
    display: block;
    text-align: center;
    color: red;

    & a {
      color: red;
      text-decoration-style: dotted;
    }
    `

    const Complete = styled.small`
    margin: 0 0 1em;
    display: block;
    text-align: center;
    color: green;
    `

    const questions = questionsLeft.map((q) => `<a href="#${q}">${q}</a>`)

    const plural = questions.length === 1 ? 'frågan' : 'frågorna'

    let list = ""

    if (questions.length === 1) {
      list = questions[0]
    } else if (questions.length === 2) {
      list = questions[0] + ' och ' + questions[1]
    } else {
      let last = questions.length-1
      list = questions.filter((x, i) => i !== last).join(', ')
        + ' och '
        + questions[last]
    }

    const warning = `Måste fylla i ${plural} ${list} korrekt.`

    const completed = questionsLeft.length === 0

    return (
      <div>
        { !loading && !completed && (
          <Note dangerouslySetInnerHTML={{ __html: warning}} />
        )}
        { completed &&
          <Complete>Fylld</Complete>
        }
      </div>
    )
  }
}

const InvalidList = connect(mapStateToProps)(ConnectedInvalidList);

export default InvalidList;
