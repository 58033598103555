import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { FreqInput, Checkbox, Input, Validate, Headline, Title, OBS, Ex, Previous, Img, Progress } from '../../fields';
import { numberOfCategories } from './index';

import tillagadeGronsaker from '../images/tillagadeGronsaker.png';
import soppaGronsaker from '../images/soppaGronsaker.png';
import grytaGronsaker from '../images/grytaGronsaker.png';
import salad1 from '../images/salad1.png';
import salad2 from '../images/salad2.png';
import farskFrukt from '../images/farskFrukt.png';
import bar from '../images/bar.png';
import soppaKram from '../images/soppaKram.png';
import appleMos from '../images/appleMos.png';

export const requiredAnswers = ['20_1', '20_2', '20_3', 21, 22, 23, '24_1', '24_2', '24_3', '24_4', 25, 26, 27, 28]

export class GronsakerFrukt extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <div id="top">
        <Progress max={numberOfCategories} value={5} />
        <Title>GRÖNSAKER OCH FRUKT</Title>
        <Headline
            nr="20"
            question="Hur ofta äter du grönsaker?"
            desc="Fyll i antal gånger och klicka endast i ett alternativ (dag, vecka eller månad) per typ av grönsaker du äter. Om det är någon typ av grönsak du aldrig äter, klicka i rutan för ”aldrig”."
        />
        <FreqInput title="Tillagade grönsaker" _id="1.20_1" />
        <Ex>(t ex kokt/stekt/stuvad blomkål, bönor, ärtor, spenat)</Ex>
        <FreqInput title="Grönsaker i del av maträtt" _id="1.20_2" />
        <Ex>(t ex grytor och gratänger, ärtsoppa, kålpudding)</Ex>
        <FreqInput title="Blandad sallad/kalla grönsaker" _id="1.20_3" />
        <Ex>(t ex tomat, råkost, isbergssallad)</Ex>

        <Headline
            nr="21"
            question="Tänk dig att du äter TILLAGADE grönsaker (t ex kokta, stuvade, stekta) <u>tio gånger</u> i följd."
            short
        />
        <Img inline src={tillagadeGronsaker} desc="Bild från tasteline.se" />

        <p>Hur många gånger brukar grönsakerna vara:</p>
        <Checkbox hidden _id="1.21_0" aldrigToggle="1.20_1" />
        <Input ten desc="Bönor, linser eller kikärtor (t ex kidneybönor, sojabönor, vita bönor)" _id="1.21_1" />
        <Input ten desc="Gröna bönor (t ex bondbönor, haricots verts)" _id="1.21_2" />
        <Input ten desc="Kål (t ex blomkål, vitkål, rödkål)" _id="1.21_3" />
        <Input ten desc="Morötter, rödbetor" _id="1.21_4" />
        <Input ten desc="Ärtor (gröna, gula)" _id="1.21_5" />
        <Input ten desc="Broccoli, brysselkål" _id="1.21_6" />
        <Input ten desc="Spenat" _id="1.21_7" />
        <Input ten desc="Svamp" _id="1.21_8" />
        <OBS sumTen="1.21" />

        <Headline
            nr="22"
            question="Tänk dig att du äter grönsaker som DEL AV EN MATRÄTT (t ex i gryta, soppa, gratäng) <u>tio gånger</u> i följd."
            short
        />
        <Img inline src={soppaGronsaker} desc="Bild från tasteline.se" />
        <Img inline src={grytaGronsaker} desc="Bild från tasteline.se" />

        <p>Hur många gånger brukar grönsakerna vara:</p>
        <Checkbox hidden _id="1.22_0" aldrigToggle="1.20_2" />
        <Input ten desc="Bönor, linser eller kikärtor (t ex kidneybönor, sojabönor, vita bönor)" _id="1.22_1" />
        <Input ten desc="Gröna bönor (t ex bondbönor, haricots verts)" _id="1.22_2" />
        <Input ten desc="Kål (t ex blomkål, vitkål, rödkål)" _id="1.22_3" />
        <Input ten desc="Kålrot, rotselleri" _id="1.22_4" />
        <Input ten desc="Morötter, rödbetor" _id="1.22_5" />
        <Input ten desc="Ärtor" _id="1.22_6" />
        <Input ten desc="Broccoli, brysselkål" _id="1.22_7" />
        <Input ten desc="Spenat" _id="1.22_8" />
        <Input ten desc="Lök (t ex gul lök, purjolök, vitlök)" _id="1.22_9" />
        <Input ten desc="Svamp" _id="1.22_10" />
        <OBS sumTen="1.22" />

        <Headline
            nr="23"
            question="Tänk dig att du äter SALLAD (kalla grönsaker) <u>tio gånger</u> i följd."
            short
        />
        <Img inline src={salad1} desc="Bild från tasteline.se" />
        <Img inline src={salad2} desc="Bild från tasteline.se" />

        <p>Hur många gånger brukar salladen bestå av:</p>
        <Checkbox hidden _id="1.23_0" aldrigToggle="1.20_3" />
        <Input ten desc="Tomat" _id="1.23_1" />
        <Input ten desc="Paprika" _id="1.23_2" />
        <Input ten desc="Gurka, isbergssallad eller grönsallad" _id="1.23_3" />
        <Input ten desc="Bladspenat, ruccolasallad" _id="1.23_4" />
        <Input ten desc="Bönor, linser eller kikärtor (t ex kidneybönor, sojabönor, vita bönor)" _id="1.23_5" />
        <Input ten desc="Ärtor" _id="1.23_6" />
        <Input ten desc="Gröna bönor (t.ex. bondbönor, haricots verts)" _id="1.23_7" />
        <Input ten desc="Majs" _id="1.23_8" />
        <Input ten desc="Morot" _id="1.23_9" />
        <Input ten desc="Vitkål" _id="1.23_10" />
        <Input ten desc="Avocado" _id="1.23_11" />
        <Input ten desc="Oliver" _id="1.23_12" />
        <OBS sumTen="1.23" />

        <Headline
            nr="24"
            question="Hur ofta äter du frukt och bär?"
            desc="Med soppa eller kräm gjord på frukt eller bär menar vi färska eller frysta frukter eller bär. Ta ej med sorter som är gjorda på pulver. Fyll i antal gånger och klicka endast i ett alternativ (dag, vecka eller månad) per typ av livsmedel du äter. Om det är någon typ av frukt eller bär du aldrig äter, klicka i rutan för ”aldrig”."
        />
        <FreqInput title="Någon typ av frukt" _id="1.24_1" />
        <FreqInput title="Någon typ av bär" _id="1.24_2" />
        <FreqInput title="Soppa eller kräm gjord på frukt eller bär" _id="1.24_3" />
        <FreqInput title="Sylt eller mos gjord på frukt eller bär" _id="1.24_4" />

        <Headline
            nr="25"
            question="Tänk dig att du äter färsk frukt <u>tio gånger</u> i följd."
            short
        />
        <Img inline src={farskFrukt} />

        <p>Hur många gånger brukar frukten vara:</p>
        <Checkbox hidden _id="1.25_0" aldrigToggle="1.24_1" />
        <Input ten desc="Äpple" _id="1.25_1" />
        <Input ten desc="Banan" _id="1.25_2" />
        <Input ten desc="Citrusfrukter (t ex apelsin, mandarin, grapefrukt)" _id="1.25_3" />
        <Input ten desc="Päron" _id="1.25_4" />
        <Input ten desc="Plommon" _id="1.25_5" />
        <Input ten desc="Melon" _id="1.25_6" />
        <Input ten desc="Vindruvor" _id="1.25_7" />
        <Input ten desc="Nektarin, persika, aprikos" _id="1.25_8" />
        <Input ten desc="Granatäpple" _id="1.25_9" />
        <OBS sumTen="1.25" />

        <Headline
            nr="26"
            question="Tänk dig att du äter färska eller frysta bär <u>tio gånger</u> i följd."
            short
        />
        <Img inline src={bar} />

        <p>Hur många gånger brukar bären vara:</p>
        <Checkbox hidden _id="1.26_0" aldrigToggle="1.24_2" />
        <Input ten desc="Jordgubbar" _id="1.26_1" />
        <Input ten desc="Blåbär" _id="1.26_2" />
        <Input ten desc="Hallon" _id="1.26_3" />
        <Input ten desc="Hjortron" _id="1.26_4" />
        <Input ten desc="Svarta vinbär, röda vinbär" _id="1.26_5" />
        <Input ten desc="Björnbär" _id="1.26_6" />
        <Input ten desc="Lingon" _id="1.26_7" />
        <Input ten desc="Körsbär" _id="1.26_8" />
        <OBS sumTen="1.26" />

        <Headline
            nr="27"
            question="Tänk dig att du äter soppa eller kräm gjord på frukt eller bär <u>tio gånger</u> i följd."
            short
        />
        <Img inline src={soppaKram} />
        <p>Med soppa eller kräm gjord på frukt eller bär menar vi färska eller frysta frukter eller bär. Ta ej med sorter som är gjorda på pulver. Hur många av gångerna brukar soppan eller krämen vara gjord på:</p>
        <Checkbox hidden _id="1.27_0" aldrigToggle="1.24_3" />
        <Input ten desc="Äpple" _id="1.27_1" />
        <Input ten desc="Apelsin, mandarin" _id="1.27_2" />
        <Input ten desc="Plommon" _id="1.27_3" />
        <Input ten desc="Torkad frukt" _id="1.27_4" />
        <Input ten desc="Jordgubbar" _id="1.27_5" />
        <Input ten desc="Blåbär" _id="1.27_6" />
        <Input ten desc="Hallon" _id="1.27_7" />
        <Input ten desc="Hjortron" _id="1.27_8" />
        <Input ten desc="Svarta vinbär, röda vinbär" _id="1.27_9" />
        <Input ten desc="Björnbär" _id="1.27_10" />
        <Input ten desc="Körsbär" _id="1.27_11" />
        <Input ten desc="Nypon" _id="1.27_12" />
        <OBS sumTen="1.27" />

        <Headline
            nr="28"
            question="Tänk dig att du äter sylt eller mos gjord på frukt eller bär <u>tio gånger</u> i följd."
            short
        />
        <Img inline src={appleMos} />
        <p>Hur många gånger brukar sylten eller moset vara gjord på:</p>
        <Checkbox hidden _id="1.28_0" aldrigToggle="1.24_4" />
        <Input ten desc="Jordgubbar" _id="1.28_1" />
        <Input ten desc="Blåbär" _id="1.28_2" />
        <Input ten desc="Hallon" _id="1.28_3" />
        <Input ten desc="Hjortron" _id="1.28_4" />
        <Input ten desc="Svarta vinbär, röda vinbär" _id="1.28_5" />
        <Input ten desc="Björnbär" _id="1.28_6" />
        <Input ten desc="Lingon" _id="1.28_7" />
        <Input ten desc="Körsbär" _id="1.28_8" />
        <Input ten desc="Äpple" _id="1.28_9" />
        <OBS sumTen="1.28" />

        <Validate className="next" answers={requiredAnswers}>
          <Link to="kott#top">
            <button>Fortsätt &#8250;</button>
          </Link>
        </Validate><Previous to="drycker" />
      </div>
    );
  }
}

export default GronsakerFrukt;
