import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { FreqInput, Input, Checkbox, Validate, Headline, Title, OBS, Ex, Previous, Progress } from '../../fields';
import { numberOfCategories } from './index';

export const requiredAnswers = [29, 30]

export class Kott extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <div id="top">
        <Progress max={numberOfCategories} value={6} />
        <Title>KÖTT OCH KÖTTPRODUKTER</Title>
        <Headline
            nr="29"
            question="Hur ofta äter du kött och köttprodukter?"
            desc="Ta ej med köttprodukter som du äter på smörgåsen. Fyll i antal gånger och klicka endast i ett alternativ (dag, vecka eller månad). Om du aldrig äter kött, klicka i rutan för ”aldrig”."
        />
        <FreqInput title="Någon typ av kött" _id="1.29_1" />
        <Ex>(t ex nöt, fläsk, kyckling, färsprodukter, vilt, korv, bacon, blodpudding)</Ex>

        <Headline
            nr="30"
            question="Tänk dig att du äter kött eller köttprodukter <u>tio gånger</u> i följd."
            desc="Hur många gånger av dessa brukar köttet vara:"
        />
        <Checkbox hidden _id="1.30_0" aldrigToggle="1.29_1" />
        <Input ten desc="Köttfärsrätter (t ex köttbullar, pannbiff, köttfärssås)" _id="1.30_1" />
        <Input ten desc="Fläskkött (hel, gryta)" _id="1.30_2" />
        <Input ten desc="Nöt-, kalvkött (hel, gryta)" _id="1.30_3" />
        <Input ten desc="Lamm-, viltkött (hel, gryta)" _id="1.30_4" />
        <Input ten desc="Kyckling, kalkon (hel, gryta)" _id="1.30_5" />
        <Input ten desc="Korv (t ex falukorv, varmkorv, fläskkorv)" _id="1.30_6" />
        <Input ten desc="Bacon, sidfläsk" _id="1.30_7" />
        <Input ten desc="Blodpudding, blodkorv" _id="1.30_8" />
        <Input ten desc="Lever, njure" _id="1.30_9" />
        <OBS sumTen="1.30" />

        <Validate className="next" answers={requiredAnswers}>
          <Link to="fisk#top">
            <button>Fortsätt &#8250;</button>
          </Link>
        </Validate><Previous to="gronsaker-frukt" />
      </div>
    );
  }
}

export default Kott;
