import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { FreqInput, Input, Checkbox, Validate, Headline, Title, OBS, Ex, Previous, Progress } from '../../fields';
import { numberOfCategories } from './index';

export const requiredAnswers = [38, 39]

export class Snabbmat extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <div id="top">
        <Progress max={numberOfCategories} value={10} />
        <Title>SNABBMAT</Title>
        <Headline
            nr="38"
            question="Hur ofta äter du snabbmat?"
            desc="Fyll i antal gånger och klicka endast i ett alternativ (dag, vecka eller månad). Om du aldrig äter snabbmat, klicka i rutan för ”aldrig”."
        />
        <FreqInput title="Någon typ av snabbmat" _id="1.38_1" />
        <Ex>(t ex pizza, pirog, hamburgare, sushi, kebab, pommes frites)</Ex>

        <Headline
            nr="39"
            question="Tänk dig att du äter snabbmat <u>tio gånger</u> i följd."
            desc="Hur många gånger av dessa brukar du äta:"
        />
        <Checkbox hidden _id="1.39_0" aldrigToggle="1.38_1" />
        <Input ten desc="Pizza" _id="1.39_1" />
        <Input ten desc="Hamburgare, kebab med bröd" _id="1.39_2" />
        <Input ten desc="Matpaj, pirog" _id="1.39_3" />
        <Input ten desc="Sushi" _id="1.39_4" />
        <Input ten desc="Pommes frites" _id="1.39_5" />
        <Input ten desc="Wraps, smörgåsar med röra" _id="1.39_6" />
        <OBS sumTen="1.39" />

        <Validate className="next" answers={requiredAnswers}>
          <Link to="matfett#top">
            <button>Fortsätt &#8250;</button>
          </Link>
        </Validate><Previous to="potatis" />
      </div>
    );
  }
}

export default Snabbmat;
