import React from 'react'
import styled from 'styled-components';

import EnkatList from './EnkatList';
import UserList from './UserList';
import InvalidList from './InvalidList';
import Control from './fields/Control';

const Container = styled.div`
display: block;
width: 100%;
clear: both;
background: #eee;
padding: 1.5em;
`

const LeftCol = styled.div`
float: left;
width: 50%;
`;

const RightCol = styled.div`
float: right;
width: 50%;
text-align: right;
`;

const Clear = styled.div`
clear: both;
height: 1.5em;
`

const Panel = (props) => {

  const { admin, answers } = props
  
  return (
    <Container>
      <LeftCol>
        { admin && (
          <EnkatList />
        )}
        { admin && (
          <br />
        )}
        <UserList />
      </LeftCol>
      <RightCol>
        <Control />
      </RightCol>
      <Clear />
      <InvalidList answers={answers} />
    </Container>
  )
}

export default Panel;
