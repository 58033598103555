import React from 'react'
import styled from 'styled-components';
import { connect } from "react-redux";
import { commitAnswer } from "../Actions";

const DefaultProps = {
  checked: false,
  disabled: false
}

const Row = styled.div`
margin-bottom: 1.5em;
`

const Description = styled.span`
margin: 0;
`

const Input = styled.input`
display: inline;
margin: 0 1em 0 2em;
`

const mapStateToProps = (state, ownProps) => {
  let stateObj = state.answers.find( answer => answer._id === ownProps._id );
  let checked = stateObj !== undefined &&
    'checked' in stateObj &&
    stateObj.checked
  let disabled = false
  let toggleID = ownProps.aldrigToggle
  let nejID = ownProps.nejToggle
  let toggleAnswer = ownProps.aldrigToggleAnswer
  let toggleList = ownProps.aldrigToggleList

  if (ownProps.hidden) {
    checked = false
  }

  if (toggleID !== undefined) {
    let freqInput = state.answers.find( answer => answer._id === toggleID );
    disabled = freqInput !== undefined && (
      ('freq' in freqInput && freqInput.freq === 'aldrig') ||
      ('times' in freqInput && parseInt(freqInput.times) === 0))

    checked = checked || disabled
  }

  if (nejID !== undefined) {
    let answer = state.answers.find( answer => answer._id === nejID );
    disabled = answer !== undefined && (
      ('value' in answer && answer.value === 'nej'))

    checked = checked || disabled
  }

  if (toggleAnswer !== undefined) {
    let key = Object.keys(toggleAnswer)[0]
    let answer = state.answers.find( answer => answer._id === key );
    disabled = answer !== undefined && (
      ('value' in answer && answer.value === toggleAnswer[key]))

    checked = checked || disabled
  }

  if (toggleList !== undefined) {
    let aldrigList = toggleList.filter( tID => {
      let freqInput = state.answers.find( answer => answer._id === tID );
      return freqInput !== undefined && (
        ('freq' in freqInput && freqInput.freq === 'aldrig') ||
        ('times' in freqInput && parseInt(freqInput.times) === 0))
    })

    disabled = aldrigList.length === toggleList.length

    if (toggleAnswer !== undefined && !disabled) {
      let key = Object.keys(toggleAnswer)[0]
      let answer = state.answers.find( answer => answer._id === key );
      disabled = answer !== undefined && (
        ('value' in answer && answer.value === toggleAnswer[key]))
    }

    checked = checked || disabled
  }

  return {...DefaultProps, checked: checked, disabled: disabled }
};

const mapDispatchToProps = dispatch => {
  return {
    commitAnswer: answer => dispatch(commitAnswer(answer))
  };
};

class ConnectedCheckbox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: props.checked,
      disabled: props.disabled
    };

    this.toggleCheck = this.toggleCheck.bind(this);
  }

  componentDidUpdate(prevProps) {
    Object.keys(DefaultProps).forEach( key => {
      if (this.props[key] !== prevProps[key]) {
        this.setState({ [key]: this.props[key] }, () => {
          if (key === 'checked' && (
            Object.keys(this.props).includes('aldrigToggle') ||
            Object.keys(this.props).includes('nejToggle') ||
            Object.keys(this.props).includes('aldrigToggleAnswer') ||
            Object.keys(this.props).includes('aldrigToggleList')
          )) {
            this.saveChanges()
          }
        })
      }
    })
  }

  toggleCheck(event) {
    this.setState({
      checked: event.target.checked
    }, () => this.saveChanges());
  }

  saveChanges() {
    const _id = this.props._id;
    let { checked } = this.state;
    this.props.commitAnswer({ _id, checked })
  }

  render() {
    const RowStyle = {
      display: this.props.hidden ? 'none': 'block'
    }

    return(
      <Row style={RowStyle}>
        <Input
          type="checkbox"
          checked={this.props.checked}
          onChange={this.toggleCheck}
          disabled={this.props.disabled}
        />
        <Description dangerouslySetInnerHTML={{__html: this.props.desc }}/>
      </Row>
    )
  }
}

const Checkbox = connect(mapStateToProps, mapDispatchToProps)(ConnectedCheckbox);

export default Checkbox;
