import React from 'react'
import styled from 'styled-components';
import { connect } from "react-redux";

const DefaultProps = {
  visible: true
}

const Text = styled.div`
margin: 1em;
font-size: 1em;
font-style: italic;
color: #FF2400;
`

const mapStateToProps = (state, ownProps) => {
  let id = ownProps.sumTen

  // Check if disabled
  let checkbox = state.answers.find( answer => answer._id === id+'_0' )
  let disabled = checkbox !== undefined && 'checked' in checkbox && checkbox.checked

  let fields = state.answers.filter( answer =>
    answer._id.startsWith(id+'_') && answer._id !== id+'_0' );

  let sum = 0
  fields.forEach( field => {
    if ('text' in field && !isNaN(parseInt(field.text))) {
      sum += parseInt(field.text)
    }
  });

  return {...DefaultProps, visible: !disabled && sum !== 10  }
};

class ConnectedOBS extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: props.visible
    };
  }

  componentDidUpdate(prevProps) {
    Object.keys(DefaultProps).forEach( key => {
      if (this.props[key] !== prevProps[key])
        this.setState({ [key]: this.props[key] })
    })
  }

  render() {

    const { visible } = this.props

    const Style = {
      visibility: visible ? 'visible': 'hidden'
    }

    return(
      <Text style={Style}>OBS! Totala summan ska alltid bli 10, ange endast heltal</Text>
    )
  }
}

const OBS = connect(mapStateToProps)(ConnectedOBS);

export default OBS;
