import React from 'react'
import Radio from './Radio';
import Headline from './Headline';

const FreqQuestion2 = (props) => {

  const { nr, question, enkat } = props
  const id = `${enkat}.${nr}_1`
  
  return (
    <div>
        <Headline
            nr={nr}
            question={question}
            short
        />
        <Radio _id={id} value="nej" desc="Nej" />
        <Radio _id={id} value="nagonGang" desc="Ja, någon gång under denna vecka" />
        <Radio _id={id} value="treGanger" desc="Ja, ungefär tre gånger denna vecka" />
        <Radio _id={id} value="varjeDygn" desc="Ja, ungefär någon gång varje dygn" />
        <Radio _id={id} value="fleraGanger" desc="Ja, flera gånger varje dygn" />
    </div>
  )
}

export default FreqQuestion2;
