import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { FreqInput, Input, Checkbox, Validate, Headline, Radio, Title, CategoryTitle, OBS, Progress, Previous } from '../../fields';
import { numberOfCategories } from './index';

import vittBrod from '../images/vittbrod.jpg';
import vittBrod2 from '../images/vittbrod2.jpg';
import limpa from '../images/limpa.jpg';
import limpa2 from '../images/limpa2.png';
import medelgrovtEj from '../images/medelgrovtEj.jpg';
import medelgrovtEj2 from '../images/medelgrovtEj2.jpg';
import medelgrovtAv from '../images/medelgrovtAv.png';
import medelgrovtAv2 from '../images/medelgrovtAv2.jpg';
import fullkornsbrod from '../images/fullkornsbrod.jpg';

import veteKnacke from '../images/veteKnacke.png';
import havreKnacke from '../images/havreKnacke.png';
import husmanKnacke from '../images/husmanKnacke.png';

export const requiredAnswers = ['1_1', '1_2', 2, 3, 4, 5, 6, 7]

export class Smorgasar extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <div id="top">
        <Progress max={numberOfCategories} value={1} />
        <CategoryTitle>A. FRÅGOR OM KOSTVANOR</CategoryTitle>
        <Title>SMÖRGÅSAR</Title>
        <Headline
            nr="1"
            question="Hur ofta äter du bröd?"
            desc="Fyll i antal gånger och klicka endast i ett alternativ (dag, vecka eller månad) per typ av bröd du
            äter. Om det är någon typ av bröd du aldrig äter, klicka i rutan för ”aldrig”."
        />
        <FreqInput skivor title="Mjukt bröd" _id="3.1_1" />
        <FreqInput skivor title="Hårt bröd (knäckebröd)" _id="3.1_2" />

        <Headline
            nr="2"
            question="Tänk dig att du äter mjukt bröd <u>tio gånger</u> i följd."
            desc="Hur många gånger brukar brödet vara:"
        />
        <Checkbox hidden _id="3.2_0" aldrigToggle="3.1_1" />
        <Input ten desc="Vitt bröd" _id="3.2_1" exImg={vittBrod} exImg2={vittBrod2} />
        <Input ten desc="Limpa" _id="3.2_2" exImg={limpa} exImg2={limpa2} />
        <Input ten desc="Medelgrovt bröd, ej råg" _id="3.2_3" exImg={medelgrovtEj} exImg2={medelgrovtEj2} />
        <Input ten desc="Medelgrovt bröd av råg" _id="3.2_4" exImg={medelgrovtAv} exImg2={medelgrovtAv2} />
        <Input ten desc="Grovt fullkornsbröd av råg (t ex nyckelhålsmärkt)" _id="3.2_5" exImg={fullkornsbrod} />
        <OBS sumTen="3.2" />

        <Headline
            nr="3"
            question="Tänk dig att du äter hårt bröd (knäckebröd) <u>tio gånger</u> i följd."
            desc="Hur många gånger brukar brödet vara gjort på:"
        />
        <Checkbox hidden _id="3.3_0" aldrigToggle="3.1_2" />
        <Input ten desc="Vete (t ex Frukost, Solruta)" _id="3.3_1" exImg={veteKnacke} />
        <Input ten desc="Havre" _id="3.3_2" exImg={havreKnacke} />
        <Input ten desc="Råg (t ex Husman, Sport, Delikatess, Brungräddat)" _id="3.3_3" exImg={husmanKnacke} />
        <OBS sumTen="3.3" />

        <Headline
            nr="4"
            question="Tänk dig att du äter bröd <u>tio gånger</u> i följd."
            desc="Hur många gånger brukar brödet innehålla frön (t ex lin-, pumpa-, sesam,
            solros-, vallmofrö) inuti eller ovanpå? Skriv in 0 (noll) om inte någon av
            smörgåsarna innehåller frön."
        />
        <Checkbox hidden _id="3.4_0" aldrigToggleList={["3.1_1", "3.1_2"]} />
        <Input int desc="Antal smörgåsar" _id="3.4_1" max="10" />
        
        <Headline
            nr="5"
            question="Hur mycket matfett brukar du ha på din mjuka eller hårda smörgås?"
            desc="Ett paket är lika mycket som den förpackning med matfett på 10 gram (2tsk)
            som man brukar få på t ex lunchrestauranger."
        />
        <Checkbox hidden _id="3.5_0" aldrigToggleList={["3.1_1", "3.1_2"]} />
        <Radio _id="3.5_1" value="0" desc="Ingenting, använder ej matfett på smörgåsen" />
        <Radio _id="3.5_1" value="0.5" desc="½ paket" />
        <Radio _id="3.5_1" value="1" desc="1 paket" />
        <Radio _id="3.5_1" value="1.5" desc="1½ paket" />
        <Radio _id="3.5_1" value="2" desc="2 paket" />
        <Radio _id="3.5_1" value="mer" desc="Mer än 2 paket" />

        <Headline
            nr="6"
            question="Tänk dig att du äter smörgås <u>tio gånger</u> i följd."
            desc="På hur många av smörgåsarna brukar du använda:"
        />
        <Checkbox _id="3.6_0" desc="Jag använder inte matfett på smörgåsen."
          aldrigToggleList={["3.1_1", "3.1_2"]}
          aldrigToggleAnswer={{"3.5_1": "0"}} />
        <Input ten desc="Smör (80 %, i folie)" _id="3.6_1" />
        <Input ten desc="Bregott (75-80 %) - Extrasaltat, Havssalt, Brynt, grön/gul/röd förpackning" _id="3.6_2" />
        <Input ten desc="Bregott mellan (60 %) - blå förpackning" _id="3.6_3" />
        <Input ten desc="Smörgåsmargarin (60-80 %, t ex FinFin, Milda, Runda Bords)" _id="3.6_4" />
        <Input ten desc="Lättmargarin (28-40 %, t ex Becel original, Lätta, Lätt&Lagom, Mini Lätta)" _id="3.6_5" />
        <Input ten desc="Kolesterolsänkande lättmargarin (35-40 %, t ex Becel pro.activ, Benecol Tasty) " _id="3.6_6" />
        <Input ten desc="Smörgås utan matfett" _id="3.6_7" />
        <OBS sumTen="3.6" />

        <Headline
            nr="7"
            question="Tänk dig att du äter smörgåspålägg <u>tio gånger</u> i följd."
            desc="Hur många gånger brukar pålägget vara:"
        />
        <Checkbox _id="3.7_0" desc="Jag använder inte smörgåspålägg." aldrigToggleList={["3.1_1", "3.1_2"]} />
        <Input ten desc="Ost" _id="3.7_1" />
        <Input ten desc="Keso eller mjukost" _id="3.7_2" />
        <Input ten desc="Köttpålägg (skinka, kalkon, saltrulle)" _id="3.7_3" />
        <Input ten desc="Korvpålägg" _id="3.7_4" />
        <Input ten desc="Leverpastej" _id="3.7_5" />
        <Input ten desc="Kaviar, sardiner, sill " _id="3.7_6" />
        <Input ten desc="Marmelad" _id="3.7_7" />
        <Input ten desc="Smörgås utan pålägg" _id="3.7_8" />
        <OBS sumTen="3.7" />

        <Validate className="next" answers={requiredAnswers} enkat="3">
          <Link to="grot-flingor-musli#top">
            <button>Fortsätt &#8250;</button>
          </Link>
        </Validate><Previous to="intro" />
      </div>
    );
  }
}

export default Smorgasar;
