import Intro from './Intro';
import { Kostvanor, requiredAnswers as KostvanorRA } from './Kostvanor';
import { Tarmsymptom, requiredAnswers as TarmsymptomRA } from './Tarmsymptom';
import { Matvanor, requiredAnswers as MatvanorRA } from './Matvanor';
import { Fysisk, requiredAnswers as FysiskRA } from './Fysisk';
import { Mediciner, requiredAnswers as MedicinerRA } from './Mediciner';
import { Tobak, requiredAnswers as TobakRA } from './Tobak';
import { Halsa, requiredAnswers as HalsaRA } from './Halsa';
import { Stralbehandling, requiredAnswers as StralbehandlingRA } from './Stralbehandling';
import { Misc, requiredAnswers as MiscRA } from './Misc';
import Slutet from './Slutet';

export const requiredAnswers = [
  ...KostvanorRA,
  ...TarmsymptomRA,
  ...MatvanorRA,
  ...FysiskRA,
  ...MedicinerRA,
  ...HalsaRA,
  ...StralbehandlingRA,
  ...TobakRA,
  ...MiscRA
].map( a => '2.'+ a)

export const numberOfCategories = 9

export { Intro, Kostvanor, Tarmsymptom, Matvanor, Fysisk, Mediciner, Tobak, Halsa,
  Stralbehandling, Misc, Slutet }
