import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import styled from 'styled-components';
import { Input, Validate, Headline, Radio, Title, Previous } from '../../fields';

export const requiredAnswers = [29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39]

const Spacer = styled.div`
height: 2em;
`

export class Allmanna extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <div id="top">
        <Title>ALLMÄNNA FRÅGOR</Title>

        <Spacer />

        <Input _id="0.29_1" headline="1. Ålder:" large line />
        <Input _id="0.30_1" headline="2. Längd:" large line desc="cm" int min="3" max="300" />
        <Input _id="0.31_1" headline="3. Vikt:" large line desc="kg" int min="30" max="500" />
        <Input _id="0.32_1" headline="4. Vad vägde du när du fick din cancerdiagnos?" large line desc="kg" int min="30" max="500" />

        <Headline
            nr="5"
            anchor="33"
            question="Vilken cancerdiagnos får eller ska du få behandling för nu?"
        />
        <Radio _id="0.33_1" value="livmodercancer" desc="Livmodercancer (Corpuscancer)" />
        <Radio _id="0.33_1" value="livmoderhalscancer" desc="Livmoderhalscancer (Cervixcancer)" />
        <Radio _id="0.33_1" value="vulvacancer" desc="Vulvacancer" />
        <Radio _id="0.33_1" value="analcancer" desc="Analcancer" />
        <Radio _id="0.33_1" value="annan" desc="Annan, nämligen:" custom />

        <Headline
            nr="6"
            anchor="34"
            question="Är du idag (civilstånd):"
        />
        <Radio _id="0.34_1" value="gift" desc="Gift/sammanboende" />
        <Radio _id="0.34_1" value="anka" desc="Änka/änkeman" />
        <Radio _id="0.34_1" value="sarbo" desc="Ensamboende med partner, ”särbo”" />
        <Radio _id="0.34_1" value="ensamboendeUtanFastPartner" desc="Ensamboende utan fast partner" />

        <Headline
            nr="7"
            anchor="35"
            question="Vilken utbildning har du? Välj den högsta utbildningen."
        />
        <Radio _id="0.35_1" value="grundskola" desc="Grundskola eller motsvarande" />
        <Radio _id="0.35_1" value="gymnasium" desc="Gymnasium, yrkesskola eller motsvarande" />
        <Radio _id="0.35_1" value="universitet" desc="Universitet eller högskola" />

        <Headline
            nr="8"
            anchor="36"
            question="Vilken är din nuvarande sysselsättning?"
        />
        <Radio _id="0.36_1" value="studerande" desc="Studerande" />
        <Radio _id="0.36_1" value="arbetssokande" desc="Arbetssökande" />
        <Radio _id="0.36_1" value="forvarvsarbetande" desc="Förvärvsarbetande" />
        <Radio _id="0.36_1" value="hemarbetande" desc="Icke förvärvsarbetande, hemarbetande" />
        <Radio _id="0.36_1" value="sjukskriven" desc="Sjukskriven" />
        <Radio _id="0.36_1" value="sjukpensionar" desc="Sjukpensionär" />
        <Radio _id="0.36_1" value="avtalpensionar" desc="Ålderspensionär/avtalspensionär" />

        <Headline
            nr="9"
            anchor="37"
            question="Var bor du?"
        />
        <Radio _id="0.37_1" value="paLandet" desc="På landet (enstaka grannhus)" />
        <Radio _id="0.37_1" value="mindreOrt" desc="I mindre ort, mindre stad eller mellanstor stad" />
        <Radio _id="0.37_1" value="storStad" desc="I en stor stad" />

        <Headline
            nr="10"
            anchor="38"
            question="Röker du cigaretter regelbundet?"
        />
        <Radio _id="0.38_1" value="nej" desc="Nej, jag har aldrig rökt" />
        <Radio _id="0.38_1" value="roker" desc="Ja, jag röker" />
        <Radio _id="0.38_1" value="slutatVid" desc="Jag har rökt tidigare, men slutat vid" custom short after="års ålder" />

        <Headline
            nr="11"
            anchor="39"
            question="Röker du cigaretter regelbundet?"
        />
        <Radio _id="0.39_1" value="nej" desc="Nej, jag har aldrig snusat" />
        <Radio _id="0.39_1" value="snusar" desc="Ja, jag snusar" />
        <Radio _id="0.39_1" value="slutat" desc="Jag har snusat tidigare, men slutat" />

        <Validate className="next" answers={requiredAnswers} enkat="0" offset={28}>
          <Link to="avslutande#top">
            <button>Fortsätt &#8250;</button>
          </Link>
        </Validate><Previous to="sexuellkrankning" />
      </div>
    );
  }
}

export default requiredAnswers;
