import PouchDB from 'pouchdb'
import { pushAnswers, pushingDone, setAnswers } from "./Actions";
import store from './Store';
import ECProject from './ECProject';

const ECEndpoint = ECProject === 'vista' ?
  'https://eftercancern.se/vista-db/':
  'https://eftercancern.se/fidura-db/'

const UserAPIEndpoint = ECProject === 'vista' ?
  'https://eftercancern.se/vista/api/v1/':
  'https://eftercancern.se/fidura/api/v1/'

export const isLocal = window.location.hostname.startsWith('localhost') ||
  window.location.hostname.startsWith('survey.dev.')

export const isAdmin = isLocal || window.DBList

export const endpoint = isLocal ? '' : ECEndpoint

export const dbList = isLocal ? {
  'user1': 'localDB1',
  'user2': 'localDB2',
  'user3': 'localDB3'
} : window.DBList

export const initialUser = isLocal ? 'user2' : window.userID

var db = {}

export const loginList = isLocal ? {
  'localDB1': '10012323',
  'localDB2': '20303030',
  'localDB3': '10302030'
} : window.LoginList

var login = isLocal ? '20303030' : window.userLogin

export var userList = {}

if (dbList) {
  Object.keys(dbList).forEach( (user) => {
    if (dbList[user] in loginList) {
      userList[user] = loginList[dbList[user]]
    } else {
      userList[user] = user
    }
  })
}

export const initDB = () => {
  if (isLocal) {
    console.log("The changes won't be saved. Running locally")
  }

  loadDB(isLocal ? 'localDB2' : window.userDB)
}

export const loadDB = (dbName) => {
  console.log(`Switching to ${endpoint}${dbName}`)

  db = new PouchDB(`${endpoint}${dbName}`)

  login = isAdmin && loginList && dbName in loginList ? loginList[dbName] : login

  fetchAnswers();
}

export const push = (answers) => {

  // Pouch/CouchDB needs to get the revision in order to avoid conflicts
  // so we fetch the relevant documents first
  db.allDocs({
    include_docs: true,
    keys: answers.map( a => a._id )

  }).then( result => {

    let docList = result.rows.map( row => (
      { ...row.doc, ...answers.find(a => a._id === row.key) }
    ));

    return db.bulkDocs(docList)

  }).then( result => {
    let currentTime = new Date(Date.now()).toLocaleTimeString()
    console.log(`Modification saved (${currentTime})`)

  }).catch( err => {
    console.log(err)

  }).finally( () => {
    store.dispatch(pushingDone())
  });

}

export const fetchAnswers = () => {

  db.allDocs({ include_docs: true }).then( result => {
    store.dispatch(setAnswers(result.rows.map(r => r.doc)));
  }).catch( err => {
    console.log(err)
  });

}

export const syncEveryNowAndThen = ( seconds = 3 ) => {

  setInterval( () => store.dispatch(pushAnswers()), seconds * 1000 );

}

export const validateSurvey = ( survey = '1' ) => {
  if (!isLocal) {
    let url = `${UserAPIEndpoint}login/${login}/survey/${survey}/taken`

    fetch(url, {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'same-origin',
      referrer: 'no-referrer'
    }).then( response => console.log(`Survey ${survey} taken`, response) )

  } else {
    console.log('Survey taken')
  }
}

export const surveysValidated = ( callback ) => {
  if (isLocal) {
    console.log({ 'local': true, 'taken': ['baseline'] })
  } else {
    let url = `${UserAPIEndpoint}login/${login}/surveys_taken`

    fetch(url, {
      method: 'GET',
      cache: 'no-cache',
      credentials: 'same-origin',
      referrer: 'no-referrer'
    }).then((resp) => resp.json())
    .then( data => callback( data ) )
  }
}
