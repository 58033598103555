import { initDB, loadDB, initialUser, push, syncEveryNowAndThen } from './DB';

const initialState = () => {

  initDB();

  syncEveryNowAndThen();

  return {
    answers: [],
    updatedAnswers: new Set(),
    pushing: false,
    fetching: true,
    user: initialUser
  }
}

const answerReducer = (state = initialState(), action) => {
  switch (action.type) {
    case "COMMIT_ANSWER":
      let id = action.payload._id
      const newAnswers = [ ...state.answers.filter( a => (
        a._id !== id )), action.payload ]

      return {
        ...state,
        updatedAnswers: state.updatedAnswers.add(id),
        answers: newAnswers
      }

    case "PUSH_ANSWERS":
      if (state.pushing || state.updatedAnswers.size === 0) {
        return state;
      } else {
        const relevantAnswers = state.answers.filter( answer =>
          state.updatedAnswers.has(answer._id)
        );

        push(relevantAnswers);
        return { ...state, updatedAnswers: new Set(), pushing: true };
      }

    case "PUSHING_DONE":
      return { ...state, pushing: false };

    case "SET_ANSWERS":
      return { ...state, fetching: false, answers: [...state.answers, ...action.payload] };

    case "SWAP_DB":
      loadDB(action.payload.id);

      return { ...state,
        answers: [],
        fetching: true,
        pushing: false,
        updatedAnswers: new Set(),
        user: action.payload.user };

    default:
      return state;
  }
};

export default answerReducer;
