import styled from 'styled-components';

const Title = styled.div`
margin: 0.5em 0em 0em;
padding: 1.5em 1em 0;
font-size: 2em;
text-transform: uppercase;
color: #999;
border-bottom: 2px dotted #999;
`

export default Title;
