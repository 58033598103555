import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { FreqInput, Input, Headline, Validate, Title, OBS, Ex, Checkbox, Previous, Progress } from '../../fields';
import { numberOfCategories } from './index';

export const requiredAnswers = ['41_1', '41_2', 42, 43]

export class Sas extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <div id="top">
        <Title>SÅS OCH DRESSING</Title>
        <Progress max={numberOfCategories} value={12} />
        <Headline
            nr="41"
            question="Hur ofta äter du dressing, kalla röror och sås?"
            desc="Fyll i antal gånger och klicka endast i ett alternativ (dag, vecka eller månad) per typ av livsmedel du använder. Om du aldrig äter dressing, kall röra eller sås, klicka i rutan för ”aldrig”."
        />
        <FreqInput title="Någon typ av kall sås eller röra" _id="1.41_1" />
        <Ex>(t ex salladsdressing, vinägrett, aioli, skagenröra)</Ex>
        <FreqInput title="Någon typ av varm sås" _id="1.41_2" />
        <Ex>(baserad på t ex mjölkprodukter eller buljong)</Ex>

        <Headline
            nr="42"
            question="Tänk dig <u>tio måltider</u> i följd som du har dressing, kall sås eller röra till."
            desc="Hur många gånger brukar dressingen, den kalla såsen eller röran vara:"
        />
        <Checkbox _id="1.42_0" desc="Jag äter inte dressing, kall sås eller röra." aldrigToggle="1.41_1" />
        <Input ten desc="Olivolja" _id="1.42_1" />
        <Input ten desc="Vinägrett på olja" _id="1.42_2" />
        <Input ten desc="Salladsdressing, t ex rhode island, vitlök" _id="1.42_3" />
        <Input ten desc="Sås baserad på lätt crème fraiche, gräddfil, matyoghurt" _id="1.42_4" />
        <Input ten desc="Sås baserad på grädde, crème fraiche (34 % fetthalt)" _id="1.42_5" />
        <Input ten desc="Majonnäs, aioli eller liknande" _id="1.42_6" />
        <Input ten desc="Remouladsås, skagenröra, rödbetssallad eller liknande" _id="1.42_7" />
        <OBS sumTen="1.42" />

        <Headline
            nr="43"
            question="Tänk dig <u>tio måltider</u> i följd som du har varm sås till."
            desc="Hur många gånger brukar såsen vara gjord på:"
        />
        <Checkbox _id="1.43_0" desc="Jag äter inte varm sås." aldrigToggle="1.41_2" />
        <Input ten desc="Skysås, buljong" _id="1.43_1" />
        <Input ten desc="Mjölk" _id="1.43_2" />
        <Input ten desc="Magra mejeriprodukter (10 % fetthalt eller lägre)" _id="1.43_3" />
        <Input ten desc="Matlagningsgrädde, lätt crème fraiche (15 % fetthalt)" _id="1.43_4" />
        <Input ten desc="Mellangrädde, smaksatt crème fraiche (27 % fetthalt)" _id="1.43_5" />
        <Input ten desc="Vispgrädde, crème fraiche (34 % fetthalt)" _id="1.43_6" />
        <Input ten desc="Smör, t ex bearnaise-, hollandaisesås" _id="1.43_7" />
        <OBS sumTen="1.43" />

        <Validate className="next" answers={requiredAnswers}>
          <Link to="snacks#top">
            <button>Fortsätt &#8250;</button>
          </Link>
        </Validate><Previous to="matfett" />
      </div>
    );
  }
}

export default Sas;
