import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { FreqInput, Input, Checkbox, Validate, Headline, Title, OBS, Ex, Previous, Progress } from '../../fields';
import { numberOfCategories } from './index';

export const requiredAnswers = [36, 37]

export class Potatis extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <div id="top">
        <Progress max={numberOfCategories} value={9} />
        <Title>POTATIS, PASTA, RIS OCH GRYNER</Title>
        <Headline
            nr="36"
            question="Hur ofta äter du potatis, pasta, ris och gryn?"
            desc="Fyll i antal gånger och klicka endast i ett alternativ (dag, vecka eller månad). Om du aldrig äter potatis, pasta, ris och gryn, klicka i rutan för ”aldrig”."
        />
        <FreqInput title="Någon typ av potatis, pasta, ris eller gryn" _id="1.36_1" />
        <Ex>(t ex kokt potatis, makaroner eller couscous)</Ex>

        <Headline
            nr="37"
            question="Tänk dig att du äter potatis, pasta, ris eller gryn <u>tio gånger</u> i följd."
            desc="Hur många gånger av dessa brukar du äta:"
        />
        <Checkbox hidden _id="1.37_0" aldrigToggle="1.36_1" />
        <Input ten desc="Potatis (t ex kokt, mos, klyftor)" _id="1.37_1" />
        <Input ten desc="Vit pasta (t ex makaroner, spaghetti, pastaskruvar)" _id="1.37_2" />
        <Input ten desc="Fullkornspasta (t ex makaroner, spaghetti, pastaskruvar)" _id="1.37_3" />
        <Input ten desc="Vitt ris" _id="1.37_4" />
        <Input ten desc="Fullkorns-/råris" _id="1.37_5" />
        <Input ten desc="Gryn (t ex bulgur, couscous, matvete, quinoa)" _id="1.37_6" />
        <OBS sumTen="1.37" />

        <Validate className="next" answers={requiredAnswers}>
          <Link to="snabbmat#top">
            <button>Fortsätt &#8250;</button>
          </Link>
        </Validate><Previous to="vegetarisk" />
      </div>
    );
  }
}

export default Potatis;
