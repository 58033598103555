import React, { Component } from "react";
// eslint-disable-next-line
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

import { Intro, Smorgasar, GrotFlingorMusli, NotterFron, Drycker,
GronsakerFrukt, Kott, Fisk, Vegetarisk, Potatis, Snabbmat, Matfett,
Sas, Snacks, Tarmsymptom, Matvanor, Fysisk, Mediciner, Tobak, Halsa,
Bakgrund, Misc, Slutet, Tack, requiredAnswers } from './enkat1';

import Help from './Help';
import Logout from './Logout';

import Panel from '../Panel';
import { isAdmin } from '../DB';

class Enkat1 extends Component {
  render() {
    return (
      <div>
        <Route exact path="/fidura/enkat/1" render={() => (
          <Redirect to="/fidura/enkat/1/intro"/>
        )}/>

        { isAdmin && (
          <Panel answers={requiredAnswers} />
        )}

        <Help />
        <Logout />

        <Route exact path="/fidura/enkat/1/intro" component={Intro} />
        <Route exact path="/fidura/enkat/1/smorgasar" component={Smorgasar} />
        <Route exact path="/fidura/enkat/1/grot-flingor-musli" component={GrotFlingorMusli} />
        <Route exact path="/fidura/enkat/1/notter-fron" component={NotterFron} />
        <Route exact path="/fidura/enkat/1/drycker" component={Drycker} />
        <Route exact path="/fidura/enkat/1/gronsaker-frukt" component={GronsakerFrukt} />
        <Route exact path="/fidura/enkat/1/kott" component={Kott} />
        <Route exact path="/fidura/enkat/1/fisk" component={Fisk} />
        <Route exact path="/fidura/enkat/1/vegetarisk" component={Vegetarisk} />
        <Route exact path="/fidura/enkat/1/potatis" component={Potatis} />
        <Route exact path="/fidura/enkat/1/snabbmat" component={Snabbmat} />
        <Route exact path="/fidura/enkat/1/matfett" component={Matfett} />
        <Route exact path="/fidura/enkat/1/sas" component={Sas} />
        <Route exact path="/fidura/enkat/1/snacks" component={Snacks} />
        <Route exact path="/fidura/enkat/1/tarmsymptom" component={Tarmsymptom} />
        <Route exact path="/fidura/enkat/1/matvanor" component={Matvanor} />
        <Route exact path="/fidura/enkat/1/fysisk" component={Fysisk} />
        <Route exact path="/fidura/enkat/1/mediciner" component={Mediciner} />
        <Route exact path="/fidura/enkat/1/tobak" component={Tobak} />
        <Route exact path="/fidura/enkat/1/halsa" component={Halsa} />
        <Route exact path="/fidura/enkat/1/bakgrund" component={Bakgrund} />
        <Route exact path="/fidura/enkat/1/misc" component={Misc} />
        <Route exact path="/fidura/enkat/1/slutet" component={Slutet} />
        <Route exact path="/fidura/enkat/1/tack" component={Tack} />
      </div>
    );
  }
}

export default Enkat1;
