import React from 'react'
import styled from 'styled-components';
import { connect } from "react-redux";
import { commitAnswer } from "../Actions";

const DefaultProps = {
  checked: false,
  customValue: undefined,
  values: [],
  disabled: false,
  hidden: false,
  omJa: undefined,
  warning: false
}

const Row = styled.div`
margin: 0 0 0.5em 2em;
`

const RadioLabel = styled.label`
display: inline;
margin-right: 1rem;
`

const RadioInput = styled.input`
`

const Input = styled.input`
display: inline;
width: 10em;
text-align: left;
margin: 0;
border: 0;
border-bottom: 1px solid black;
font-size: 1.1em;

&:focus {
  outline: none;
}

&:disabled {
  background: inherit;
}
`

const mapStateToProps = (state, ownProps) => {
  let stateObj = state.answers.find( answer => answer._id === ownProps._id );
  let checked = stateObj !== undefined && 'value' in stateObj && stateObj.value === ownProps.value;
  let values = stateObj !== undefined && 'values' in stateObj ? stateObj.values : DefaultProps.values;
  let nejID = ownProps.nejToggle
  let hidden = false;

  if ('multiple' in ownProps && ownProps.multiple) {
    checked = values.includes(ownProps.value);
  }

  let customValue = (stateObj !== undefined && 'customValue' in stateObj && checked) ?
    stateObj.customValue : undefined;

  if ('omJa' in ownProps && ownProps.omJa) {
    let omJaObj = state.answers.find( answer => answer._id === ownProps.omJa )
    hidden = omJaObj === undefined || !('value' in omJaObj) || omJaObj.value !== 'ja'
    if (hidden) checked = false
  }

  // If there is a checked checkbox on top, with ID X_0, disable the field
  let checkboxID = ownProps._id.replace(/_.+/g, "_0")
  let checkbox = state.answers.find( answer => answer._id === checkboxID)
  let disabled = (checkbox !== undefined && 'checked' in checkbox && checkbox.checked) || hidden
  hidden = hidden && (!('show' in ownProps) || !ownProps.show)

  if (nejID !== undefined) {
    let answer = state.answers.find( answer => answer._id === nejID );
    disabled = answer !== undefined && (
      ('value' in answer && answer.value === 'nej'))

    checked = checked || disabled
  }

  // Warn if the custom value isn't entered
  let warning = ownProps.custom && customValue === ''

  return {...DefaultProps,
    checked: checked,
    customValue: customValue,
    values: values,
    disabled: disabled,
    hidden: hidden,
    omJa: ownProps.omJa,
    warning: warning
  }
};

const mapDispatchToProps = dispatch => {
  return {
    commitAnswer: answer => dispatch(commitAnswer(answer))
  };
};

class ConnectedRadio extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: props._id,
      checked: props.checked,
      customValue: props.customValue,
      values: props.values,
      warning: props.warning
    };

    this.toggleCheck = this.toggleCheck.bind(this);
    this.changeCustomValue = this.changeCustomValue.bind(this);
  }

  componentDidMount() {
    if ('omJa' in this.props && this.props.omJa !== undefined) {
      const { _id, omJa, commitAnswer } = this.props;
      commitAnswer({ _id, omJa })
    }
  }

  componentDidUpdate(prevProps) {
    Object.keys(DefaultProps).forEach( key => {
      if (this.props[key] !== prevProps[key])
        this.setState({ [key]: this.props[key] })
    })
  }

  toggleCheck(event) {
    let checked = event.target.checked
    let values = checked ?
      [...this.state.values, this.props.value] :
      this.state.values.filter(i => i !== this.props.value)
    let customValue = this.state.customValue ? this.state.customValue : '';

    this.setState({
      checked: checked,
      customValue: this.props.custom ? customValue: undefined,
      values: values,
      warning: this.props.custom && customValue === ''
    }, () => this.saveChanges());
  }

  changeCustomValue(event) {
    this.setState({
      customValue: event.target.value
    }, () => this.saveChanges());
  }

  saveChanges() {
    const { _id, value, multiple, omJa, commitAnswer } = this.props;
    const { customValue, values } = this.state;

    if (multiple) {
      commitAnswer({ _id, values, customValue, omJa })
    } else {
      commitAnswer({ _id, value, customValue, omJa })
    }
  }

  render() {
    const { multiple, value, checked, disabled, hidden, desc, custom, offset,
       after, short, warning } = this.props

    const { customValue } = this.state

    const InputStyle = {
      width: short ? '5em': '10em',
      borderColor: warning ? 'red': 'black'
    }

    const AfterStyle = {
      color: warning ? 'red': 'inherit'
    }

    const RowStyle = {
      display: hidden ? 'none': 'block',
      paddingLeft: offset ? offset: '0'
    }

    const DescStyle = {
      color: disabled ? 'rgba(0, 0, 0, 0.6)': 'inherit'
    }

    return (
      <Row style={RowStyle}>
        <RadioLabel>
          <RadioInput
            type={multiple ? 'checkbox': 'radio'}
            value={value}
            checked={checked}
            onChange={this.toggleCheck}
            disabled={disabled}
          />
          <span style={DescStyle} dangerouslySetInnerHTML={{__html: desc }}/>
        </RadioLabel>
        {custom && (
          <Input
            style={InputStyle}
            type="text"
            value={customValue ? customValue : ''}
            onChange={this.changeCustomValue}
            disabled={disabled || !checked}
          />
        )}
        <span style={AfterStyle}>{after}</span>
      </Row>
    )
  }
}

const Radio = connect(mapStateToProps, mapDispatchToProps)(ConnectedRadio);

export default Radio;
