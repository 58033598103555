import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import styled from 'styled-components';
import { Input, Validate, Previous, Headline, DatePicker, Progress } from '../../fields';
import { numberOfCategories } from './index';

export const requiredAnswers = [88, 89, 90]

const Spacer = styled.div`
height: 2em;
`

export class Misc extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <div id="top">
        <Progress max={numberOfCategories} value={21} />
        <Spacer />

        <Headline
            nr="88"
            question="När är du född?"
            inline
        />
        <DatePicker _id="1.88_1" inline />
        <Input _id="1.89_1" headline="89. Hur lång är du nu?" large line desc="cm" int min="3" max="300" />
        <Input _id="1.90_1" headline="90. Vad väger du nu?" large line desc="kg" int min="30" max="500" />

        <Validate className="next" answers={requiredAnswers}>
          <Link to="slutet#top">
            <button>Fortsätt &#8250;</button>
          </Link>
        </Validate><Previous to="bakgrund" />

      </div>
    );
  }
}

export default Misc;
