import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Input, Headline, Validate, Title, OBS, Checkbox, Previous, Progress } from '../../fields';
import { numberOfCategories } from './index';

export const requiredAnswers = [40]

export class Matfett extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <div id="top">
        <Progress max={numberOfCategories} value={11} />
        <Title>MATFETT</Title>

        <Headline
            nr="40"
            question="Tänk dig att du har köpt 10 kg matfett till ditt hushåll för matlagning och bakning."
            desc="Uteslut matfett som du tar på smörgåsar. Hur fördelar sig dessa 10 kilon på olika slags matfett?"
        />
        <Checkbox _id="1.40_0" desc="Jag använder inte matfett." />
        <Input ten desc="Smör, Bregott" _id="1.40_1" />
        <Input ten desc="Hårt hushållsmargarin" _id="1.40_2" />
        <Input ten desc="Flytande margarin" _id="1.40_3" />
        <Input ten desc="Olivolja" _id="1.40_4" />
        <Input ten desc="Rapsolja" _id="1.40_5" />
        <Input ten desc="Matolja (t ex majs-, solros-, sojaolja)" _id="1.40_6" />
        <Input ten desc="Kokosolja" _id="1.40_7" />
        <OBS sumTen="1.40" />

        <Validate className="next" answers={requiredAnswers}>
          <Link to="sas#top">
            <button>Fortsätt &#8250;</button>
          </Link>
        </Validate><Previous to="snabbmat" />
      </div>
    );
  }
}

export default Matfett;
