import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { FreqInput, Input, Checkbox, Validate, Headline, Title, OBS, Ex, Previous, Progress } from '../../fields';
import { numberOfCategories } from './index';

import linfro from '../images/linfro.png';
import pumpa from '../images/pumpa.png';
import solros from '../images/solros.png';
import sesam from '../images/sesam.png';
import chia from '../images/chia.png';
import psylliumfro from '../images/psylliumfro.png';
// eslint-disable-next-line
import psylliumfroskal from '../images/psylliumfroskal.png';
import psyllium from '../images/psyllium.png';
import fiberhusk from '../images/fiberhusk.png';
import husk from '../images/husk.png';

export const requiredAnswers = ['12_1', '12_2', 13, 14]

export class NotterFron extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <div id="top">
        <Progress max={numberOfCategories} value={3} />
        <Title>NÖTTER OCH FRÖN</Title>
        <Headline
            nr="12"
            question="Hur ofta äter du nötter och frön?"
            desc="Fyll i antal gånger och klicka endast i ett alternativ (dag, vecka eller månad) per typ av nötter eller frön du äter. Om du aldrig äter nötter och frön, klicka i rutan för ”aldrig”."
        />
        <FreqInput title="Någon typ av nöt" _id="1.12_1" />
        <Ex>(t ex hasselnöt, jordnöt, cashew-nöt), inklusive sötmandel</Ex>
        <FreqInput title="Någon typ av frö eller fröskal" _id="1.12_2" />
        <Ex>(t ex linfrö, solrosfrö, chiafrö, psylliumfröskal, Fiberhusk)</Ex>

        <Headline
            nr="13"
            question="Tänk dig att du äter nötter <u>tio gånger</u> i följd."
            desc="Hur många gånger brukar nötterna vara:"
        />
        <Checkbox hidden _id="1.13_0" aldrigToggle="1.12_1" />
        <Input ten desc="Hasselnötter, mandel" _id="1.13_1" />
        <Input ten desc="Jordnötter" _id="1.13_2" />
        <Input ten desc="Andra nötter (t ex cashew-, pistasch-, valnötter)" _id="1.13_3" />
        <OBS sumTen="1.13" />

        <Headline
            nr="14"
            question="Tänk dig att du äter frö eller fröskal <u>tio gånger</u> i följd."
            desc="Hur många gånger brukar fröna vara:"
        />
        <Checkbox hidden _id="1.14_0" aldrigToggle="1.12_2" />
        <Input ten desc="Linfrö" _id="1.14_1" exImg={linfro} />
        <Input ten desc="Pumpa-, sesam-, solrosfrö" _id="1.14_2"
          exImg={pumpa}
          exImg2={solros}
          exImg3={sesam} />
        <Input ten desc="Chia-, psylliumfrö" _id="1.14_3"
          exImg={chia}
          exImg2={psylliumfro} />
        <Input ten desc="Psylliumfröskal, Psyllium, Fiberhusk, Husk" _id="1.14_4"
          exImg2={psyllium}
          exImg3={fiberhusk}
          exImg4={husk}
        />
        <OBS sumTen="1.14" />

        <Validate className="next" answers={requiredAnswers}>
          <Link to="drycker#top">
            <button>Fortsätt &#8250; &#8250;</button>
          </Link>
        </Validate><Previous to="grot-flingor-musli" />
      </div>
    );
  }
}

export default NotterFron;
