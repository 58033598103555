import React from 'react'
import styled from 'styled-components';

const Container = styled.div`
margin: 2rem 0 1.2rem;
padding-top: 1rem;
`

const QuestionNumber = styled.span`
padding: 0 0.5rem 0 0;
font-weight: bold;
font-size: 110%;

&:after {
  content: '.';
}
`;

const Question = styled.span`
padding-right: 1.5rem;
font-weight: bold;
font-size: 110%;
`;

const Description = styled.div`
font-weight: normal;
margin-top: 0.3em;
`;

const Example = styled.div`
font-style: italic;
margin-top: 1rem;
`

const Small = styled.small`
color: #555;
margin-top: 1rem;
display: block;
`

const Headline = (props) => {

  const { nr, anchor, question, desc, small, example, inline, long } = props

  const ContainerStyle = {
    display: inline ? 'inline-block': 'block',
    padding: inline ? '2rem 0 1.2rem 0.4rem': '0',
    paddingTop: inline ? '2rem': '1.2rem',
    paddingBottom: long ? '1.2rem' : '0'
  }
  
  return (
    <Container id={anchor ? anchor: nr} style={ContainerStyle}>
      { nr && (
        <QuestionNumber>{nr}</QuestionNumber>
      )}
      { question && (
        <Question dangerouslySetInnerHTML={{__html: question }} />
      )}
      { desc && (
        <Description>{desc}</Description>
      )}
      { small && (
        <Small>{small}</Small>
      )}
      { example && (
        <Example>{example}</Example>
      )}
    </Container>
  )
}

export default Headline;
