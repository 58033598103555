import FreqInput from './FreqInput';
import Input from './SimpleInput';
import Checkbox from './Checkbox';
import Headline from './Headline';
import Radio from './Radio';
import Title from './Title';
import CategoryTitle from './CategoryTitle';
import OBS from './OBS';
import Ex from './Ex';
import VitaminTable from './VitaminTable';
import Portionstabell from './Portionstabell';
import FreqQuestion from './FreqQuestion';
import FreqQuestion2 from './FreqQuestion2';
import Textarea from './Textarea';
import Validate from './Validate';
import Previous from './Previous';
import Exemplet from './Exemplet';
import DryckExemplet from './DryckExemplet';
import DatePicker from './DatePicker';
import Img from './Img';
import MerEllerMindre from './MerEllerMindre';
import Warning from './Warning';
import Control from './Control';
import Progress from './Progress';

export { FreqInput, Input, Checkbox, Headline, Radio, Title, CategoryTitle, Previous, Exemplet, DryckExemplet, Img,
  OBS, VitaminTable, Portionstabell, Ex, FreqQuestion, FreqQuestion2, Textarea, Validate, DatePicker, MerEllerMindre,
  Warning, Control, Progress }
