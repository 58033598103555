import React, { Component } from "react";

import { Kostvanor, Tarmsymptom, Matvanor, Fysisk, Mediciner, Tobak, Halsa,
Stralbehandling, Misc, Slutet, requiredAnswers } from '../enkat2';

import Panel from '../../Panel';
import Logout from '../Logout';

class Enkat2 extends Component {
  render() {
    return (
      <>
        <Panel admin answers={requiredAnswers} />
        <Logout />

        <Kostvanor />
        <Tarmsymptom />
        <Matvanor />
        <Fysisk />
        <Mediciner />
        <Halsa />
        <Stralbehandling />
        <Tobak />
        <Misc />
        <Slutet />
      </>
    );
  }
}

export default Enkat2;
