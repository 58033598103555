import React from 'react'
import styled from 'styled-components';
import { connect } from "react-redux";

const DefaultProps = {
  visible: false
}

const Text = styled.div`
margin: 1em;
font-size: 1em;
font-style: italic;
color: #FF2400;
`

const mapStateToProps = (state, ownProps) => {
  let visible = DefaultProps.visible

  if ('omNej' in ownProps && ownProps.omNej) {
    let omNejObj = state.answers.find( answer => answer._id === ownProps.omNej )
    visible = omNejObj !== undefined && 'value' in omNejObj && omNejObj.value === 'nej'
  }

  return {...DefaultProps, visible: visible }
};

class ConnectedWarning extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: props.visible
    };
  }

  componentDidUpdate(prevProps) {
    Object.keys(DefaultProps).forEach( key => {
      if (this.props[key] !== prevProps[key])
        this.setState({ [key]: this.props[key] })
    })
  }

  render() {

    const { visible, content } = this.props

    const Style = {
      visibility: visible ? 'visible': 'hidden'
    }

    return(
      <Text style={Style}
        dangerouslySetInnerHTML={{__html: content}}
      />
    )
  }
}

const Warning = connect(mapStateToProps)(ConnectedWarning);

export default Warning;
