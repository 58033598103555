import React from 'react'
import { connect } from "react-redux";
import styled from 'styled-components';
import { getInvalidQuestions } from '../Validation';

const DefaultProps = {
  completed: false,
  questionsLeft: [],
  loading: true,
  confirmed: false
}

const mapStateToProps = (state, ownProps) => {

  let questionsLeft = getInvalidQuestions(
    state.answers,
    ownProps.answers,
    ownProps.enkat
  )

  return {...DefaultProps, ...{
    completed: questionsLeft.length === 0,
    questionsLeft: questionsLeft,
    loading: state.fetching,
    confirmed: false
  }}
};

class ConnectedValidate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      completed: props.completed,
      questionsLeft: props.questionsLeft,
      loading: props.loading,
      confirmed: false,
    };

    this.confirm = this.confirm.bind(this);
  }

  componentDidUpdate(prevProps) {
    Object.keys(DefaultProps).forEach( key => {
      if (this.props[key] !== prevProps[key])
        this.setState({ [key]: this.props[key] })
    })
  }

  confirm(sentence) {
    this.setState({
      confirmed: window.confirm(sentence)
    })
  }

  render() {

    const { completed, loading, children, className, questionsLeft, offset } = this.props

    const { confirmed } = this.state

    const style = {
      visibility: 'visible',
      opacity: confirmed || (completed && !loading) ? '1': '0.4',
      position: 'relative',
      marginBottom: '10em'
    }

    const noteStyle = {
      visibility: 'visible',
      opacity: completed && !loading ? '0': '1'
    }

    const downButton = {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      zIndex: 10
    }

    const upButton = {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      zIndex: 100
    }

    const Note = styled.small`
    margin: 0 0 1em;
    display: block;
    text-align: center;
    color: red;

    & a {
      color: red;
      text-decoration-style: dotted;
    }
    `

    const Confirm = styled.button`
    opacity: 0;
    pointer: not-allowed;
    `

    const questions = questionsLeft.map((realNr) => {
      let displayedNr = offset ? parseInt(realNr) - offset : realNr

      return `<a href="#${realNr}">${displayedNr}</a>`

    })

    const plural = questions.length === 1 ? 'frågan' : 'frågorna'

    let linkList = ""

    if (questions.length === 1) {
      linkList = questions[0]
    } else if (questions.length === 2) {
      linkList = questions[0] + ' och ' + questions[1]
    } else {
      let last = questions.length-1
      linkList = questions.filter((x, i) => i !== last).join(', ')
        + ' och '
        + questions[last]
    }

    const warning = `Du måste fylla i ${plural} ${linkList} korrekt innan du fortsätter.`

    let list = ""

    const dispQuestionsLeft = questionsLeft.map((realNr) =>
      offset ? parseInt(realNr) - offset : realNr)

    if (dispQuestionsLeft.length === 1) {
      list = dispQuestionsLeft[0]
    } else if (dispQuestionsLeft.length === 2) {
      list = dispQuestionsLeft[0] + ' och ' + dispQuestionsLeft[1]
    } else {
      let last = dispQuestionsLeft.length-1
      list = dispQuestionsLeft.filter((x, i) => i !== last).join(', ')
        + ' och '
        + dispQuestionsLeft[last]
    }

    const popUpWarning = `Du måste fylla i ${plural} ${list} korrekt innan du fortsätter.`

    return (
      <>
        <div className={className}>

          <Note style={noteStyle} dangerouslySetInnerHTML={{ __html: warning}} />

          <div style={style}>

            { !completed && !confirmed && (
              <div style={upButton}>
                <Confirm onClick={
                  () => this.confirm(`Är du säker? ${popUpWarning}`)}>
                  A VERY BIG COVERING BUTTON
                </Confirm>
              </div>
            )}

            <div style={downButton}>
              {children}
            </div>

          </div>
        </div>
      </>
    )
  }
}

const Validate = connect(mapStateToProps)(ConnectedValidate);

export default Validate;
