import React from 'react'
import styled from 'styled-components';
import { connect } from "react-redux";
import { commitAnswer } from "../Actions";

const DefaultProps = {
  text: '',
  disabled: false
}

const Row = styled.div`
margin-bottom: 0.5em;
`

const Description = styled.p`
margin-left: 2em;
margin-top: 1.5em;
`

const TextInput = styled.textarea`
display: block;
width: 500px;
height: 150px;
margin: 0 1em 0 2.5em;
`

const mapStateToProps = (state, ownProps) => {
  let stateObj = state.answers.find( answer => answer._id === ownProps._id );
  let disabled = DefaultProps.disabled

  // For om ja questions only
  if (['1.70_2', '1.70_3', '1.71_2', '1.71_3', '1.75_2', '1.75_3',  '1.76_2', '1.76_3',
    '2.2_2', 
    '2.31_2', '2.32_2', '2.33_2', '2.39_2', '2.40_2', '2.41_2',
    '2.31_3', '2.32_3', '2.33_3', '2.39_3', '2.40_3', '2.41_3',
    '3.70_2', '3.70_3', '3.71_2', '3.71_3', '3.72_2', '3.72_3',
    '3.76_2', '3.76_3', '3.77_2', '3.77_3', '3.78_2', '3.78_3'
    ].includes(ownProps._id)) {
    let radioID = ownProps._id.replace(/_.+/g, "_1")
    let radio = state.answers.find( answer => answer._id === radioID)
    disabled = !(radio !== undefined && 'value' in radio && radio.value === "ja")
  }

  if ('omJa' in ownProps && ownProps.omJa) {
    let omJaObj = state.answers.find( answer => answer._id === ownProps.omJa )
    disabled = disabled || omJaObj === undefined || !('value' in omJaObj) || omJaObj.value !== 'ja'
  }

  return {...DefaultProps, ...stateObj, disabled: disabled}
};

const mapDispatchToProps = dispatch => {
  return {
    commitAnswer: answer => dispatch(commitAnswer(answer))
  };
};

class ConnectedTextarea extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: props._id,
      text: props.times,
      disabled: props.disabled
    };

    this.changeText = this.changeText.bind(this);
  }

  componentDidUpdate(prevProps) {
    Object.keys(DefaultProps).forEach( key => {
      if (this.props[key] !== prevProps[key])
        this.setState({ [key]: this.props[key] })
    })
  }

  changeText(event) {
    this.setState({
      text: event.target.value
    }, () => this.saveChanges());
  }

  saveChanges() {
    const _id = this.props._id;
    let { text } = this.state;
    this.props.commitAnswer({ _id, text })
  }

  render() {
    return(
      <Row>
        <Description dangerouslySetInnerHTML={{__html: this.props.desc }} />
        <TextInput
          value={this.props.text}
          onChange={this.changeText}
          disabled={this.props.disabled}
          spellCheck="true"
          lang="sv"
        />
      </Row>
    )
  }
}

const Textarea = connect(mapStateToProps, mapDispatchToProps)(ConnectedTextarea);

export default Textarea;
