import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import styled from 'styled-components';
import { Input, Radio, Title, Textarea, Progress, Previous, Validate } from '../../fields';
import { validateSurvey } from '../../DB';
import { numberOfCategories } from './index';

export const requiredAnswers = [91]

const Text = styled.p`
margin: 1em 0 1em 2em;
`

const Spacer = styled.div`
height: 2em;
`

export class Slutet extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <div>
        <Progress max={numberOfCategories} value={22} />

        <Title>Slutligen…</Title>
        <Text>Om du har några ytterligare frågor, kontakta oss gärna på 031-786 51 30 / 076-618 51 30 (kl. 8–15) eller 
          &nbsp;<a href="mailto:fidura@vgregion.se">fidura@vgregion.se</a>.</Text>

        <Spacer />
        <Text>Hur lång tid tog det dig ungefär att fylla i det här formuläret?</Text>
        <Input int _id="1.extra_1" beforeText="Det tog ungefär" desc="minuter" line />

        <Spacer />
        <Textarea _id="1.extra_2" desc="Skriv gärna vad du tycker om frågeformuläret:" />

        <Spacer />
        <Text>91. Kan vi ringa dig om vi skulle behöva fråga om något?</Text>
        <Radio _id="1.91_1" value="ja" desc="Ja, mitt telefonnummer är:" custom />
        <Radio _id="1.91_1" value="nej" desc="Nej" />

        <Spacer />
        <p><strong>Du får gärna gå igenom formuläret igen och kontrollera att du besvarat alla frågorna korrekt.</strong></p>

        
        <Validate className="next" answers={requiredAnswers}>
          <Link to="tack#top">
            <button onClick={ () => validateSurvey('enkat1') }>Skicka &#8250;</button>
          </Link>
        </Validate>

        <Previous to="misc" />
      </div>
    );
  }
}

export default Slutet;
