import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Headline, Radio, CategoryTitle, Validate, FreqQuestion, Progress, Previous } from '../../fields';
import { numberOfCategories } from './index';

// 45 to 69
export const requiredAnswers = Array.from(new Array(25), (x,i) => i + 45)

export class Tarmsymptom extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <div id="top">
        <Progress max={numberOfCategories} value={14} />
        <CategoryTitle>B. FRÅGOR OM TARMHÄLSA</CategoryTitle>
        <FreqQuestion
          nr="45" enkat="1"
          question="Har du haft hård avföring som har varit svår att få ut de senaste tre månaderna?"
        />
        <FreqQuestion
          nr="46" enkat="1"
          question="Har du haft lös avföring de senaste tre månaderna?"
        />
        <FreqQuestion
          nr="47" enkat="1"
          question="Har du återvänt till toaletten inom en timme efter avföring för att återigen tömma tarmen de senaste tre månaderna?"
        />
        <FreqQuestion
          nr="48" enkat="1"
          question="Har du haft plötsligt påkomna avföringsträngningar som krävt omedelbart toalettbesök de senaste tre månaderna?"
        />

        <Headline
            nr="49"
            question="Hur länge har du kunnat hålla avföringen vid trängningar de senaste tre månaderna?"
            short
        />
        <Radio _id="1.49_1" value="inteAktuellt" desc="Inte aktuellt, jag har inte haft avföringsträngningar de senaste tre månaderna" />
        <Radio _id="1.49_1" value="kortareAn1Min" desc="Kortare tid än 1 minut" />
        <Radio _id="1.49_1" value="mellan1och5Min" desc="Mellan 1 och 5 minuter" />
        <Radio _id="1.49_1" value="mellan5och10Min" desc="Mellan 5 och 10 minuter" />
        <Radio _id="1.49_1" value="mellan10och30Min" desc="Mellan 10 och 30 minuter" />
        <Radio _id="1.49_1" value="langreAn30Min" desc="30 minuter eller längre" />

        <FreqQuestion
          nr="50" enkat="1"
          question="Har du utan förvarning läckt avföring i kläderna, trots att du tidigare tömt tarmen, de senaste tre månaderna?"
        />
        <FreqQuestion
          nr="51" enkat="1"
          question="Har du utan förvarning tömt <u>all</u> avföring i kläderna de senaste tre månaderna?"
        />
        <FreqQuestion
          nr="52" enkat="1"
          question="Har du haft läckage av lös avföring, när du varit <u>vaken</u>, de senaste tre månaderna?"
        />
        <FreqQuestion
          nr="53" enkat="1"
          question="Har du haft läckage av lös avföring, när du <u>sovit</u>, de senaste tre månaderna?"
        />
        <FreqQuestion
          nr="54" enkat="1"
          question="Har du haft läckage av fast avföring, när du varit <u>vaken</u>, de senaste tre månaderna?"
        />
        <FreqQuestion
          nr="55" enkat="1"
          question="Har du haft läckage av fast avföring, när du <u>sovit</u>, de senaste tre månaderna?"
        />
        <FreqQuestion
          nr="56" enkat="1"
          question="Har du tyckt att du har luktat avföring någon gång de senaste tre månaderna?"
        />
        <FreqQuestion
          nr="57" enkat="1"
          question="Har du haft en känsla av uppblåsthet i magen de senaste tre månaderna?"
        />
        <FreqQuestion
          nr="58" enkat="1"
          question="Har det hänt att du inte har kunnat hålla kvar gas (fjärtar, fisar) i ändtarmen när det behövts de senaste tre månaderna?"
        />
        <FreqQuestion
          nr="59" enkat="1"
          question="Har du haft ljudliga gasavgångar (fjärtar, fisar) som du inte kunnat stoppa de senaste tre månaderna?"
        />
        <FreqQuestion
          nr="60" enkat="1"
          question="Har du haft illaluktande gasavgångar (fjärtar, fisar) som du inte kunnat stoppa de senaste tre månaderna?"
        />
        <FreqQuestion
          nr="61" enkat="1"
          question="Har det kommit blod från tarmen de senaste tre månaderna?"
        />
        <FreqQuestion
          nr="62" enkat="1"
          question="Har du haft läckage av blod från ändtarmen, när du varit <u>vaken</u>, de senaste tre månaderna?"
        />
        <FreqQuestion
          nr="63" enkat="1"
          question="Har du haft läckage av blod från ändtarmen, när du <u>sovit</u>, de senaste tre månaderna?"
        />
        <FreqQuestion
          nr="64" enkat="1"
          question="Har det kommit slem från tarmen de senaste tre månaderna?"
        />
        <FreqQuestion
          nr="65" enkat="1"
          question="Har du haft läckage av slem från ändtarmen, när du varit <u>vaken</u>, de senaste tre månaderna?"
        />
        <FreqQuestion
          nr="66" enkat="1"
          question="Har du haft läckage av slem från ändtarmen, när du <u>sovit</u>, de senaste tre månaderna?"
        />
        <FreqQuestion
          nr="67" enkat="1"
          question="Har du haft klåda vid ändtarmsöppningen de senaste tre månaderna?"
        />
        <FreqQuestion
          nr="68" enkat="1"
          question="Har du haft smärta i ändtarmsöppningen de senaste tre månaderna?"
        />
        <FreqQuestion
          nr="69" enkat="1"
          question="Har du haft smärtor i buken de senaste tre månaderna?"
        />

        <Validate className="next" answers={requiredAnswers}>
          <Link to="matvanor#top">
            <button>Fortsätt &#8250;</button>
          </Link>
        </Validate><Previous to="snacks" />
      </div>
    );
  }
}

export default Tarmsymptom;
