import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Validate, Headline, Radio, Title, CategoryTitle, Previous, MerEllerMindre,
  Textarea, Img, Ex, Warning, Checkbox, Progress } from '../../fields';
import styled from 'styled-components';
import { numberOfCategories } from './index';

import kefir from '../images/kefir.png';
import proviva from '../images/proviva.png';
import activia from '../images/activia.png';
import actimel from '../images/actimel.png';
import halsofil from '../images/halsofil.png';
import probimage from '../images/probimage.png';

export const requiredAnswers = [1, 2, 3, 4, 5]

const GreyRow = styled.div`
border-top: 1px solid black;
border-bottom: 1px solid black;
background-color: rgb(238, 238, 238);
padding-top: 0.3em;
margin-bottom: 0.3em;
padding-bottom: 0.3em;
margin-top: 0.3em;
`

export class Kostvanor extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <div id="top">
        <Progress max={numberOfCategories} value={1} />
        <CategoryTitle>A. FRÅGOR OM KOSTVANOR</CategoryTitle>
        <Headline
            nr="1"
            question="Har du ändrat ditt kostintag sedan du startade din strålbehandling i jämförelse med ditt
kostintag under perioden tre månader innan strålbehandlingen?"
        />

        <Radio _id="2.1_1" value="nej" desc="Nej" />
        <Radio _id="2.1_1" value="ja" desc="Ja" />

        <Warning omNej="2.1_1"
          content="Vänligen gå vidare till fråga 4"
        />

        <div>
          <p>Fyll i hur ditt intag har förändrats för nedanstående livsmedelsgrupper sedan du startade din
            strålbehandling.</p>
        </div>

        <GreyRow>
          <MerEllerMindre _id="2.1_2" title="Bröd" omJa/>
        </GreyRow>
        <MerEllerMindre _id="2.1_3" title="Gröt, flingor, müsli" omJa/>
        <GreyRow>
          <MerEllerMindre _id="2.1_4" title="Nötter, frön, fröskal" omJa/>
        </GreyRow>
        <MerEllerMindre _id="2.1_5" title="Mjölkprodukter (ej laktosfria)" omJa/>
        <GreyRow>
          <MerEllerMindre _id="2.1_6" title="Laktosfria mjölkprodukter, mjölkfria ersättningsprodukter" omJa/>
        </GreyRow>
        <MerEllerMindre _id="2.1_7" title="Totalt vätskeintag"
          desc="Exempelvis vatten, juice, kaffe, te" dricker omJa/>
        <GreyRow>
          <MerEllerMindre _id="2.1_8" title="Grönsaker" omJa/>
        </GreyRow>
        <MerEllerMindre _id="2.1_9" title="Frukt, bär" omJa/>
        <GreyRow>
          <MerEllerMindre _id="2.1_10" title="Kött, köttprodukter"
            desc="Exempelvis nötkött, fläskkött, köttfärs, kyckling, korv, bacon" omJa/>
        </GreyRow>
        <MerEllerMindre _id="2.1_11" title="Fet fisk"
          desc="Exempelvis lax, sill, strömming, makrill" omJa/>
        <GreyRow>
          <MerEllerMindre _id="2.1_12" title="Mager fisk, skaldjur"
            desc="Exempelvis torsk, spätta, räkor, musslor" omJa/>
        </GreyRow>
        <MerEllerMindre _id="2.1_13" title="Potatis, pasta, ris, gryn" omJa/>
        <GreyRow>
          <MerEllerMindre _id="2.1_14" title="Färdigmat"
            desc="Exempelvis pizza, paj, piroger, hamburgare, pommes frites" omJa/>
        </GreyRow>
        <MerEllerMindre _id="2.1_15" title="Matfett"
          desc="Exempelvis smör, margarin, olja i matlagning och på smörgås" omJa/>
        <GreyRow>
          <MerEllerMindre _id="2.1_16" title="Bullar, kakor, tårta" omJa/>
        </GreyRow>
        <MerEllerMindre _id="2.1_17" title="Godis"
          desc="Exempelvis choklad, lösgodis" omJa/>
        <GreyRow>
          <MerEllerMindre _id="2.1_18" title="Snacks" desc="Exempelvis chips, ostbågar, popcorn" omJa/>
        </GreyRow>


        <Headline
            nr="2"
            question="Har du gjort några andra förändringar i ditt kostintag utöver det som du angett i
fråga 1?"
        />

        <Checkbox hidden _id="2.2_0" nejToggle="2.1_1" />

        <Radio _id="2.2_1" value="nej" desc="Nej" />
        <Radio _id="2.2_1" value="ja" desc="Ja" />

        <Textarea desc="Vilka andra kostförändringar har du gjort? Skriv ditt svar kortfattat nedan."
          _id="2.2_2"
        />

        <Headline
            nr="3"
            question="Vilket har skälet eller skälen varit till att du ändrat ditt kostintag efter att din
strålbehandling startade?"
            desc="Skriv ditt svar kortfattat nedan."
        />

        <Checkbox hidden _id="2.3_0" nejToggle="2.1_1" />
        <Textarea _id="2.3_1" omJa="2.1_1" />

        <Headline
            nr="4"
            question="Hur ofta under din strålbehandling har du ätit någon form av så kallad functional food eller kosttillskott som innehåller extra tillsatta mjölksyrabakterier?"
            desc="Exempel är produkter från Proviva (frukt- och bärdryck, shots), yoghurtprodukter från Verum/Gefilus/Actimel, A-fil samt ProbiMage."
            short
        />

        <Img inline src={kefir} />
        <Img inline src={proviva} />
        <Img inline src={activia} />
        <Img inline src={actimel} />
        <Img inline src={halsofil} />
        <Img inline src={probimage} />

        <Radio _id="2.4_1" value="varjedag" desc="Varje dag" />
        <Radio _id="2.4_1" value="varjevecka" desc="Varje vecka" />
        <Radio _id="2.4_1" value="varjemanad" desc="Varje månad" />
        <Radio _id="2.4_1" value="aldrig" desc="Aldrig" />

        <Headline
            nr="5"
            question="Hur ofta äter du någon form av livsmedel som naturligt innehåller mjölksyrabakterier?"
            desc="Exempel är surkål, mjölksyrade grönsaker, Kombucha."
        />
        <Ex><strong>Mjölksyrade grönsaker</strong> - en jäsningsprocess där grönsaker blandas med salt eller saltlösning och får stå i rumstemperatur 5–7 dagar och sedan förvaras ytterligare några veckor i kylskåp. Det kallas också syrade grönsaker eller kimchi.</Ex>
        <Ex><strong>Kombucha</strong> - en dryck baserad på te som har genomgått en jäsningsprocess.</Ex>
        <Radio _id="2.5_1" value="varjedag" desc="Varje dag" />
        <Radio _id="2.5_1" value="varjevecka" desc="Varje vecka" />
        <Radio _id="2.5_1" value="varjemanad" desc="Varje månad" />
        <Radio _id="2.5_1" value="aldrig" desc="Aldrig" />


        <Validate className="next" answers={requiredAnswers} enkat="2">
          <Link to="tarmsymptom#top">
            <button>Fortsätt &#8250;</button>
          </Link>
        </Validate><Previous to="intro" />
      </div>
    );
  }
}

export default Kostvanor;
