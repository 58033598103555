import React, { Component } from "react";
// eslint-disable-next-line
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

import { Intro, Kvinnohalsa, ForeCancern, SexuellKrankning, Allmanna, Avslutande, Tack,
  requiredAnswers } from './enkat0';

import Help from './Help';

import Panel from '../Panel';
import { isAdmin } from '../DB';

class Enkat0 extends Component {
  render() {
    return (
      <div>
        <Route exact path="/vista/enkat/0" render={() => (
          <Redirect to="/vista/enkat/0/intro"/>
        )}/>

        { isAdmin && (
          <Panel answers={requiredAnswers} />
        )}

        <Help />

        <Route exact path="/vista/enkat/0/intro" component={Intro} />
        <Route exact path="/vista/enkat/0/kvinnohalsa" component={Kvinnohalsa} />
        <Route exact path="/vista/enkat/0/forecancern" component={ForeCancern} />
        <Route exact path="/vista/enkat/0/sexuellkrankning" component={SexuellKrankning} />
        <Route exact path="/vista/enkat/0/allmanna" component={Allmanna} />
        <Route exact path="/vista/enkat/0/avslutande" component={Avslutande} />
        <Route exact path="/vista/enkat/0/tack" component={Tack} />
      </div>
    );
  }
}

export default Enkat0;
