import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { FreqInput, Input, Headline, Validate, Checkbox, Title, Progress, Portionstabell, Radio, Ex, Previous, Img, DryckExemplet } from '../../fields';
import styled from 'styled-components';
import { numberOfCategories } from './index';

import kefir from '../images/kefir.png';
import proviva from '../images/proviva.png';
import activia from '../images/activia.png';
import actimel from '../images/actimel.png';
import halsofil from '../images/halsofil.png';
import probimage from '../images/probimage.png';

export const requiredAnswers = [
  '15_1', '15_2', '15_3', '15_4', '15_5', '15_6', '15_7', '15_8', '15_9', '15_10',
  '15_11', '15_12', '15_13', '15_14', '15_15', '15_16', '15_17', '15_18', '15_19', '15_20',
  16, 17, 18, '19_1']

const GreyRow = styled.div`
border-top: 1px solid black;
border-bottom: 1px solid black;
background-color: rgb(238, 238, 238);
padding-top: 0.3em;
margin-bottom: 0.3em;
`

export class Drycker extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <div id="top">
        <Progress max={numberOfCategories} value={4} />
        <Title>DRYCKER</Title>
        <Headline
            nr="15"
            question="Hur mycket dricker eller äter du av följande? Ange antalet gånger du dricker eller äter en portion av livsmedlet. Använd portionstabellen nedan för att se vilken mängd en portion innebär."
            desc="Även mjölk eller fil som du tar till gröt eller flingor ska tas med här. Fyll endast i ett alternativ (dag eller vecka) per typ av livsmedel du använder. Om du aldrig använder livsmedlet, kryssa i rutan för ”aldrig”."
        />
        <Portionstabell />
        <DryckExemplet />
        <GreyRow>
        <FreqInput titleWidth="200px" title="Lätt/minimjölk" _id="3.15_1" />
        </GreyRow>
        <FreqInput titleWidth="200px" title="Mellanmjölk" _id="3.15_2" />
        <GreyRow>
        <FreqInput titleWidth="200px" title="Standardmjölk" _id="3.15_3" />
        </GreyRow>
        <FreqInput titleWidth="200px" title="Lättfil/lättyoghurt" _id="3.15_4" />
        <GreyRow>
        <FreqInput titleWidth="200px" title="Mellanfil/mellanyoghurt" _id="3.15_5" />
        </GreyRow>
        <FreqInput titleWidth="200px" title="Filmjölk/yoghurt 3 % fett" _id="3.15_6" />
        <GreyRow>
        <FreqInput titleWidth="200px" title="Mjölkfri dryck/yoghurt" _id="3.15_7" />
        <Ex>(t ex sojadryck, mandeldryck, havredryck, soygurt, havregurt)</Ex>
        </GreyRow>
        <FreqInput titleWidth="200px" title="Juice" _id="3.15_8" />
        <Ex>(inklusive egenpressad, färskpressad, Proviva)</Ex>
        <GreyRow>
        <FreqInput titleWidth="200px" title="Saft/läsk" _id="3.15_9" />
        </GreyRow>
        <FreqInput titleWidth="200px" title="Lightsaft/lightläsk" _id="3.15_10" />
        <GreyRow>
        <FreqInput titleWidth="200px" title="Alkoholfri öl/lättöl" _id="3.15_11" />
        </GreyRow>
        <FreqInput titleWidth="200px" title="Folköl/mellanöl" _id="3.15_12" />
        <GreyRow>
        <FreqInput titleWidth="200px" title="Starköl" _id="3.15_13" />
        </GreyRow>
        <FreqInput titleWidth="200px" title="Rött vin" _id="3.15_14" />
        <GreyRow>
        <FreqInput titleWidth="200px" title="Vitt vin" _id="3.15_15" />
        </GreyRow>
        <FreqInput titleWidth="200px" title="Starkvin/dessertvin" _id="3.15_16" />
        <GreyRow>
        <FreqInput titleWidth="200px" title="Starksprit" _id="3.15_17" />
        </GreyRow>
        <FreqInput titleWidth="200px" title="Kaffe" _id="3.15_18" />
        <GreyRow>
        <FreqInput titleWidth="200px" title="Te" _id="3.15_19" />
        </GreyRow>
        <FreqInput titleWidth="200px" title="Örtte" _id="3.15_20" />

        <Headline
            nr="16"
            question="Tänk dig att du äter mjölkprodukter <u>tio gånger</u> i följd."
            desc="Hur många gånger brukar mjölkprodukterna vara laktosfria alternativ? Fyll i 0 (noll) om du inte äter laktosfria produkter."
        />
        <Checkbox _id="3.16_0" desc="Jag äter inte mjölkprodukter" />
        <Input int desc="antal gånger" _id="3.16_1" max="10" />

        <Headline
            nr="17"
            question="Hur ofta äter du någon form av så kallad functional food eller kosttillskott som innehåller extra tillsatta mjölksyrabakterier?"
            desc="Exempel är produkter från Proviva (frukt- och bärdryck, shots), yoghurtprodukter från Verum/Gefilus/Actimel, A-fil samt ProbiMage."
            short
        />
        <Img inline src={kefir} />
        <Img inline src={proviva} />
        <Img inline src={activia} />
        <Img inline src={actimel} />
        <Img inline src={halsofil} />
        <Img inline src={probimage} />
        <Radio _id="3.17_1" value="varjedag" desc="Varje dag" />
        <Radio _id="3.17_1" value="varjevecka" desc="Varje vecka" />
        <Radio _id="3.17_1" value="varjemanad" desc="Varje månad" />
        <Radio _id="3.17_1" value="aldrig" desc="Aldrig" />

        <Headline
            nr="18"
            question="Hur ofta äter du någon form av livsmedel som naturligt innehåller mjölksyrabakterier?"
            desc="Exempel är surkål, mjölksyrade grönsaker, Kombucha."
        />
        <Ex><strong>Mjölksyrade grönsaker</strong> - en jäsningsprocess där grönsaker blandas med salt eller saltlösning och får stå i rumstemperatur 5–7 dagar och sedan förvaras ytterligare några veckor i kylskåp. Det kallas också syrade grönsaker eller kimchi.</Ex>
        <Ex><strong>Kombucha</strong> - en dryck baserad på te som har genomgått en jäsningsprocess.</Ex>
        <Radio _id="3.18_1" value="varjedag" desc="Varje dag" />
        <Radio _id="3.18_1" value="varjevecka" desc="Varje vecka" />
        <Radio _id="3.18_1" value="varjemanad" desc="Varje månad" />
        <Radio _id="3.18_1" value="aldrig" desc="Aldrig" />

        <Headline
            nr="19"
            question="Hur mycket vätska dricker du i genomsnitt per dygn? "
            desc="Räkna även med kaffe, te samt alkoholhaltiga drycker"
        />
        <Input float desc="Liter per dygn" _id="3.19_1" max="20" />

        <Validate className="next" answers={requiredAnswers} enkat="3">
          <Link to="gronsaker-frukt#top">
            <button>Fortsätt &#8250;</button>
          </Link>
        </Validate><Previous to="notter-fron" />
      </div>
    );
  }
}

export default Drycker;
